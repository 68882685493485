<template>
  <CBox>
    <CBox :w="['100%', '80%']" m="0 auto">
      <CImage :src="event.image" w="100%"/>
      <CBox p="24px 16px">
        <CText class="title">{{ event.title }}</CText>
        <CText class="time-available">
          {{ getDate(event.date) }} <br>
          {{ getTime(event.startTime) }} - {{  getTime(event.endTime) }}
        </CText>
        <CText class="booking-type">
          参加費 無料・申込不要
        </CText>
        <CBox pt="4" v-html="event.body_html">
        </CBox>
      </CBox>
    </CBox>
    <CBox
        rounded="lg"
        background="#f0f0f0"
        p="15px"
        textAlign="center"
        m="16px">
      <CBox fontSize="14px" mb="2">
        このイベントは申込不要です。
      </CBox>
      <CBox fontSize="16px">
        当日、会場までおこしください。
      </CBox>
    </CBox>
      
      <CBox align="center" style="padding-bottom:50px;">
        <CButton
            variant="solid"
            fontWeight="bold"
            fontSize="16px"
            color="#ffffff"
            bg="#F2B5B1"
            h="52px"
            @click="goBack">
          戻る
        </CButton>
      </CBox>

  </CBox>
</template>

<script>
import {CBox, CImage, CText, CButton} from "@chakra-ui/vue";
import moment from "moment"

export default {
  name: "NoBookingNeeded",
  components: {
    CBox,
    CImage,
    CText,
    CButton,
  },
  props: {
    goBack: {
      type: Function
    },
    event: {
      type: Object
    }
  },
  methods:{
    getDate(date){
      return moment(date).format('LL')
    },
    getTime(time){
      return moment.utc(time*1000).format('HH:mm');
    },
  },
  mounted(){
    window.scrollTo(0, 0)
  }
}
</script>

<style scoped>
.title {
  font-size: 18px;
  font-weight: 500;
  padding-bottom: 10px;
}
.time-available, .booking-type {
  font-size: 16px;
}
.description {
  padding-top: 10px;
  font-size: 13px;
}
</style>