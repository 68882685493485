<template>
  <CBox>
    <CBox :w="['100%', '80%']" m="0 auto" v-if="step === 1">
      <AlertBox
          v-bind:message="alertBox.message"
          v-bind:type="alertBox.type"
      />
      <CImage :src="event.image" w="100%"/>
      <CBox p="24px 16px">
        <CText class="title"> {{ event.title }} </CText>
        <CText class="time-available">
            {{ getDate(event.date) }} <br>
            {{ getTime(event.startTime) }} - {{  getTime(event.endTime) }}
        </CText>
        <CText class="booking-type">
          参加費 無料・申込
        </CText>
        <CText class="booking-type" v-if="event.quantity" :style="canBook ? '':'color:red;'">
            参加人数: {{ event.quantity - getBooked(event) }} / {{ event.quantity }} {{ canBook ? '' : '(満席)' }}
        </CText>
        <CBox pt="4" v-html="event.body_html">
        </CBox>
      </CBox>
      <CBox align="center" mt="8" mb="16">
        <CButton
            v-if="canBook"
            variant="solid"
            fontWeight="bold"
            fontSize="16px"
            color="#ffffff"
            bg="#F2B5B1"
            h="52px"
            @click="nextStep"
        >
          イベントに申し込む
        </CButton>
        <div
          v-else
          style="background-color:#F0F0F0;color:#333;padding:14px;border-radius:5px;margin:20px;">
          このイベントは満席です<br>
          現在お申し込みができません
        </div>
      </CBox>

      <CBox align="center" style="padding-bottom:50px;">
        <CButton
            variant="solid"
            fontWeight="bold"
            fontSize="16px"
            color="#ffffff"
            bg="#F2B5B1"
            h="52px"
            @click="goBack"
        >
          戻る
        </CButton>
      </CBox>

    </CBox>
    <ConfirmBookedEvent :goBack="goBack" :event="event" v-if="step === 2" v-bind:next-step="nextStep"/>
    <EventBookingComplete :goBack="goBack" :event="event" v-if="step === 3"/>
  </CBox>
</template>

<script>
import {CBox, CImage, CText, CButton} from "@chakra-ui/vue";
import AlertBox from "../AlertBox";
import ConfirmBookedEvent from "./ConfirmEventBooked";
import EventBookingComplete from "./EventBookingComplete";
import axios from "../../axios";
import moment from "moment";

export default {
  name: "FreeBookedEvent",
  components: {
    CBox,
    CImage,
    CText,
    CButton,
    AlertBox,
    ConfirmBookedEvent,
    EventBookingComplete,
  },
  props: {
    goBack: {
      type: Function
    },
    event: {
      type: Object
    }
  },
  data() {
    return {
      alertBox: {
        type: 'alert',
        message: 'このイベントは参加に申込みが必要です',
      },
      step: 1
    }
  },
  computed:{
    canBook(){
      let canBook = true
      if(this.event.booked && this.event.quantity){
        if(this.event.quantity <= this.event.booked.length){
          return canBook = false
        }
      }
      return canBook
    }
  },
  methods: {
    getDate(date){
      return moment(date).format('LL')
    },
    getTime(time){
      return moment.utc(time*1000).format('HH:mm');
    },
    getBooked(event){
      let quantity = event.quantity
      let booked = event.booked
      if(!quantity || quantity == 0){
        return 0
      }
      return quantity - booked.length
    },
    backStep(){
      this.step = 1
    },
    async nextStep() {
      if(this.step == 2){
        const loader = this.$loading.show()
        const user = this.$store.state.user
        await axios.post('/api/events', {
          user_id: user.record_id,
          event_id: this.event.id
        })
        loader.hide()
      }
      this.step = this.step + 1
    }
  },
  mounted(){
    window.scrollTo(0, 0)
  }
}
</script>

<style scoped>
.title {
  font-size: 18px;
  font-weight: bold;
  padding-bottom: 10px;
}
.time-available, .booking-type {
  font-size: 16px;
}
.description {
  padding-top: 10px;
  font-size: 13px;
}
</style>