import Vue from 'vue';
import Router from 'vue-router';
import Antd from 'ant-design-vue';
import NProgress from 'nprogress';

import Login from '../views/Login';
import Register from "../views/Register";
import HomePage from "../views/HomePage";
import PurchaseTicket from "../views/PurchaseTicket";
import StepBooking from "../views/StepBooking";
import Sensei from "../components/Sensei";
import SalonAndFitnessSection from "../views/SalonAndFitnessSection";
import EventSection from "../views/EventSection";
import CafeBentoSection from "../views/CafeBentoSection";
import Bento from "../views/Bento";
import Takeout from "../views/Takeout";
import Orders from "../views/Orders";
import User from "../views/User";
import UpdateUser from "../views/UpdateUser";
import ChangePassword from "../views/ChangePassword";
import ModalTest from "../components/ModalTest";
import RequestForgotPassword from "../views/RequestForgotPassword";
import PrivacyPolicy from "../views/PrivacyPolicy";
import CommercialTransactionsLaw from "../views/CommercialTransactionsLaw";
import DeleteUser from "../components/DeleteUser"
// import PasswordReset from "../views/PasswordReset"

import 'ant-design-vue/dist/antd.css';

Vue.use(Antd);
Vue.use(Router)

const router = new Router({
    mode: 'history',
    routes: [
        { path: '/', component: HomePage, name: 'Home' },
        { path: '/login', component: Login, name: 'Login' },
        { path: '/register', component: Register, name: 'Register' },
        { path: '/purchase-ticket', component: PurchaseTicket, name: 'PurchaseTicket' },
        { path: '/step-booking/:type', component: StepBooking, name: 'StepBooking' },
        { path: '/studio', component: SalonAndFitnessSection, name: 'SalonAndFitnessSection' },
        { path: '/event', component: EventSection, name: 'EventSection' },
        { path: '/cafe', component: CafeBentoSection, name: 'CafeBentoSection'},
        { path: '/takeout', component: Takeout, name: 'Takeout' },
        { path: '/bento', component: Bento, name: 'Bento' },
        { path: '/sensei', component: Sensei, name: 'Sensei'},
        { path: '/orders', component: Orders, name: 'Orders' },
        { path: '/user', component: User, name: 'User'},
        { path: '/delete-user', component: DeleteUser, name: 'DeleteUser'},
        { path: '/change-password', component: ChangePassword, name: 'ChangePassword' },
        { path: '/update-user', component: UpdateUser, name: 'UpdateUser' },
        { path: '/modal-test', component: ModalTest, name: 'ModalTest' },
        { path: '/privacy-policy', component: PrivacyPolicy, name: 'PrivacyPolicy'},
        { path: '/commercial-transactions-law', component: CommercialTransactionsLaw, name: 'CommercialTransactionsLaw'},
        { path: '/request-forgot-password', component: RequestForgotPassword, name: 'RequestForgotPassword' },
        // { path: '/password-reset', component: PasswordReset, name: 'PasswordReset' }
    ]
});

router.beforeEach((to, from, next) => {
    // const loggedIn = localStorage.getItem('token');
    /*
    if(to.name == 'Sensei'){
        return next()
    }
    
    if (to.name !== 'Login' && to.name !== 'Register'  && !loggedIn) {
        return next({ name: 'Login' })
    }
    if ((to.name === 'Login' || to.name === 'Register') && loggedIn) {
        return next({ name: 'Home' })
    }
    */
    next()
})

router.beforeResolve((to, from, next) => {
    // If this isn't an initial page load.
    if (to.name) {
        // Start the route progress bar.
        NProgress.start()
    }
    next()
})

router.afterEach(() => {
    // Complete the animation of the route progress bar.
    NProgress.done()
    window.scrollTo(0, 0)
})

export default router;