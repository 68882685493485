<template>
  <div>
    <modal name="my-first-modal">
      <div class="modal-content">
        <div style="">
            <div for="timeselect">時間</div>
            <VueTimepicker class="time-picker" id="timeselect" :hour-range="[[10, 12], [14, 19]]" :minute-interval="10" format="HH:mm">
            </VueTimepicker>
        </div>
      </div>
    </modal>

  </div>
</template>
<script>
import {
    // CModalContent,
    // CFormLabel
} from "@chakra-ui/vue";
import 'vue2-timepicker/dist/VueTimepicker.css';
import VueTimepicker from 'vue2-timepicker';

VueTimepicker
export default {
  name: "ModalTest",
  data(){
    return {
      isOpen: true
    }
  },
  methods:{
    show () {
        this.$modal.show('my-first-modal');
    },
    hide () {
        this.$modal.hide('my-first-modal');
    },
    close() {
      this.isOpen = false
    },
  },
  components: {
    VueTimepicker,
    // CModalContent,
    // CFormLabel
  },
  mounted(){
    this.show()
  }
}
</script>

<style scoped>

.modal-content {
  /*margin-top:200px;*/
  /*height: 100%; 
  overflow: auto;
  position: fixed;*/
}
</style>