<template>
  <CBox class="square"
    @mouseover="hover = true"
    @mouseleave="hover = false"
    :style="`background-image:url(${image});`">
    <div class="image-line-up" 
     v-if="type === 'normal'"></div>
  
    <CBox :class="hover ? 'card-bottom-show card-bottom card-bottom-normal' : 'card-bottom card-bottom-normal'" v-if="hover">
      <CText class="title">
        {{ title }} 
      </CText>
      <CText class="description" v-html="description">
      </CText>
    </CBox>

  </CBox>
</template>

<script>
import { CBox, CText } from "@chakra-ui/vue";

export default {
  name: "Card",
  components: {
    CBox,
    CText,
  },
  props: {
    width: Number,
    image: String,
    title: String,
    date: String,
    description: String,
    tagName: String,
    type: String,
    id: String
  },
  data(){
    return {
      hover: false
    }
  }
};
</script>

<style scoped>
.square {
    float:left;
    position: relative;
    width: 30%;
    padding-bottom : 30%; /* = width for a 1:1 aspect ratio */
    margin:1.66%;
    background-position:center center;
    background-repeat:no-repeat;
    background-size:cover; /* you change this to "contain" if you don't want the images to be cropped */
}

.title {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: #FFF;
  text-align: center;
}

.date {
  text-align: center;
}

.description {
  text-align: center;
  word-wrap: break-word;
  overflow: hidden;
}

.card-bottom {
  color: #FFF;
  background: rgba(122, 181, 163, 90%);
  padding: 20px;
  z-index: 99;
  bottom: 0;
  top: auto;
  position: absolute;
  width: 100%;
  transition: top 0.5s ease 0s;
  cursor: pointer;
}

.card-bottom-show {
  top: 0px;
  transition: top 0.5s ease 0s;
}

.card-button {
  background-color:#FFF;
  color:#005C42;
  font-weight: bold;
  border-radius: 5px;
  padding:5px;
  display: inline-block;
  display: block;
  width: 180px;
  text-align: center;
  margin: 20px;
  cursor: pointer;
  position: absolute;
  bottom: 0px;
  margin-left: calc(50% - 100px);
}

.card-bottom-normal {
  width: calc(100%);
  padding-top: 40%;
}

.image-line-up {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

@media screen and (max-width: 868px) {
  .description {
    display: none;
  }
  .square {
    margin:0%;
    width: 33.3%;
    padding-bottom : 33.3%; /* = width for a 1:1 aspect ratio */
  }
  .title {
    font-size: 14px;
  }
  .card-bottom-normal {
    width: calc(100%);
    padding-top: 40%;
  }
  .card {
    padding: 0px;
  }
}

</style>