<template>
  <CBox :mb="[60, 160]" p="32px 0" style="padding-top:80px;">
    <div v-if="displayBackground">
      <CText style="text-align:center;" color="#DA536E" fontSize="16px" fontWeight="normal" mt="5">
        先生専用画面
      </CText>
      <CText style="text-align:center;" fontSize="18px" fontWeight="normal" mb="30" mt="5">
        レッスン予約枠の登録
      </CText>
      <CButton style="margin:0px auto;" d="flex" alignItems="center" class="badge" fontSize="16" h="38px" fontWeight="normal" @click="listMode = !listMode">{{listMode ? "カレンダーモードへの変更" : "リストモードに変更 "}}</CButton>
    </div>

    <div style="max-width:900px;margin:0px auto;" v-if="listMode && displayBackground">
      <CBox
          d="flex"
          alignItems="center"
          justifyContent="space-between"
          p="0 16px"
          mb="34px">
        <CButton class="badge" fontSize="16" h="38px" fontWeight="normal" @click="setPreviousMonth">前月</CButton>
        <CText fontSize="24px">{{ getCurrentMonth }}</CText>
        <CButton class="badge"  fontSize="16" h="38px" fontWeight="normal" @click="setNextMonth">翌月</CButton>
      </CBox>

      <CBox class="booking-time-info" p="20px 0" :m="['0', '0 16px']">
        <CBox d="flex" alignItems="center" justifyContent="space-between" p="0 16px" mb="20px" v-for="available in getCurrentAvailableSchedule" :key="available.recordId">
          <CText>{{getMonthYearDate(available["date"])}} {{getTime(available['startTime'])}} - {{getTime(available['endTime'])}} </CText>
          <CText>予約: {{available.booking.length}}/{{available['pax']}}</CText>
          <CText style="width:200px;"> {{ getLessonType(available["lesonType"]) }} </CText>
          <CText style="min-width: 160px;">
          <CButton class="edit-btn" bg="#E0E0E0" fontSize="16px" h="38px" fontWeight="normal" @click="openEdit(available)">編集</CButton>
          <CButton class="delete-btn" bg="#E0E0E0" fontSize="16px" h="38px" fontWeight="normal" @click="openDelete(available)">削除</CButton>
          </CText>
        </CBox>
      </CBox>
    </div>
    
    <div style="max-width:900px;margin:0px auto 0px auto;" v-if="displayBackground">
        <div class="create-btn" @click="openCreateModal">
          +
        </div>
    </div>
    
    <div style="max-width:900px;margin: 10px auto 0px auto;" v-if="!listMode && !isOpen">
      <vc-calendar
        locale="ja"
        class="custom-calendar"
        :masks="masks"
        :attributes="getCurrentAvailableSchedule"
        disable-page-swipe
        is-expanded>
        <template v-slot:day-content="{ day, attributes }">
          <div class="" style="overflow:hidden;min-height:100px;border:1px solid #b8c2cc;border-highlight:1px solid #b8c2cc;">
            <span class="">{{ day.day }}</span>
            <div class="" style="overflow-y:scroll;height: 100px;">
              <p
                v-for="attr in attributes"
                :key="attr.recordId"
                class="calendar-text"
                >
              {{ attr.customData.title }}  {{ attr.customData.start }} - {{ attr.customData.end }}
              </p>
            </div>
          </div>
        </template>
      </vc-calendar>
    </div>

    <modal
      height="200px"
      name="delete-modal"
      :width="innerWidth < 777 ? innerWidth - 50 : 600"
      @closed="closeDelete">
      <div class="delete-modal-content">
        <div class="modal-headers">レッスンを削除</div>
          <CBox v-if="toDelete" style="padding:10px;">
            <CText>{{toDelete['date']}} {{getTime(toDelete['startTime'])}}</CText>
            <CText>予約: {{toDelete.schedules.length}}/{{toDelete['pax']}} {{ getLessonType(toDelete["lessonType"]) }}</CText>
          </CBox>
        <div class="modal-buttons">
          <CButton class="cancel-btn" @click="closeDelete">キャンセル</CButton>
          <CButton class="delete-btn"  @click="deleteSchedule">削除</CButton>
        </div>
      </div>

    </modal>
    
    <div class="modal-content" v-if="isOpen || isEdit" style="">
          <div class="modal-headers">{{ isOpen ? "レッスンを作成" : "レッスンを編集" }}</div>
          <CFormControl is-required>
            
            <div class="form-group">
            <CFormLabel for="startdate">開始日:</CFormLabel>
            <datepicker class="date-picker" v-validate="'required'" name="startDateTime" id="startdate" :language="ja" :format="customFormatter" v-model="startDate"/>
            <span style="color:red;">{{ errors.first('startDateTime') }}</span>
            </div>

            <div class="form-group">
            <CFormLabel for="lessontype">タイプ</CFormLabel>
            <CSelect v-validate="`included:${bookingTypesToSring()}`" name="lessonType" id="lessontype" placeholder="" v-model="selectedType">
              <option v-for="type in bookingTypes" :key="type.id" :value="type.id" :selected="selectedType == type.id">
                  {{ type['name'] }}
              </option>
            </CSelect>
            <span style="color:red;">{{ errors.first('lessonType') }}</span>
            </div>

            <div class="form-group">
            <CFormLabel for="paxtinput">人数:</CFormLabel>
            <CInput v-validate="'required|between:1,6'" name="pax" id="paxtinput" v-model.number="pax" type="number" />
            <span style="color:red;">{{ errors.first('pax') }}</span>
            </div>

            <div class="form-group">
            <CFormLabel for="priceinput">料金:</CFormLabel>
            <CInput id="priceinput"  v-model="getTypePrice" :disabled="true"/>
            </div>

            <div class="form-group">
            <CFormLabel for="timeselect">時間</CFormLabel><br>
            <VueTimepicker hide-disabled-items v-validate="'required'" :hour-range="[[9,22]]" name="startTime" class="time-picker" id="timeselect" v-model="startTime" :minute-interval="10" format="HH:mm">
            </VueTimepicker>
            <span style="color:red;">{{ errors.first('startTime') }}</span>
            
            <label> ~ </label>
            <VueTimepicker hide-disabled-items v-validate="'required'" :hour-range="getHourRange" :minute-range="getMinuteRange" id="timeselectend" name="endTime" class="time-picker" v-model="endTime" :minute-interval="10" format="HH:mm">
            </VueTimepicker><br>
            <span style="color:red;" v-if="!timeValidator">終了時間の前に開始時間を設定してください</span>
            <span style="color:red;">{{ errors.first('endTime') }}</span>
            </div>

              <div v-if="!isEdit" class="form-group">
                <CFormLabel for="type">繰り返し:</CFormLabel>
                <CSelect v-validate="'required'" name="repeat" id="type" placeholder="" v-model="repeat">
                  <option disabled value="">1つを選択してください</option>
                  <option value="no-repeat">繰り返さない</option>
                  <option value="daily">毎日</option>
                  <option value="weekly">毎週の{{ ['日','月','火','水','木','金','土'][weekdayPosition] }}曜日</option>
                  <option value="monthly-first-week">毎月第1{{ ['日','月','火','水','木','金','土'][weekdayPosition] }}曜日</option>
                  <option value="weekday">毎週平日 (月 ~ 金)</option>
                </CSelect>
                <span style="color:red;">{{ errors.first('repeat') }}</span>
              </div>

            <div v-if="repeat && repeat !== 'no-repeat'" class="form-group">
              <CFormLabel for="enddatename">終了日:</CFormLabel>
              <datepicker v-validate="'required'" name="endDateTime" id="enddatename" :language="ja" :format="customFormatter" v-model="endDate"/>
              <span style="color:red;" v-if="!dateValidator">開始日以降に終了日を設定してください</span>
              <span style="color:red;">{{ errors.first('endDateTime') }}</span>
            </div>

            <div class="modal-buttons">
              <CButton class="cancel-btn" @click="close">キャンセル</CButton>
              <CButton class="save-btn" @click="saveSchedule" v-if="selectedType && isOpen">日時を保存</CButton>
              <CButton class="save-btn" v-if="selectedType && isEdit" @click="edit">日時を保存</CButton>
            </div>
          </CFormControl>

    </div>

  </CBox>
</template>
<script>
import { ja } from 'vuejs-datepicker/dist/locale'
import moment from 'moment'
moment.locale("ja");
import datepicker from 'vuejs-datepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'
import VueTimepicker from 'vue2-timepicker'
import notie from 'notie'
import axios from '../axios'
import {
    CBox,
    CText,
    CButton,
    CFormControl,
    CFormLabel,
    CSelect,
    CInput,
    } from "@chakra-ui/vue";
import japanese from 'vee-validate/dist/locale/ja';
import '/src/assets/modal-custom.css'
import { Validator } from 'vee-validate';

const isGreater = (startTime, endTime) => {
  return Number(startTime) >= Number(endTime)
};

// The first param is called 'min', and the second is called 'max'.
const paramNames = ['startTime', 'endTime'];

Validator.extend('greaterThan', isGreater, {
  paramNames //  pass it in the extend options.
});

export default {
  components: {
    CInput,
    CFormLabel,
    CSelect,
    CFormControl,
    CBox,
    CText,
    CButton,
    VueTimepicker,
    datepicker
  },
  data(){
    let today = new Date()
    today.setHours(today.getHours() - 9);
    let tomorrow = new Date(today);
    tomorrow.setDate(today.getDate()+1);
    return {
      innerWidth: window.innerWidth,
      openScroll: true,
      listMode: true,
      isDelete: false,
      isOpen: false,
      isEdit: false,
      isCreate: false,
      currentMonth: new Date(),
      selectedType: null,
      toDelete: null,
      day:{},
      pax: 1,
      endDate: tomorrow,
      startDate: today,
      repeat: null,
      sensei: {},
      bookingTypes: [],
      availableSchedule: [],
      schedules: [],
      scheduleToEdit:{},
      selectedDate: '',
      ja: ja,
      price: 0,
      startTime: {
        "HH": "10",
        "mm": "00"
      },
      endTime: {
        "HH": "10",
        "mm": "30"
      },
      masks: {
        weekdays: "WWW",
      }
    }
  },
  name: 'Sensei',
  computed: {
    displayBackground(){
      let displayBackground = true
      if(this.isOpen){
        displayBackground = false
      }
      if(this.isEdit){
       displayBackground = false
      }
      return displayBackground
    },
    dateValidator(){
      const isAfter = moment(this.startDate).isBefore(this.endDate);
      if(this.repeat == 'no-repeat'){
        return true
      }
      if(this.isEdit){
        return true
      }
      return isAfter
    },
    timeValidator(){
      const beginningTime = moment.duration(this.startTime.HH + ':' + this.startTime.mm).asSeconds()
      const endTime = moment.duration(this.endTime.HH + ':' + this.endTime.mm).asSeconds()
      return beginningTime <= endTime
    },
    getHourRange(){
      return [[9,22]]
    },
    getMinuteRange(){
      return [[0,50]]
    },
    getTypePrice(){
      return this.getPrice ? this.toYen(this.getPrice['price']) : ''
    },
    getCurrentAvailableSchedule(){
      const check = moment(this.currentMonth, 'YYYY/MM/DD');
      const month = check.format('M');
      const year  = check.format('YYYY');

      let getCurrentAvailableSchedule = this.availableSchedule.filter(as => {
        if(!this.listMode){
          return as
        }
        const asMonth = moment(as.startDateTime , 'YYYY/MM/DD').format('M')
        const asYear = moment(as.startDateTime , 'YYYY/MM/DD').format('YYYY')
        return asMonth == month && year == asYear
      })
      .map(d => {
        d.dates = new Date(d["date"])
        d.customData = {
          title: d["lessonTypeName"][0],
          start: moment().startOf('day').seconds(d['startTime']).format('HH:mm'),
          end: moment().startOf('day').seconds(d['endTime']).format('HH:mm')
        }
        return d
      })
      .sort((a,b) => {
        return new Date(b.startDateTime) - new Date(a.startDateTime);
      });

      return getCurrentAvailableSchedule
    },
    getCurrentMonth(){
      const date = '20' + (this.currentMonth.getYear() - 100) + '年' + (this.currentMonth.getMonth() + 1) + '月'
      return date
    },
    weekdayPosition(){
      return this.startDate.getDay()
    },
    jpPrice(){
      return this.price
    },
    getPrice(){
      return this.bookingTypes.find(d => {
        return d.id == this.selectedType
      })
    }
  },
  methods: {
    customFormatter(date) {
      return moment(date).format('YYYY年 MM月 DD日')
    },
    bookingTypesToSring(){
      const bookingTypeIds = this.bookingTypes.map(bt => bt.id).join(',');
      return bookingTypeIds
    },
    openCreateModal() {
        this.isOpen = true
    },
    close() {
      this.isDelete = false
      this.isEdit = false
      this.isOpen = false
      this.repeat = null
    },
    getLessonType(type){
      let typeName = ""
      if((this.bookingTypes && this.bookingTypes.length > 0) && (type && type[0])){
        this.bookingTypes.forEach(bt => {
          if(bt.recordId ==  type[0]){
            typeName =  bt["名"]
          }
        })
      }
      return typeName
    },
    setPreviousMonth(){
      let month = this.currentMonth.getMonth()
      let year = this.currentMonth.getFullYear()
      if(month == 0){
        month = 11
        year = year - 1
      } else {
        month = month - 1
      }
      let newDate = new Date(this.currentMonth).setMonth(month)
      newDate = new Date(newDate).setFullYear(year)
      this.currentMonth = new Date(newDate)
    },
    setNextMonth(){
      let month = this.currentMonth.getMonth()
      let year = this.currentMonth.getFullYear()
      if(month == 11){
        month = 0
        year = year + 1
      } else {
        month = month + 1
      }
      let newDate = new Date(this.currentMonth).setMonth(month)
      newDate = new Date(newDate).setFullYear(year)
      this.currentMonth = new Date(newDate)
    },
    openEdit(item){
      this.scheduleToEdit = item
      this.isEdit = true
      // set the correct start date with date & time

      const startDate = new Date(item.startDateTime)
      startDate.setHours(startDate.getHours() - 9);
      console.log(startDate)
      // console.log(new Date(new Date(item.startDateTime) + ((new Date().getTimezoneOffset() + (9 * 60)) * 60 * 1000))) 

      this.startDate = startDate
      this.selectedType = item['lessonType'][0]
      this.pax = item['pax']

      const formattedStartSeconds = moment().startOf('day').seconds(item['startTime']).format('HH:mm');
      this.startTime = {
        "HH": formattedStartSeconds.split(':')[0],
        "mm": formattedStartSeconds.split(':')[1]
      }

      const formattedEndSeconds = moment().startOf('day').seconds(item['endTime']).format('HH:mm');
      this.endTime = {
        "HH": formattedEndSeconds.split(':')[0],
        "mm": formattedEndSeconds.split(':')[1]
      }

    },
    openDelete(available){
      this.toDelete = available
      this.isDelete = true
      this.$modal.show('delete-modal')
    },
    closeDelete(){
      this.$modal.hide('delete-modal')
      this.isDelete = true
      this.toDelete = false
    },
    async deleteSchedule(){
      const loader = this.$loading.show()
      const recordId = this.toDelete.recordId
      
      await axios.post("api/delete-schedule", {
        id: recordId
      })

      setTimeout(() => {
        this.getSchedule()
        this.isDelete = false
        this.closeDelete()
        loader.hide()
      }, 2000)

    },
    getDay(pos){
      return pos.getDay() -1
    },
    toYen(total){
      return new Intl.NumberFormat( 'ja-JP', { style: 'currency', currency: 'JPY' })
      .format(total)
    },
    getTime(seconds){
      return new Date(seconds * 1000).toISOString().substr(11, 5);
    },
    getMonthYearDate(date){
     return moment(date, 'YYYY-MM-DD').format('LL')
    },
    async edit(){
      const loader = this.$loading.show()
      // add validator here ????
      console.log({
                  "fields": {
                    "startTime": moment.duration(this.startTime.HH + ':' + this.startTime.mm).asSeconds(),
                    "endTime": moment.duration(this.endTime.HH + ':' + this.endTime.mm).asSeconds(),
                    "startDateTime": moment(this.startDate).set({"hour": this.startTime.HH , "minute": this.startTime.mm}).format('YYYY-MM-DD, HH:mm'),
                    "endDateTime": moment(this.startDate).set({"hour": this.endTime.HH, "minute": this.endTime.mm}).format('YYYY-MM-DD, HH:mm'),
                    "lessonType": [this.selectedType],
                    "pax": this.pax,
                    "date": moment(this.startDate).format('YYYY-MM-DD')
                  }})
      await axios.post('api/edit-teacher-schedule', {
        record: {
                  "id": String(this.scheduleToEdit.recordId),
                  "fields": {
                    "startTime": moment.duration(this.startTime.HH + ':' + this.startTime.mm).asSeconds(),
                    "endTime": moment.duration(this.endTime.HH + ':' + this.endTime.mm).asSeconds(),
                    "startDateTime": moment(this.startDate).set({"hour": this.startTime.HH , "minute": this.startTime.mm}).format('YYYY-MM-DD, HH:mm'),
                    "endDateTime": moment(this.startDate).set({"hour": this.endTime.HH, "minute": this.endTime.mm}).format('YYYY-MM-DD, HH:mm'),
                    "lessonType": [this.selectedType],
                    "pax": this.pax,
                    "date": moment(this.startDate).format('YYYY-MM-DD')
                  }
                }
      })

      this.isEdit = false
      this.close()
      notie.alert({
        type: "success",
        text: "予約を修正しました!",
      })

      this.getSchedule()
      
      setTimeout(() => {
        loader.hide()
      }, 1000)
    
    },
    async saveSchedule(){
      const loader = this.$loading.show()
      if(!this.timeValidator || !this.dateValidator){
        loader.hide()
        return
      }
      const isValid = await this.$validator.validate()
      if (!isValid) {
          loader.hide()
          return
      }
      const repeat = this.repeat
      const id = this.sensei.recordId
      const weekdayPosition = this.weekdayPosition
      const selectedType = this.selectedType
      const pax = this.pax
      let newSchedules = []

      function getDates(startDate, stopDate) {
          var dateArray = [];
          var currentDate = moment(startDate);
          stopDate = moment(stopDate);
          while (currentDate <= stopDate) {
              let push = false
              const currentDay = moment(currentDate).day()
              
              if(repeat == 'daily'){
                push = true
              }
              if(repeat == 'weekly' && weekdayPosition == currentDay){
                push = true
              }

              if(repeat == 'monthly-first-week' && weekdayPosition == currentDay && currentDate.date() <= 7){
                push = true
              }
              
              if(repeat == 'weekday' && currentDay !== 0 &&  currentDay !== 6){
                push = true
              }

              if(push){
                dateArray.push( moment(currentDate).format('YYYY-MM-DD') )
              }

              currentDate = moment(currentDate).add(1, 'days');
          }
          return dateArray;
      }

      newSchedules = getDates()
      if(repeat !== 'no-repeat'){
          newSchedules = getDates(this.startDate, this.endDate)
          newSchedules = newSchedules.map(date => {
            return {
              "fields": {
                "日付": moment(date).format('YYYY-MM-DD'),
                "開始時間": moment.duration(this.startTime.HH + ':' + this.startTime.mm).asSeconds(),
                "終了時間": moment.duration(this.endTime.HH + ':' + this.endTime.mm).asSeconds(),
                "startDateTime": moment(date).set({"hour": this.startTime.HH , "minute": this.startTime.mm}).format('YYYY-MM-DD, HH:mm'),
                "endDateTime": moment(date).set({"hour": this.endTime.HH, "minute": this.endTime.mm}).format('YYYY-MM-DD, HH:mm'),
                "レッスンタイプ": [ selectedType ],
                "先生": [id],
                "人数": pax
              }
            }
          })
      } else {
          newSchedules = [
          {
            "fields": {
                "日付": moment(this.startDate).format('YYYY-MM-DD'),
                "開始時間": moment.duration(this.startTime.HH + ':' + this.startTime.mm).asSeconds(),
                "終了時間": moment.duration(this.endTime.HH + ':' + this.endTime.mm).asSeconds(),
                "startDateTime": moment(this.startDate).set({"hour": this.startTime.HH , "minute": this.startTime.mm}).format('YYYY-MM-DD, HH:mm'),
                "endDateTime": moment(this.startDate).set({"hour": this.endTime.HH, "minute": this.endTime.mm}).format('YYYY-MM-DD, HH:mm'),
                "レッスンタイプ": [ selectedType ],
                "先生": [id],
                "人数": pax
            }
          }
          ]
      }
      
      const saveSchedule = async () => {
            const poppedSchedules = []
            for(let i = 0; i < 9; i++){
                if(newSchedules.length > 0){
                  poppedSchedules.push(newSchedules.shift())
                }
            }

            await axios.post('/api/create-shedules', {
              "records": poppedSchedules
            })

          if(newSchedules.length > 0){
            saveSchedule()
          } else {
            return
          }
      }

      await saveSchedule()

      this.close()
      this.repeat = null
      this.day = ''
      this.endDate = ''
      this.isOpen = false

      notie.alert({
        type: "success",
        text: "日時を保存しました!",
      })

      setTimeout(() => {
        this.getSchedule()
        loader.hide()
      }, 2000)
      
    },
    getDate(time){
      return moment(time).format('LL'); 
    },
    getFullDate(time){
      return moment(time).format('LLLL');
    },
    async getSchedule(){
      const user = this.$store.state.user
      if(!user || !user.teacher){
        return this.$router.push("/")
      }
      // Get teacher info
      const senseiInfo = await axios.get('api/teacher-info', {
        params: { record_id: user.teacher[0] }
      })
      this.senseiInfo = senseiInfo.data
      this.sensei = senseiInfo.data
      // Get available schedule
      const teacherAvailableSchedule = await axios.get('api/teacher-available-schedule', {
        params: { recordId: this.senseiInfo.recordId }
      })
      this.availableSchedule = teacherAvailableSchedule.data.map(d => {
        d.schedules = []
        return d
      })
      // Get booked schedules
      const teacherSchedule = await axios.get('api/teacher-booked-schedule', {
        params: { recordId: this.senseiInfo.recordId }
      })
      this.schedules = teacherSchedule.data
      
      this.availableSchedule = this.availableSchedule.map(as => {
        let matchedSchedules = this.schedules.filter(s => {
          
          return s['available'] == as.recordId
        })
        as.schedules = matchedSchedules
        return as
      })

      // get lesson types
      const bookingTypes = await axios.get('api/lesson-types')
      this.bookingTypes = bookingTypes.data.filter(bt => {
        const includes = this.sensei["lessonType"].includes(bt.id)
        return includes
      })
    },
    onResize() {
      this.innerWidth = window.innerWidth
    }
  },
  beforeMount(){
    setTimeout(() => {
      this.getSchedule()
    }, 2000)
  },
  mounted(){
    this.$nextTick(() => {
        window.addEventListener('resize', this.onResize);
    })
    this.onResize()
  },
  beforeDestroy() {
      window.removeEventListener('resize', this.onResize); 
  },
  created(){
    this.$validator.localize('ja', {
      messages: japanese.messages,
      attributes: {
        pax: '人数',
        lessonType: 'レッスンタイプ',
        startTime: '開始時間',
        endTime: '終了時間',
        endDateTime: '終了日',
        startDateTime: '開始日',
        repeat: '振り返し'
      }
    });
    this.$validator.localize('ja');
  }
}
</script>
<style lang="postcss" scoped>

.tab-forms {
  padding: 20px;
}

.booking-lists-sensei {
  width:95%;
  margin:0 auto;
  margin-bottom: 10px;
  background-color: #f7f8f9;
  cursor: pointer;
  padding:10px;
}

.booking-lists-sensei:hover {
  background-color: #FFF5ED;
}

.booking-lists-sensei > button {
  float: right;
  right:0px;
  background-color: #4abaa7;
  padding:10px;
  color:#FFF;
  border: #4abaa7;
  border-radius: 2px;
  width: 120px;
  cursor: pointer;
  margin:10px;
}

.booking-lists-sensei > button:hover {
  opacity: 0.7;
}

.delete-btn {
  background: #da3323;
  color: #FFF;
}

.edit-btn {
  /* */
  margin-right:10px;
}
.badge {
  background: #54917F;
  border-radius: 4px;
  color: #FFF;
}
.booking-time-info {
  background: #FFFFFF;
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}
.create-btn {
    /*position: absolute;*/
    bottom: 50px;
    margin-right: 20px;
    margin-top: 20px;
    font-size: 50px;
    background: #54917F;
    color: #FFF;
    height: 50px;
    width: 50px;
    line-height: 50px;
    border-radius: 50px;
    text-align: center;
    cursor: pointer;
    z-index: 99;
    float: right;
}
.save-btn {
    background: #54917F;
    color: #FFF;
}
.modal-headers {
  text-align:center;
  font-size:18px;
}
.delete-modal-content{
  padding-top:20px;
}
.modal-buttons {
  float:right;
  padding:20px;
}
.modal-content {
  padding-right: 10px;
  padding-left: 10px;
  padding-top: 40px;
  padding-bottom:10px;
  max-width: 600px;
  margin: 0px auto 20px auto;
}

.modal {
  height: 100%;
  /*overflow: auto;*/
  position: fixed;
}

.cancel-btn {
  margin-right: 10px;
}

/deep/ .custom-calendar.vc-container {
  --day-border: 1px solid #b8c2cc;
  --day-border-highlight: 1px solid #b8c2cc;
  --day-width: 90px;
  --day-height: 90px;
  --weekday-bg: #f8fafc;
  --weekday-border: 1px solid #eaeaea;
  border-radius: 0;
  width: 100%;
  & .vc-header {
    background-color: #f1f5f8;
    padding: 10px 0;
  }
  & .vc-weeks {
    padding: 0;
  }
  & .vc-weekday {
    background-color: var(--weekday-bg);
    border-bottom: var(--weekday-border);
    border-top: var(--weekday-border);
    padding: 5px 0;
  }
  & .vc-day {
    padding: 0 5px 3px 5px;
    text-align: left;
    height: var(--day-height);
    min-width: var(--day-width);
    background-color: white;
    &.weekday-1,
    &.weekday-7 {
      background-color: #eff8ff;
    }
    &:not(.on-bottom) {
      border-bottom: var(--day-border);
      &.weekday-1 {
        border-bottom: var(--day-border-highlight);
      }
    }
    &:not(.on-right) {
      border-right: var(--day-border);
    }
  }
  & .vc-day-dots {
    margin-bottom: 5px;
  }
}

.vc-day {
  padding: 0 5px 3px 5px;
  text-align: left;
  height: var(--day-height);
  min-width: var(--day-width);
  background-color: red;
}

.calendar-text {
  background-color:#4299e1;
  margin:2px;
  border-radius:5px;
  padding:5px;
  color:#FFF;
}

@media screen and (max-width: 777px) {
  .calendar-text {
    font-size:12px;
  }
}

.form-group {
  margin-top: 20px;
  margin-bottom: 20px;
}
.time-picker {
  /*padding: 20px;*/
}

.controls {
  display: none !important;
}

select, input, textarea {
  font-size: 16px;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  select,
  textarea,
  input {
    font-size: 16px;
  }
}

</style>