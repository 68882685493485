<template>
  <CBox>
    <CBox w="100%" mb="0">
      <CImage
        src="/images/bg_1.png"
        h="800"
        w="100%"
        objectFit="cover"/>
      <CImage
        src="/images/logo_1.png"
        class="cover-logo"/>
    </CBox>
    
    <CBox class="to-event-block" align="center" justifyContent="center">
      <CButton color="white" class="button" @click="$router.push('/login')" v-if="!getUser">
        ログイン
      </CButton>
      <CBox style="right:20px;" v-if="getUser">
        <CLink as="router-link" to="/user">
          <CImage
              style="display:inherit;"
              src="/images/user-white.png"
              alt=""
              w="40px"
          />
        </CLink>
      </CBox>
    </CBox>

    <HeaderLarge :style="`position:${$store.state.scrollY < 869 ? 'absolute;' : 'fixed;top:0px;'}`"/>
    
    <CBox class="about-section" id="aboutUs" style="margin-top: 120px;">
      <CFlex w="100%" align="center" justifyContent="center">
          <CImage
            src="/images/icon_right.png"
            h="60px"
            w="100px"
          />
          <CHeading class="home-headers" color="#2A4750" textAlign="center" mb="10">
            ABOUT US
          </CHeading>
          <CImage
            src="/images/icon_right.png"
            h="60px"
            w="100px"
          />
      </CFlex>
      <div class="about-us-sub">
        トレーラーガーデンについて
      </div>
      <div class="about-us-text" v-if="$store.state.about">
        {{  $store.state.about.text  }}
      </div>
      <div class="about-us-image"></div>
      <div class="about-line">
        <CImage
        style="margin-top:64px;margin-bottom:64px;"
        src="/images/line.png"
        h="auto"
        w="50px"
        />
        <div class="line-info">
          公式LINEに登録することで、カフェのモバイルオーダーやイベント・サービスの予約がより簡単にできるようになります。
        </div>
          <button class="line-info-button" @click="openLink('https://liff.line.me/1645278921-kWRPP32q/?accountId=605uamre')">
            公式LINEに登録する
          </button>
      </div>
    </CBox>
    <div style="width:100%;">
      <a href="https://tabiiro.jp/book/indivi/leisure/206595/" target="_blank"><img style="margin:0px auto" src="https://tabiiro.jp/book/indivi/leisure/206595/bnr/s660_116.jpg" border="5" alt="旅色に紹介されました"></a>
    </div>
    <CBox class="landing-section" id="cafe">
      <CFlex w="100%" align="center" justifyContent="center">
          <CImage
            src="/images/icon_right.png"
            h="60px"
            w="100px"/>
          <CHeading class="home-headers" color="#2A4750" textAlign="center" mb="10">
            CAFE
          </CHeading>
          <CImage
            src="/images/icon_right.png"
            h="60px"
            w="100px"/>
      </CFlex>
      <div class="cafe-info" v-if="$store.state.cafe">
        {{  $store.state.cafe.text  }}
      </div>
      <CFlex
        justifyContent="center"
        class="card-container">

      <Card
          v-for="menu in menus"
          :key="menu.id"
          :image="menu.image"
          :title="menu.title"
          :description="menu.body_html"
          class="normal-card"
          type="normal"
          :width="getCardWidth"
          />
      </CFlex>
      <CBox align="center" mt="50" @click="goToPage" class="page-to-go">
          <CImage
            src="/images/tape.png"
            h="60px"
            w="300px"
            objectFit="cover"/>
          <div class="more-text">
            <CLink
              :disabled="getUser ? false : true"
              fontSize="1xl"
              margin="0"
              as="router-link"
              to="/cafe"
              class="link"
              >{{  getUser ? "カフェを見る" :  "準備中" }}</CLink>
          </div>
      </CBox>
    </CBox>

    <CBox class="landing-section" id="studio">
      <CFlex w="100%" 
        align="center" 
        justifyContent="center">
          <CImage
            src="/images/icon_right.png"
            h="60px"
            w="100px"/>
          <CHeading class="home-headers" color="#2A4750" textAlign="center" mb="10" @click="$router.push('/studio')">
            STUDIO
          </CHeading>
          <CImage
            src="/images/icon_right.png"
            h="60px"
            w="100px"/>
      </CFlex>
      <div class="studio-info" v-if="$store.state.studio">
        {{  $store.state.studio.text  }}
      </div>
      <CFlex
        justifyContent="center"
        class="card-container">
      <Card
          v-for="lessonType in lessonTypes"
          :key="lessonType.id"
          :image="lessonType.image"
          :title="lessonType.title"
          :description="lessonType.body_html"
          class="normal-card"
          type="normal"
          :width="getCardWidth"
          />
      </CFlex>
      <CBox align="center" mt="50" @click="goToPage" class="page-to-go">
          <CImage
            src="/images/tape.png"
            h="60px"
            w="300px"
            objectFit="cover"
          />
          <div class="more-text">
            <CLink
              fontSize="1xl"
              margin="0"
              as="router-link"
              to="/studio"
              class="link section-buttons"
              >STUDIOを見る</CLink>
          </div>
      </CBox>
    </CBox>

    <CBox class="landing-section" id="shop">
      <CFlex w="100%" align="center" justifyContent="center">
          <CImage
            src="/images/icon_right.png"
            h="60px"
            w="100px"
          />
          <CHeading class="home-headers" color="#2A4750" textAlign="center" mb="10">
            STORE
          </CHeading>
          <CImage
            src="/images/icon_right.png"
            h="60px"
            w="100px"
          />
      </CFlex>
      <div class="shop-info"  v-if="$store.state.studio">
        {{  $store.state.studio.text  }}
      </div>
      
      <CBox style="width:100%;padding-bottom:70%;">
        <MultiCard
            v-for="item in shopItems"
            :key="item.id"
            :image="item.image"
            :title="item.title"
            :description="item.body_html"
          />
      </CBox>
      <CBox align="center" mt="50" @click="goToPage" class="page-to-go">
            <CImage
              src="/images/tape.png"
              h="60px"
              w="300px"
              objectFit="cover"/>
            <div class="more-text">
              <!-- <CLink
                :disabled="true"
                fontSize="1xl"
                margin="0"
                as="router-link"
                to="/shop"
                class="link"
                >KOYA</CLink> -->
            <a href="https://www.knitido-plus.jp" target="_blank">ECサイトを見る</a>
            </div>
      </CBox>
    </CBox>

    <CBox class="landing-section" id="shop">
      <CFlex w="100%" align="center" justifyContent="center">
          <CImage
            src="/images/icon_right.png"
            h="60px"
            w="100px"
          />
          <CHeading class="home-headers" color="#2A4750" textAlign="center" mb="10">
            KOYA
          </CHeading>
          <CImage
            src="/images/icon_right.png"
            h="60px"
            w="100px"
          />
      </CFlex>
      <div class="shop-info" v-if="$store.state.studio">
        {{  $store.state.koya.text  }}
      </div>
      <CFlex
        justifyContent="center"
        class="card-container">
      <Card
          v-for="item in cottageItems"
          :key="item.id"
          :image="item.image"
          :title="item.title"
          :description="item.body_html"
          class="normal-card"
          type="normal"
          :width="getCardWidth"
        />
      </CFlex>
      <CBox align="center" mt="50" class="page-to-go">
          <CImage
            src="/images/tape.png"
            h="60px"
            w="300px"
            objectFit="cover"/>
          <div class="more-text">
            <CLink
              :disabled="true"
              fontSize="1xl"
              margin="0"
              as="router-link"
              to="/shop"
              class="link"
              >準備中</CLink>
          </div>
      </CBox>
    </CBox>

  </CBox>
</template>

<script>
import { CImage, CBox, CFlex, CHeading, CLink, CButton} from "@chakra-ui/vue";
import Card from "../components/Card";
import MultiCard from "../components/MultiCard";
import HeaderLarge from "../components/HeaderLarge";
import axios from 'axios';
import Papa from 'papaparse'

export default {
  name: "HomePage",
  components: {
    CBox,
    CFlex,
    Card,
    CHeading,
    CLink,
    CImage,
    HeaderLarge,
    CButton,
    MultiCard
  },
  data(){
    return {
      cottageItems:[],
      events: [],
      lessonTypes: [],
      menus: [],
      shopItems: [],
      innerWidth: 0,
      about: {
        text:'',
        title: ''
      },
      cafe: {
        text:'',
        title: ''
      },
      studio: {
        text:'',
        title: ''
      },
      store: {
        text:'',
        title: ''
      },
      koya: {
        text:'',
        title: ''
      },
      access: {
        text:'',
        title: ''
      }
    }
  },
  computed:{
    getUser(){
      return this.$store.state.user
    },
    getCardWidth(){
      let innerWidth = this.innerWidth
      if(innerWidth > 1200){
        return 1200 / 3 - 20
      }
      return innerWidth > 777 ? (innerWidth / 3) - 20 : innerWidth / 3
    }
  },
  methods:{
    openLink(url){
      window.open(url, '_blank')
    },
    goToPage(){
      //
    },
    onResize() {
      this.innerWidth = window.innerWidth
    }
  },
  async beforeMount(){
    const contents = await axios.get('https://firebasestorage.googleapis.com/v0/b/knitido-trailer-garden.appspot.com/o/%E3%82%B3%E3%83%B3%E3%83%86%E3%83%B3%E3%83%84.csv?alt=media')
    const parsedData = Papa.parse(contents.data, { header: true });
    parsedData.data.forEach((dd) => {
      if(dd['項目'] === 'ABOUT'){
        this.$store.state.about = {
          title: dd['タイトル'],
          text: dd['テクスト']
        }
      }
      if(dd['項目'] === 'CAFE'){
        this.$store.state.cafe = {
          title: dd['タイトル'],
          text: dd['テクスト']
        }
      }
      if(dd['項目'] === 'STUDIO'){
        this.$store.state.studio = {
          title: dd['タイトル'],
          text: dd['テクスト']
        }
      }
      if(dd['項目'] === 'KOYA'){
        this.$store.state.koya = {
          title: dd['タイトル'],
          text: dd['テクスト']
        }
      }
      if(dd['項目'] === 'ACCESS'){
        this.$store.state.access = {
          title: dd['タイトル'],
          text: dd['テクスト']
        }
      }
    })

    // Map new data
    let menus = [
      {
        id:"cafe-1",
        item:"",
        image:"https://firebasestorage.googleapis.com/v0/b/knitido-trailer-garden.appspot.com/o/cafe-1.jpg?alt=media",
        title:"ドリンク",
        body_html:"下津のレモンを使った自家製のオリジナル無添加レモネード。"
      },
      {
        id:"cafe-2",
        image:"https://firebasestorage.googleapis.com/v0/b/knitido-trailer-garden.appspot.com/o/cafe-2.jpg?alt=media",
        title:'“サイモス60”水',
        body_html:"TRAILER TRAILER GARDEN内に流れている水は全て“サイモス60”を使用しています。サイモス60は私たちの身体にとって心地の良い振動波を含んでおり、身体のリズムを整え『自分力』を高めてくれる効果があります。"
      },
      {
        id:"cafe-3",
        image:"https://firebasestorage.googleapis.com/v0/b/knitido-trailer-garden.appspot.com/o/cafe-3.jpg?alt=media",
        title:"チーズケーキ",
        body_html:"TRAILER TRAILER GARDENオリジナルケーキ。抹茶チーズケーキをはじめ、5種類のスイーツをご用意しております。"
      }
    ]

    // filter data that has cafe
    this.menus = menus.filter(d => {
      const findMenu = parsedData.data.find(dd => dd['項目'].includes(d.id))
      if(findMenu){
        d.title = findMenu['タイトル']
        d.body_html = findMenu['テクスト']
      }
      return d
    })
    // filter data that has studio
    const studios = [
      {
        id:"studio-1",
        image:"https://firebasestorage.googleapis.com/v0/b/knitido-trailer-garden.appspot.com/o/studio-1.jpg?alt=media",
        title:"ピラティス",
        body_html:"人気が高まっているピラティスはインナーマッスルや体幹を鍛え、バランスのとれた健康体を作ります。TRAILER GARDENでは肩こり・腰痛・骨盤のゆがみ・冷え性など様々なお悩みに合わせたコースをご用意しております。心身ともに健康になりませんか。"
      },{
        id:"studio-2",
        image:"https://firebasestorage.googleapis.com/v0/b/knitido-trailer-garden.appspot.com/o/studio-2.jpg?alt=media",
        title:"巻き爪",
        body_html:"日本人の10人に1人は巻き爪と言われるほどお悩みの方は少なくはありません。フットケア先進国のドイツでも用いられている国内・国際特許取得のペディグラステクノロジーで痛みがなく、目立たない施術で、日常生活に支障のない足元をサポートします。"
      }
    ]
    this.lessonTypes = studios.filter(d => {
      const findMenu = parsedData.data.find(dd => dd['項目'].includes(d.id))
      if(findMenu){
        d.title = findMenu['タイトル']
        d.body_html = findMenu['テクスト']
      }
      return d
    })
    // filter data that has store
    const shopItems = [
      {
        id:"store-1",
        image:"https://firebasestorage.googleapis.com/v0/b/knitido-trailer-garden.appspot.com/o/store-1.jpg?alt=media",
        title:"TRAILER GARDEN オリジナルブランド商品",
        body_html:""
      },
      {
        id:"store-2",
        image:"https://firebasestorage.googleapis.com/v0/b/knitido-trailer-garden.appspot.com/o/store-2.jpg?alt=media",
        title:'ニッティドオリジナルブランド『Knitido＋』のヨガ・ピラティス用5本指靴下',
        body_html:""
      },
      {
        id:"store-3",
        image:"https://firebasestorage.googleapis.com/v0/b/knitido-trailer-garden.appspot.com/o/store-3.jpg?alt=media",
        title:"TRAILER GARDEN オリジナルブランド商品",
        body_html:""
      },
      {
        id:"store-4",
        image:"https://firebasestorage.googleapis.com/v0/b/knitido-trailer-garden.appspot.com/o/store-4.jpg?alt=media",
        title:"KNITIDO オリジナルニットマスク",
        body_html:""
      },
      {
        id:"store-5",
        image:"https://firebasestorage.googleapis.com/v0/b/knitido-trailer-garden.appspot.com/o/store-5.jpg?alt=media",
        title:'TRAILER GARDEN オリジナルブランド商品',
        body_html:""
      },
      {
        id:"store-6",
        image:"https://firebasestorage.googleapis.com/v0/b/knitido-trailer-garden.appspot.com/o/store-6.jpg?alt=media",
        title:"TRAILER GARDEN オリジナルブランド商品",
        body_html:""
      }
    ]
    this.shopItems = shopItems.filter(d => {
      const findMenu = parsedData.data.find(dd => dd['項目'].includes(d.id))
      if(findMenu){
        d.title = findMenu['タイトル']
        d.body_html = findMenu['テクスト']
      }
      return d
    })
    // filter data that has koya
    const cottageItems = [
      {
        id:"koya",
        image:"https://firebasestorage.googleapis.com/v0/b/knitido-trailer-garden.appspot.com/o/koya.jpg?alt=media",
        title:"小屋",
        body_html:"“糸を編む”を表現した天井がこだわりの小屋。木の香りと植物があり心落ち着く空間となっています。ポップアップストアやワークショップなども開催予定。"
      }
    ]
    this.cottageItems = cottageItems.filter(d => {
      const findMenu = parsedData.data.find(dd => dd['項目'].includes(d.id))
      if(findMenu){
        d.title = findMenu['タイトル']
        d.body_html = findMenu['テクスト']
      }
      return d
    })

  },
  mounted() {
    let hash = this.$route.hash
    this.$nextTick(() => {
        window.addEventListener('resize', this.onResize);
    })
    this.onResize()
    if(hash){
      setTimeout(() => {
      let el = document.getElementById(hash.replace("#",''));
      if (el) {
        var headerOffset = 100;
        var elementPosition = el.getBoundingClientRect().top;
        var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth"
        });
      }
      }, 2000)
    }
  },
  beforeDestroy() {
      window.removeEventListener('resize', this.onResize); 
  }
};
</script>
<style scoped>
.section-buttons {
  font-family: 'Zen Maru Gothic', sans-serif;
}
.line-info {
  text-align:center;
  padding:10px;
  color:gray;
  font-family: 'Zen Maru Gothic', sans-serif;
}
.line-info-button {
  font-family: 'Zen Maru Gothic', sans-serif;
}
.cafe-info {
  text-align:center;
  padding:10px;
  color:gray;
  font-family: 'Zen Maru Gothic', sans-serif;
}
.studio-info {
  text-align:center;
  padding:10px;
  color:gray;
  font-family: 'Zen Maru Gothic', sans-serif;
}
.shop-info {
  text-align:center;
  padding:10px;
  color:gray;
  font-family: 'Zen Maru Gothic', sans-serif;
}
.home-headers {
    font-family: 'Patrick Hand', cursive;
    font-weight: normal;
    font-size: 60px;
    margin-bottom: 0px;
    padding-left: 10px;
    padding-right: 10px; 
}
.landing-section {
  max-width: 1200px;
  margin: 64px auto 0px auto;
}
.button {
  background: #ed6d46;
  box-shadow: 0 9px 46px 8px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  padding: 26px 60px;
  color: #FFFFFF;
}
.card {
  margin: 5px;
}
.normal-card {
  /*margin:10px;*/
}
.top-event {
  margin-top: 160px;
  max-width: 1200px;
}
.more-text {
  margin-top: -40px;
  color: #fff;
  /*font-family: 'Patrick Hand', cursive;*/
  font-family: 'Zen Maru Gothic', sans-serif;
  font-weight: normal;
}
.page-to-go {
  cursor: pointer;
}
.about-us-sub {
  font-family: 'Zen Maru Gothic', sans-serif;
  text-align:center;
  padding:10px;
  color:gray;
  line-height:40px;
}
.about-us-text {
  font-family: 'Zen Maru Gothic', sans-serif;
  color:gray;
  line-height:40px;
  margin-bottom:32px;
  padding: 20px;
}
.about-us-image {
  background-image:url("https://firebasestorage.googleapis.com/v0/b/knitido-trailer-garden.appspot.com/o/about.jpg?alt=media"); 
  width: 100%;
  height: 300px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.about-line {
  max-width: 70%;
  height: auto;
  background: #F2F2F2;
  margin: 20px auto;
  padding: 20px;
  border-radius: 20px;
}
.about-line > img {
  margin: 20px auto;
}
.about-line > button {
  background-color: #A2CBBF;
  padding: 20px;
  width: 200px;
  text-align: center;
  margin-top: 10px;
  border-radius: 10px;
  font-weight: bold;
  margin-left: calc(50% - 100px);
}
.cover-logo{
    width: 240px;
    height: 240px;
    position: absolute;
    z-index: 999;
    top: 280px;
    margin: auto calc(50% - 120px);
}
a:link {
  text-decoration: none;
  color: #FFF;
}
a:visited {
  text-decoration: none;
  color: #FFF;
}
.mid-header {
  padding-left: 50px;
  padding-right: 50px;
  background: #2A4750;
  backdrop-filter: blur(20px);
  width: 100%;
}
.to-event-block {
  cursor:pointer;
  background-color:#2A4750;
  color:#FFF;
  text-align:center;
  padding:20px;
  font-size:20px;
}
.login-btn {
  font-size: 18px;
  border: 1px #FFF solid;
  padding: 5px;
  border-radius: 5px;
  color: #FFF;
  text-decoration: none;
}

@media screen and (min-width: 868px) {
  .to-event-block {
    display: none;
  }
}

@media screen and (max-width: 868px) {
  .cover-logo {
      width: 240px;
      height: 240px;
      position: absolute;
      z-index: 100;
      margin: auto calc(50% - 120px);
  }
  .top-event {
    margin-top: 32px;
  }
  .event-cards {
    display: inline-block;
    width: 100%;
    padding: 10px;
  }
  .event-card {
    margin-bottom: 20px;
  }
  .normal-card {
    margin: 0px;
    padding: 0px;
  }
  .mid-header {
    display: none;
  }
  .about-section {
    padding-left: 0px;
    padding-right: 0px;
    max-width: 1200px;
    margin: 84px auto 0px auto;

  }
  .about-line {
    max-width: 100%;
    border-radius: 0px;
    margin-top: 0px;
  }
  .about-us-text {
    margin-right: 10px;
    margin-left: 10px;
  }
}

</style>