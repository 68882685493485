<template>
  <CBox style="margin:10px auto 0px auto;max-width:1200px;">
    <CBox class="bookings-info">
       <CText>選択中のレッスン</CText>
       <CText style="font-weight:bold;">{{ booking["レッスンタイプ名"][0] }} </CText>
    </CBox>
    <CHeading as="h4" size="16px" weight="400" m="8">
      お支払いに利用するチケットを選択してください
    </CHeading>

    <CBox rounded="lg" class="item-purchase" @click="goToConfirm(false)" v-if="bookChecker.canBook">
      <CText fontSize="18px">
        {{ booking["レッスンタイプ名"][0] }}チケット
      </CText>
      <CText color="#828282">
        チケット残り： {{ ticketsLeft }}枚
      </CText>
    </CBox>
    <CBox></CBox>
    <CBox rounded="lg" class="item-purchase" @click="goToConfirm(true)" v-if="bookChecker.canMultiBook">
      <CText fontSize="18px">
        {{ booking["タイプ名"][0] }}回数券チケット
      </CText>
      <CText color="#828282">
        チケット残り： {{ multiTicketsLeft }}枚
      </CText>
    </CBox>
    
    <CBox mt="44px" d="flex" justifyContent="center" v-if="!canBook">
        <CButton backgroundColor="#EF857D" color="#ffffff" @click="goToPurchase">
          新しくチケットを購入する
        </CButton>
    </CBox>

    <CBox mt="51px" mb="62px" d="flex" justifyContent="center">
          <CButton
          bg="#F2B5B1"
          h="52px"
          fontWeight="bold"
          color="#ffffff"
          @click="goBack">
        日程画面に戻る
      </CButton>
    </CBox>
  </CBox>
</template>

<script>
import {CBox, CHeading, CText, CButton} from "@chakra-ui/vue";
import moment from 'moment'

export default {
  name: "StepBookingThird",
  components: {
    CBox,
    CHeading,
    CText,
    CButton,
  },
  computed:{
    ticketsLeft(){
      const bookingSku = this.booking.sku[0]
      const currentSkuTicket = this.$store.state.tickets.find(ticket => {
        return ticket.sku === bookingSku
      })
      return currentSkuTicket.available
    },
    multiTicketsLeft(){
      const multiTicket = this.$store.state.tickets.find(ticket => {
          return ticket.sku === '10-ticket'
      })
      return multiTicket.available
    },
    canBook(){
      return this.bookChecker.canBook || this.bookChecker.canMultiBook
    },
    bookChecker(){
      let canBook = false
      let canMultiBook = false
      const bookingSku = this.booking.sku[0]

      // find tickets of selected sku
      const currentSkuTicket = this.$store.state.tickets.find(ticket => {
        return ticket.sku === bookingSku
      })

      // if there are enough tickets to make a booking
      if(currentSkuTicket && currentSkuTicket.available > 0){
        canBook = true
      }
      
      // check if user has multi ticket
      const hasMultiTicket = this.$store.state.tickets.find(ticket => {
        return ticket.sku === '10-ticket'
      })
      if(hasMultiTicket && hasMultiTicket.available >= currentSkuTicket.multiTicketRule){
        canMultiBook = true
      }
      
      return {
        canBook,
        canMultiBook
      }
    }
  },
  methods:{
    goToPurchase(){
      const senseiId = this.booking["先生"][0]
      localStorage.setItem("booking-id", this.booking.record_id)
      localStorage.setItem("teacher-id", senseiId)
      this.$router.push("/purchase-ticket")
    },
    getTime(time) {
      return moment(time).utc().format("hh:mm");
    },
    getDateTime(time) {
      return moment(time).utc().format("LLLL");
    },
    goToConfirm(multi){
      if(!this.canBook){
        return
      }
      let ticketTypeId = ''

      if(multi && this.bookChecker.canMultiBook){
        const multiTicket = this.$store.state.tickets.find(ticket => {
          return ticket.sku === '10-ticket'
        })
        ticketTypeId = multiTicket.id
        this.booking.ticketTypeId = ticketTypeId
        this.booking.ticketType = this.booking["タイプ名"][0] + '回数券チケット'
      }

      if(!multi && this.bookChecker.canBook){
        const bookingSku = this.booking.sku[0]
        const currentSkuTicket = this.$store.state.tickets.find(ticket => {
          return ticket.sku === bookingSku
        })
        ticketTypeId = currentSkuTicket.id
        this.booking.ticketTypeId = ticketTypeId
        this.booking.ticketType = this.booking["レッスンタイプ名"][0] + 'チケット'
      }

      if(!this.booking["予約"]){
        this.setcurrentTicket(this.booking)
        this.nextStep()
        return
      }
      
      if(this.booking["予約"].length < this.booking["人数"]){
        this.setcurrentTicket(this.booking)
        this.nextStep()
      }
    }
  },
  mounted(){
    window.scrollTo(0, 0)
  },
  props: {
    setcurrentTicket:{
      type: Function
    },
    goBack: {
      type: Function
    },
    listCount:{
      type: Array
    },
    nextStep: {
      type: Function
    },
    booking: {
      type: Object
    },
    user: {
      type: Object
    }
  }
}
</script>

<style scoped>
.item-purchase {
  cursor: pointer;
  background: #fff;
  border-width: 1px;
  margin-bottom: 8px;
  padding: 24px;
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.14), 0 1px 8px rgba(0, 0, 0, 0.12);
  margin-left:10px;
  margin-right:10px;
}
.bookings-info {
  background: #F0F0F0;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 16px;
  margin-left: 20px;
  margin-right: 20px;
}
</style>