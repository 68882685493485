<template>
  <CBox>
    <HeaderLarge v-if="$route.path !== '/'" style="position:fixed;z-index: 99;" />
    <CBox class="header-mobile">
      <!--
      <CBox
        w="100%"
        h="40px"
        bg="#2C4A55"
        d="flex"
        alignItems="center"
        justifyContent="center"
        v-if="!isTop">
      <div style="color:#ffffff;">4月13日にプレオープン！</div>
      </CBox>
      -->
      <CBox class="menu-container-mobile">
        <CBox h="60px" d="flex" alignItems="center" justifyContent="center" v-if="!isTop">
          <CBox align="center" justifyContent="center">
            <CLink as="router-link" to="/">
              <CImage
                  objectFit="cover"
                  src="/images/trailer_garden_logo_2.png"
                  alt=""
                  class="trailer-garden-logo"
              />
            </CLink>
          </CBox>
          <CBox style="position:absolute;right:20px;" v-if="getUser">
            <CLink as="router-link" to="/user" style="padding-top: 10px;">
              <CImage
                  src="/images/user.png"
                  alt=""
                  w="40px"
              />
            </CLink>
          </CBox>
          <CBox class="sign-in-btn" v-if="!getUser && $route.path !== '/login'">
            <CLink as="router-link" to="/login" class="login-btn">
            サインイン
            </CLink>
          </CBox>
        </CBox>

        <Slide style="background-color:#3f3f41;" noOverlay :closeOnNavigation="true">
            <!--
            <CLink
              fontSize="2xl"
              margin="0"
              as="router-link"
              to="/event"
              class="link"
              >Event</CLink>
            -->
            <CLink
              fontSize="2xl"
              margin="0"
              class="link"
              @click="scrollToElement('aboutUs')"
              >About us</CLink
            >
            <CLink
              v-if="getUser"
              fontSize="2xl"
              margin="0"
              as="router-link"
              to="/cafe"
              class="link"
              >Cafe</CLink
            >
            <CLink
              fontSize="2xl"
              margin="0"
              as="router-link"
              to="/studio"
              class="link"
              >Studio</CLink
            >
            <CLink
              v-if="getUser"
              fontSize="2xl"
              margin="0"
              as="router-link"
              to="/orders"
              class="link"
              >Order</CLink
            >
            <!--
            <CLink
              fontSize="2xl"
              margin="0"
              target="_blank"
              href="https://trailer-app-dev.myshopify.com/"
              class="link"
              >Shop</CLink
            >
            -->
            <CLink
              fontSize="2xl"
              margin="0"
              @click="scrollToElement('access')"
              class="link"
              >Access</CLink
            >
            <CLink
              fontSize="2xl"
              margin="0"
              @click="scrollToElement('contact')"
              class="link"
              >Contact</CLink
            >
          <!-- only for sensei -->
          <!--
          <CLink
              v-if="$store.state.userTags.includes('sensei')"
              fontSize="2xl"
              margin="0"
              as="router-link"
              to="/export"
              class="link"
              >Export</CLink
            >
          -->
          <!--
          <CLink
              v-if="$store.state.userTags.includes('sensei')"
              fontSize="2xl"
              margin="0"
              as="router-link"
              to="/sensei"
              class="link"
              >Sensei</CLink
            >
          -->
        </Slide>
      </CBox>
    </CBox>
  </CBox>
</template>
<script>
import HeaderLarge from "../components/HeaderLarge";
import { CBox, CLink, CImage } from "@chakra-ui/vue";
import { Slide } from 'vue-burger-menu'
export default {
  name: "Header",
  components: {
    CBox,
    CLink,
    CImage,
    Slide,
    HeaderLarge
  },
  computed:{
    getUser(){
      return this.$store.state.user
    },
    getScrollY(){
      return this.$store.state.scrollY
    },
    getPosition(){
      const isLogin = this.$route.path == '/login'
      const isRegister = this.$route.path == '/register'
      if(isLogin || isRegister){
        return 'inherit'
      }
      return 'fixed'
    },
    getTop(){
      const scrollY = this.getScrollY
      const isRoute = this.$route.path == '/'
      const currentScroll = scrollY > 800 ? 0 : 800 - scrollY
      return isRoute ? currentScroll : 0
    },
    isTop(){
      return this.$route.path == '/'
    }
  },
  methods:{
    scrollToElement(id){
      const path = this.$route.path
      if(path !== '/' && id !== 'access' && id !== 'contact'){
        this.$router.push('/#'+id)
        return
      }
      let el = document.getElementById(id);
      if (el) {
        var headerOffset = 100;
        var elementPosition = el.getBoundingClientRect().top;
        var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth"
        });
      }
    }
  }
};
</script>
<style scoped>
.header {
  padding-left: 25px;
  padding-right: 25px;
  background: #2A4750;
  backdrop-filter: blur(20px);
  position: absolute;
  width: 100%;
  top: 0;
}
.button {
  border: none;
  outline: none;
  cursor: pointer;
  background: #fff;
  border-radius: 5px;
  padding:5px;
}
.menu-container-mobile {
  position: relative;
}
.menu-container .link:not(:last-child) {
  margin-right:20px;
}
.link {
  transition: none;
  font-family: 'Patrick Hand', cursive;
}
.link:hover {
  color: #ffffff;
  text-decoration: none;
}
.header-mobile {
  display: none;
}
.sign-in-btn {
  position:absolute;
  right:20px;
}
.trailer-garden-logo {
  width:220px;
}
.bm-menu {
  background-color: #f5f5f5 !important;
}
.bm-burger-button {
  position: absolute !important;
  width: 20px !important;
  height: 12px !important;
  left: 20px !important;
  top: 26px !important;
  cursor: pointer !important;
}
.bm-burger-bars {
  background: rgba(0, 0, 0, 1);
}
.login-btn {
  border: 1px #333 solid;
  padding: 5px;
  border-radius: 5px;
  color: #333;
  text-decoration: none;
}
@media screen and (max-width: 868px) {
  .header {
    display: none;
  }
  .header-mobile {
    display: block;
    /*height: 60px;*/
    background: #f5f5f5;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 999;
  }
  .link {
    margin: 0;
    color: #000000;
    display: block;
    padding: 20px 40px;
    transition: background 0.2s;
  }
  .link:hover {
    color: #000000;
    background: #dedede;
  }
  .sign-in-btn {
      font-size:12px;
  }
  .trailer-garden-logo {
    width:180px;
  }
}
</style>