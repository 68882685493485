<template>
  <CBox :mb="[60, 160]" class="event-section">
    <CHeading as="h2" size="16px" weight="400" mb="10" style="text-align:center;">
      ご注文・履歴
    </CHeading>
    
    <div class="tab-wrapper">
      <div class="tab-item" v-for="(item, index) in orderTypes" :class="index === currentTab ? 'active': ''" style="min-width: 150px;" v-bind:key="index" @click="handleChangeTab(index,item)">
        {{item.text}}
      </div>
    </div>

    <!-- for schedule -->
    <CBox rounded="lg" class="item-history" v-for="item in currentHistory" :key="item.recordId">
      <div v-if="item.type == 'booking'">
        <CFlex paddingBottom="15px">
          <CAvatar size="xl" :src="item.image" />
          <CBox paddingLeft="15px">
            <CBox fontSize="18px">
              予約: {{ item.lessonTypeName[0] }}
            </CBox>
            <CBox color="#828282">
              日付: {{ item.date[0] }}
            </CBox>
            <CBox color="#828282">
              開始時間: {{ getEndTime(item.startTime[0]) }}
            </CBox>
            <CBox color="#828282">
              終了時間: {{ getEndTime(item.endTime[0]) }}
            </CBox>
          </CBox>
        </CFlex>
         <CButton class="delete-btn" style="float:right;" bg="#E0E0E0" fontSize="16px" h="38px" fontWeight="normal" @click="openDelete(item)" v-if="!isAfterToday(item)">キャンセル</CButton>
      </div>

      <div v-if="item.type == 'event'">
        <CFlex paddingBottom="15px">
          <CAvatar size="xl" :src="item.image" />
          <CBox paddingLeft="15px">
            <CBox fontSize="18px">
              イベント: {{ item.eventName[0] }}
            </CBox>
            <CBox color="#828282">
              日付: {{ item.date[0] }}
            </CBox>
            <!--
            <CBox fontSize="18px" v-html="item.body_html">
            </CBox>
            -->
          </CBox>
        </CFlex>
      </div>

      <div v-if="item.type == 'bento'">
        <CFlex paddingBottom="15px">
          <!--
            <CAvatar size="xl" :src="item.image" />
          -->
          <CBox paddingLeft="15px">
            <CBox fontSize="18px">
              弁当: {{ item.title }}
            </CBox>
            <CBox color="#828282">
              日付: {{ "" }}
            </CBox>
            <CBox color="#828282">
              開始時間: {{ "" }}
            </CBox>
            <CBox fontSize="13px" v-html="item.body_html">
            </CBox>
          </CBox>
        </CFlex>
      </div>

      <div v-if="item.type == 'company-bento'">
        <CFlex paddingBottom="15px">
          <!--
          <CAvatar size="xl" :src="item.image[0]" />
          -->
          <CBox paddingLeft="15px">
            
            <CBox fontSize="18px">
              弁当: {{ item.bentoName[0] }}
            </CBox>
            <CBox color="#828282">
              日付: {{ item.date[0] }}
            </CBox>
            <!--
            <CBox color="#828282">
              詳細: {{ item.details[0] }}
            </CBox>
            -->
          </CBox>
        </CFlex>
        <CButton class="cancel-button" @click="openCancelModal(item)" v-if="canCancel(item)">
          注文をキャンセル
        </CButton>
      </div>
    </CBox>

    <CModal
      class="modal"
      :is-open="isDeleteModal"
      :on-close="close">
      <CModalContent class="modal-content" v-if="deleteItem.id">
        <CModalHeader style="text-align:center;">弁当注文をキャンセル</CModalHeader>
        <CModalCloseButton />
          <CBox style="padding:10px;">
            <CBox fontSize="18px">
              弁当: {{ deleteItem.bentoName[0] }}
            </CBox>
            <CBox color="#828282">
              日付: {{ deleteItem.date[0] }}
            </CBox>
          </CBox>
        <CModalFooter>
          <CButton class="cancel-btn modal-buttons" @click="isDeleteModal = false">キャンセルしません</CButton>
          <CButton class="delete-btn modal-buttons"  @click="cancelBento">弁当注文をキャンセル</CButton>
        </CModalFooter>
      </CModalContent>
    </CModal>

    <CModal
      class="modal"
      :is-open="isCancelBookingModal"
      :on-close="close">
      <CModalContent class="modal-content">
        <CModalHeader style="text-align:center;">レッスンをキャンセル</CModalHeader>
        <CModalCloseButton />
          <CBox v-if="toDelete" style="padding:10px;">
           <CText>{{toDelete['date'][0]}} {{getTime(toDelete['startTime'][0])}}</CText>
           <CText> {{ toDelete.lessonTypeName[0] }}</CText>
          </CBox>
          <CBox class="modal-buttons">
            <CBox align="right" v-if="canCancelSchedule">
              <CButton class="cancel-btn" style="margin-right:10px;" @click="closeDelete">戻る</CButton>
              <CButton class="delete-btn" @click="deleteSchedule">予約をキャンセル</CButton>
            </CBox>
            <CBox align="right" v-else>
              <CButton disabled class="delete-btn" style="font-size:12px;" bg="#E0E0E0" fontSize="16px" h="38px" fontWeight="normal">予約まで24時間を切ったためキャンセルできません</CButton>
              <br>
              <CButton class="cancel-btn" style="margin-top:10px;" @click="closeDelete">戻る</CButton>           
            </CBox>
          </CBox>
      </CModalContent>
    </CModal>

  </CBox>
</template>
<script>
import { CButton, CText, CBox, CFlex, CAvatar, CHeading, CModal, CModalContent, CModalHeader, CModalCloseButton, CModalFooter} from "@chakra-ui/vue";
import axios from "../axios";
import moment from "moment";
moment.locale("ja");
import notie from 'notie'

export default {
  name: "History",
  components: {
    CButton,
    CBox,
    CText,
    CHeading,
    CAvatar,
    CFlex,
    CModal, 
    CModalContent, 
    CModalHeader, 
    CModalCloseButton,
    CModalFooter
  },
  data(){
    return {
      isCancelBookingModal: false,
      schedule: [],
      history: [],
      orders: [],
      companyBentos: [],
      isDeleteModal: false,
      deleteItem: {},
      orderTypes: [],
      currentTab: 0,
      currentType: 'booking',
      toDelete: false,
      innerWidth: window.innerWidth,
    }
  },
  computed:{
    currentHistory() {
      return this.history.filter((item) => {
        return item.type === this.currentType
      })
    },
    canCancelSchedule(){
      if(!this.toDelete){
        return false
      }
      const today = new Date().getTime()
      const bookingDate = new Date(this.toDelete.startDateTime[0]).getTime()
      const twentyfourHours = (24 * 60 * 60 * 1000)
      if(today > (bookingDate - twentyfourHours)){
        return false
      }
      return true
    },
  },
  methods:{
    isAfterToday(item){
      const now = new Date(Date.now())
      const bookingDateTime =  new Date(new Date(item.startDateTime[0])).getTime() - 540 * 60 * 1000
      return now.getTime() > bookingDateTime
    },
    openDelete(available){
      this.toDelete = available
      this.isDelete = true
      this.isCancelBookingModal = true
    },
    closeDelete(){
      this.isCancelBookingModal = false
      this.isDelete = true
      this.toDelete = false
    },
    handleChangeTab(index, item) {
      this.currentTab = index;
      this.currentType = item.type
    },
    close() {
      this.isCancelBookingModal = false
      this.isDeleteModal = false
    },
    getDateTime(time) {
      return moment(time).utc().format("LLLL");
    },
    getEndTime(time){
      return moment.utc(time*1000).format('HH:mm');
    },
    getTime(seconds){
      return new Date(seconds * 1000).toISOString().substr(11, 5);
    },
    getLessonType(type){
      let typeName = ""
      if((this.bookingTypes && this.bookingTypes.length > 0) && (type && type[0])){
        this.bookingTypes.forEach(bt => {
          if(bt.recordId ==  type[0]){
            typeName =  bt["名"]
          }
        })
      }
      return typeName
    },
    openCancelModal(item){
      this.isDeleteModal = true
      this.deleteItem = item
    },
    async cancelBento(){
      if(this.deleteItem.id){
        const loader = this.$loading.show()
        await axios.post('/api/bento-cancel', {bento_id: this.deleteItem.id})
        this.history = this.history.filter(d => {
          return d.id !== this.deleteItem.id
        })
        this.deleteItem = {}
        this.isDeleteModal = false

        setTimeout(async () => {
          this.getHistory()
          await this.$store.dispatch('getUser')
          await this.$store.dispatch("getTickets")
        }, 2000)
        
        loader.hide()
        notie.alert({
            type: "success",
            text: "弁当の注文をキャンセルしました",
          })

      }
    },
    canCancel(item){
      let canCancel = false
      const today = Date.now()
      const bentoDate = new Date(item.date).getTime()
      // 12hr to milliseconds
      const halfDay = 43200000
      // Can cancel ordered bentos 12hrs before order day
      if(today < (bentoDate - halfDay)){
        canCancel = true
      }
      return canCancel
    },
    async getHistory(){
      const loader = this.$loading.show()
      let history = []

      // fix here for refresh
      const user = this.$store.state.user
      // users booking schedule
      const schedule = await axios.get('/api/schedule', {
        params: { email: user.email }
      })

      if(schedule.data.length > 0){
        history = schedule.data.map(d => {
          d.type = "booking"
          if(d.image && d.image[0]){
              d.image = d.image[0].url
          }
          return d
        })
      }
      
      // Get my events
      const events = await axios.get('/api/my-events', {
        params: { id: user.record_id }
      })
      this.events = events.data.forEach(d => {
        d.type = "event"
        if(d.image && d.image[0]){
              d.image = d.image[0].url
        }
        history.push(d)
      })

      // Get company bentos
      const companyBentos = await axios.get('/api/company-bento-orders', {
        params: { user_record_id: user.record_id }
      })
      this.companyBentos = companyBentos.data
      companyBentos.data.forEach(companyBento => {
        history.push({
          ...companyBento,
          type: 'company-bento',
        })
      })
      
      this.history = history.sort(function(a,b){
        return new Date(b.date[0]) - new Date(a.date[0]);
      });

      const orderListType = localStorage.getItem('orderList')
      for( let i=0; i < this.orderTypes.length; i++){
        if(this.orderTypes[i].type === orderListType){
          this.currentTab = i
          this.currentType = this.orderTypes[i].type
        }
      }
      localStorage.removeItem('orderList')
      loader.hide()
    },
    async deleteSchedule(){
      const today = new Date().getTime()
      const bookingDate = new Date(this.toDelete.startDateTime[0]).getTime()
      const twentyfourHours = (24 * 60 * 60 * 1000)
      const recordId = this.toDelete.recordId
      if(today > (bookingDate - twentyfourHours)){
        return
      }
      const loader = this.$loading.show()
      await axios.post("api/cancel-booking", {
        id: recordId
      })

      setTimeout(() => {
        this.getHistory()
        this.closeDelete()
        this.isDelete = false
        this.toDelete  = false
        loader.hide()
        notie.alert({
            type: "success",
            text: "予約をキャンセルしました",
          })
      }, 2000)
    },
    onResize() {
      this.innerWidth = window.innerWidth
    }
  },
  beforeMount(){
    
    setTimeout(() => {
      const user = this.$store.state.user
      this.orderTypes = [
        {
          text: '予約',
          type: 'booking'
        },
        {
          text: 'イベント',
          type: 'event'
        }]
      
      if(user && user.companyId){
          this.orderTypes.push({
            text: '弁当',
            type: 'company-bento'
          })
      }
      this.getHistory()
    }, 3000)
  },
  mounted(){
    this.$nextTick(() => {
        window.addEventListener('resize', this.onResize);
    })
    this.onResize()
  },
  beforeDestroy() {
      window.removeEventListener('resize', this.onResize); 
  },
}
</script>
<style scoped>
.cancel-button {
  float: right;
}
.tab-item {
  cursor: pointer;
  text-align: center;
  padding-bottom: 15px;
}
.tab-item.active {
  color: #A2CBBF;
  border-bottom: 2px solid #A2CBBF;
}
.delete-modal-content{
  padding-top:20px;
}
.modal-headers {
  text-align:center;
  font-size:18px;
}

.tab-wrapper {
  display: flex;
  overflow-x: auto;
  padding-bottom: 10px;
}
.modal-buttons {
  float: right;
  margin:10px;
}
.cancel-btn {
  /* */
}
.delete-btn {
  background: #da3323;
  color: #FFF;
}
.event-section{
  padding: 32px 16px;
  margin-top: 120px;
}
.item-history {
  cursor: pointer;
  background: #fff;
  border-width: 1px;
  margin-bottom: 8px;
  padding: 24px;
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.14), 0 1px 8px rgba(0, 0, 0, 0.12);
  display: flow-root;
}

@media screen and (max-width: 768px) {
  .event-section{
    margin-top: 80px;
  }
}

.modal-content {
  margin-top: 100px;
}

.modal-buttons {
  float:right;
}
</style>