<template>
  <CBox :mb="[60, 160]" class="event-section">
    <CHeading as="h4" size="16px" weight="400" mb="10">
      チケットをお選びください
    </CHeading>
    <div class="tab-wrapper">
      <div class="tab-item" v-for="(item, index) in listProducts" :class="index === currentTab ? 'active': ''" style="min-width: 150px;" v-bind:key="index" @click="handleChangeTab(index)">
        {{item.typeName}}
      </div>
    </div>

    <CBox v-if="currentItem">
      <CBox rounded="lg" class="item-purchase" v-for="(item, index) in currentItem.items" v-bind:key="index" @click="handlePurchase(item)">
        <CBox fontSize="18px">
          {{item.title}}
        </CBox>
        <CBox color="#828282">
          ¥{{ item.variant.price | formatNumber }}
        </CBox>
      </CBox>
    </CBox>
  </CBox>
</template>

<script>
import { CBox, CHeading } from "@chakra-ui/vue";
import axiosCustom from "../axios";
import client from "../client";
import axios from "axios"

const graphQlQuery = (query) => {
  return axios.post("https://trailer-app-dev.myshopify.com/api/graphql",
      query, {
        "headers": {
          "Content-Type": "application/graphql",
          "X-Shopify-Storefront-Access-Token":
                  "2c1e2619b388114ed6685533aa036165",
        },
      })
      .then((response) => response.data);
};

export default {
  name: "PurchaseTicket",
  components: {
    CBox,
    CHeading,
  },
  data() {
    return {
      lessonTypes: [],
      currentTab: 0,
      listProducts: [],
      checkout: { lineItems: [] },
      isLoading: false
    }
  },
  computed: {
    currentItem() {
      return this.listProducts.find((item, index) => {
        return index === this.currentTab
      })
    }
  },
  async mounted() {
    const loader = this.$loading.show()
    this.checkout = await client.checkout.create()
    const ticketRes = await axiosCustom.get('/api/ticket-types')
    this.ticketTypes = ticketRes.data
    const listTypes = {};
    const allProducts = await axios.get('/api/products', {
      params: {
        productType: 'lesson-ticket' 
      } 
    })
    ticketRes.data.forEach((item) => {
        console.log(item)
        if(listTypes[item.type]){
            const skuItems = allProducts.data.find(ap => ap.variant.sku === item.sku)
            listTypes[item.type].items = listTypes[item.type].items.concat([skuItems])
        } else {
          listTypes[item.type] = {
            groupSku: item.type,
            typeName: item.typeName,
            name: item.name,
            items: JSON.parse(JSON.stringify([allProducts.data.find(ap => ap.variant.sku.includes(item.sku))])),
          };
        }
    });
    this.listProducts = Object.values(listTypes)
    console.log(this.listProducts)
    loader.hide()
  },
  methods: {
    handleChangeTab(index) {
      this.currentTab = index;
    },
    async handlePurchase(item) {
      this.$loading.show()
      
      const selectedItem = this.ticketTypes.find(lt => item.variant.sku.includes(lt.sku))
      const token = localStorage.getItem('token');
      // Create checkout
      const checkoutMutation = `mutation {
        checkoutCreate(input: {
          lineItems: [{
            variantId: "${item.variant.admin_graphql_api_id}", 
            quantity: 1 
          }]
        }) {
          checkout {
            id
            webUrl
            lineItems(first: 5) {
              edges {
                node {
                  title
                  quantity
                }
              }
            }
          }
        }
      }`
      const checkout = await graphQlQuery(checkoutMutation)
      const checkoutId = checkout.data.checkoutCreate.checkout.id
      // Add shipping address
      const accociateShippingToCheckout = `mutation { checkoutShippingAddressUpdateV2(shippingAddress: {
        address1: "",
        address2: "",
        city: "",
        company: "",
        firstName: "",
        lastName: " ",
        phone: "",
        province: "",
        zip: ""
      }, checkoutId: "${checkoutId}") {
        checkoutUserErrors {
          code
          field
          message
        }
        checkout {
          id
          shippingAddress {
            firstName
            lastName
            address1
            province
            country
            zip
          }
        }
      }
      }`
      const shippingCheckout = await graphQlQuery(accociateShippingToCheckout)
      console.log(shippingCheckout)

      const customerCheckout = `mutation { checkoutCustomerAssociateV2(checkoutId: "${checkoutId}", customerAccessToken: "${token}") {
          checkout {
            id
          }
          checkoutUserErrors {
            code
            field
            message
          }
          customer {
            id
          }
        }
      }`
      const customerCheckoutRes = await graphQlQuery(customerCheckout)
      console.log(customerCheckoutRes)
      
      const finalCheckout = `query {
        node(id:"${checkoutId}" ) {
          ... on Checkout {
            id
            webUrl
          }
        }
      }`
      const finalCheckoutRes = await graphQlQuery(finalCheckout)
      
      localStorage.setItem('redirect', 'studio')
      if(selectedItem.sku.includes(selectedItem.type)){
        localStorage.setItem('redirect-sku', selectedItem.sku)
      }
      window.open(finalCheckoutRes.data.node.webUrl, "_self")
      // loader.hide()
    },
    onCancel() {
      this.isLoading = false;
    }
  }
};
</script>

<style scoped>
.tab-wrapper {
  display: flex;
  overflow-x: auto;
  padding-bottom: 10px;
}
.tab-item {
  cursor: pointer;
  text-align: center;
  padding-bottom: 15px;
}
.tab-item.active {
  color: #A2CBBF;
  border-bottom: 2px solid #A2CBBF;
}

.event-section {
  padding: 32px 16px;
  margin-top: 40px;
}
.item-purchase {
  background: #fff;
  border-width: 1px;
  margin-bottom: 8px;
  padding: 24px;
  cursor: pointer;
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.14), 0 1px 8px rgba(0, 0, 0, 0.12);
}
</style>