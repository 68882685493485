<template>
  <div style="margin:154px auto 0px auto;max-width:1200px;">
    <CBox class="login-info" m="16px" v-if="!getUser">
      <CBox mb="3">
        <CText>
        イベントに参加するためログインしてください
        </CText>
      </CBox>
      <CButton style="position: inherit;" fontWeight="bold" color="#ffffff" bg="#EF857D" h="52px" @click="goToLogin">
        ログイン
      </CButton>
    </CBox>

    <CBox p="0 20px" v-if="!event.id" style="display:flex;flex-wrap: wrap;">
      <CBox v-for="event in events" :key="event.id" rounded="lg" class="event-type" :mb="[3, 6]" @click="goToEvent(event)">
        <div class="image-line-up" :style="`
          background-image:url(${event.image}); 
          height:${getCardWidth}px;
          width:${getCardWidth}px;
          `">
        </div>
        <CBox p="24px">
          <CText class="title">{{ event.title }}</CText>
          <CText class="time-available">
            {{ getDate(event.date) }} {{ getTime(event.startTime) }} - {{  getTime(event.endTime) }}
          </CText>
          <CText class="booking-type" v-if="event.quantity">
              {{ event.quantity - getBooked(event) }} / {{ event.quantity }} 
          </CText>
          <CText class="booking-type" v-else>
             無料・申込不要
          </CText>
          <CText class="booking-type" v-if="event.price">
             {{ toYen(event.price) }}
          </CText>          
        </CBox>
      </CBox>
    </CBox>
    
    <NoBookingNeeded :goBack="goBack" :event="event" v-if="event && event.eventType === '無料予約無し'"/>
    <FreeBookedEvent :goBack="goBack" :event="event" v-if="event && event.eventType === '無料予約必須'"/>
    <PaidEvent :goBack="goBack" :event="event" v-if="event && event.eventType === '有料'"/>

  </div>
</template>

<script>
import {CButton, CBox, CText} from "@chakra-ui/vue";
import NoBookingNeeded from "../components/EventSection/NoBookingNeeded";
import FreeBookedEvent from "../components/EventSection/FreeBookedEvent";
import PaidEvent from "../components/EventSection/PaidEvent";
import axios from "../axios";
import moment from "moment";

export default {
  name: "EventSection",
  components: {
    CBox,
    CText,
    NoBookingNeeded,
    FreeBookedEvent,
    PaidEvent,
    CButton
  },
  data() {
    return {
      eventType: '',
      events:[],
      event: {},
      user: false,
      innerWidth: 0
    }
  },
  computed:{
    getUser(){
      return this.$store.state.user
    },
    getCardWidth(){
      let innerWidth = this.innerWidth
      if(innerWidth > 1200){
        return 1200 / 3 - 20
      }
      if(innerWidth > 777){
        return innerWidth / 3 - 20
      } else {
        return innerWidth
      }
    }
  },
  methods: {
    toYen(price){
      return new Intl.NumberFormat('ja-JP', { style: 'currency', currency: 'JPY' }).format(price)
    },
    getDate(date){
      return moment(date).format('LL')
    },
    getTime(time){
      return moment.utc(time*1000).format('HH:mm');
    },
    getBooked(event){
      let quantity = event.quantity
      let booked = event.booked
      if(!quantity || quantity == 0){
        return 0
      }
      return quantity - booked.length
    },
    goBack(){
      
      this.event = {}
      this.eventType = ""
      if(this.$route.query.id){
          this.$router.replace({ query: "" });
      }
    },
    goToLogin(){
        this.$router.push("/login?redirect=event")
        return
    },
    goToEvent(event) {
      if(!this.getUser){
        this.$router.push("/login?redirect=event")
        return
      }
      /*
      if(event.booked && event.quantity){
        if(event.quantity <= event.booked.length){
          return
        }
      }
      */

      this.event = event
    },
    getPrice(price){
      return price !== "0" ? new Intl.NumberFormat('ja-JP', { style: 'currency', currency: 'JPY' }).format(price) : ""
    },
    getType(tags){
      tags = tags.map(t => t.trim())
      if(tags.includes('free') && tags.includes('non-bookable')){
        return '無料・申込不要'
      }
      if(tags.includes('free') && tags.includes('bookable')){
        return '無料・申込'
      }
      if(tags.includes('paid')){
        return '有料・申込'
      }
      return ''
    },
    onResize() {
      this.innerWidth = window.innerWidth
    }
  },
  async mounted(){
    
    this.$nextTick(() => {
        window.addEventListener('resize', this.onResize);
    })
    this.onResize()

    const events = await axios.get('/api/events')
    this.events = events.data.map(d => {
      d.image = d.image[0].url
      return d
    })

    if(this.$route.query.id){
      const selectedEvent = this.events.find(e => {
        return e.id === this.$route.query.id
      })
      this.event = selectedEvent
      this.eventType = selectedEvent.eventType
    }
  },
  beforeDestroy() {
      window.removeEventListener('resize', this.onResize); 
  }
}
</script>
<style scoped>
.image-line-up {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.event-type {
  width: calc(33% - 20px);
  margin: 10px;
  display: inline-block;
  cursor: pointer;
  overflow: hidden;
  background: #fff;
  border-width: 1px;
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.14), 0 1px 8px rgba(0, 0, 0, 0.12);
}
.title {
  font-size: 18px;
  font-weight: 500;
  padding-bottom: 10px;
}
.time-available, .booking-type {
  font-size: 16px;
  font-weight: 500;
}
.description {
  padding-top: 10px;
  font-size: 13px;
  color: #828282;
}
.login-info {
   background: #F0F0F0;
   border-radius: 8px;
   display: flex;
   align-items: center;
   justify-content: center;
   flex-direction: column;
   padding: 16px;
}
@media screen and (max-width: 777px) {
  .event-type {
    width: calc(100% - 20px);
    display: inline-block;
  }
}
</style>