import Vue from 'vue'
import VueLoading from 'vue-loading-overlay';
import numeral from "numeral";
import App from './App.vue'
import router from './router'
import store from './store'
import VModal from 'vue-js-modal'
import Chakra, { CThemeProvider, CReset } from '@chakra-ui/vue'
import customTheme from './custom-theme'
import { MonthPicker } from 'vue-month-picker'
import { MonthPickerInput } from 'vue-month-picker'
import * as VeeValidate from 'vee-validate';
import VCalendar from 'v-calendar';
require('dotenv').config()

Vue.use(VeeValidate);
Vue.use(MonthPicker)
Vue.use(MonthPickerInput)
Vue.use(VCalendar, {
  componentPrefix: 'vc',  // Use <vc-calendar /> instead of <v-calendar />
});

import 'vue-loading-overlay/dist/vue-loading.css';

Vue.config.productionTip = false
Vue.use(Chakra, {
  extendTheme: {
    customTheme,
    baseStyles: {
      CButton: {
        borderColor: 'none',
        border: 'none',
        fontWeight: 400,
      },
      CLink: {
        color: 'white',
        textDecoration: "none"
      },
      CText: {
        margin: 0,
      }
    }
  },
})
Vue.use(VModal)
Vue.use(VueLoading);

Vue.filter("formatNumber", function (value) {
  return numeral(value).format("0,0"); // displaying other groupings/separators is possible, look at the docs
});

const app = new Vue({
  router,
  store,
  render: h => h(CThemeProvider, [h(CReset), h(App)]),
})

app.$mount('#app')

