<template>
  <div class="form-wrapper">
    <div v-if="step === 0" class="register-form">
      <div class="heading" >新規会員登録</div>

      <div class="form-group">
        <input @keydown.space.prevent v-validate="{ required:true, regex: '^[a-zA-Zぁ-んァ-ヶｱ-ﾝﾞﾟ一-龠ー]*$' }" name="lastName" v-model="lastName" placeholder="姓（漢字）">
        <span style="color:red;">{{ errors.first('lastName') }}</span>
        <div class="bottom-text">姓（漢字、ひらがな、英語）</div>
      </div>

      <div class="form-group">
        <input @keydown.space.prevent v-validate="{ required:true, regex: '^[a-zA-Zぁ-んァ-ヶｱ-ﾝﾞﾟ一-龠ー]*$' }" name="firstName" v-model="firstName" placeholder="名（漢字）">
        <span style="color:red;">{{ errors.first('firstName') }}</span>
        <div class="bottom-text">名（漢字、ひらがな、英語）</div>
      </div>

      <div class="form-group">
        <input @keydown.space.prevent v-validate="{ required:true, regex: '^[ァ-ヶー]*$' }" name="lastNameKana" v-model="lastNameKana" placeholder="姓（カナ）">
        <span style="color:red;">{{ errors.first('lastNameKana') }}</span>
        <div class="bottom-text">姓（カナ）</div>
      </div>

      <div class="form-group">
        <input @keydown.space.prevent v-validate="{ required:true, regex: '^[ァ-ヶー]*$' }" name="firstNameKana" v-model="firstNameKana" placeholder="名（カナ）">
        <span style="color:red;">{{ errors.first('firstNameKana') }}</span>
        <div class="bottom-text">名（カナ）</div>
      </div>
      
      <div class="form-group">
        <input @keydown.space.prevent type="tel" v-validate="'required'" name="phone" v-model="phone" placeholder="電話番号">
        <span style="color:red;">{{ errors.first('phone') }}</span>
        <div class="bottom-text">電話番号をご入力ください</div>
      </div>

      <div class="form-group">
        <select  v-validate="'required'" name="prefecture" id="prefecture" v-model="prefecture" placeholder="都道府県">
          <option v-for="prefect in prefectures" :key="prefect" :value="prefect" :selected="prefect == prefecture">{{ prefect }}</option>
        </select>
        <div class="bottom-text">都道府県を選択してください</div>
      </div>

      <div class="form-group">
        <input @keydown.space.prevent v-validate="{ required:true }" name="municipality" v-model="municipality" placeholder="市区町村">
        <span style="color:red;">{{ errors.first('municipality') }}</span>
        <div class="bottom-text">市区町村を入力してください</div>
      </div>

      <div class="form-group">
        <input @keydown.space.prevent v-validate="{ required:true }" name="address" v-model="address" placeholder="住所">
        <span style="color:red;">{{ errors.first('address') }}</span>
        <div class="bottom-text">住所を入力してください</div>
      </div>

      <div class="form-group">
        <input @keydown.space.prevent name="building" v-model="building" placeholder="建物">
        <span style="color:red;">{{ errors.first('building') }}</span>
        <div class="bottom-text">建物を入力してください</div>
      </div>

      
      <div class="form-group">
        <input @keydown.space.prevent v-validate="'required|email'" name="email" v-model="email" placeholder="メールアドレス">
        <span style="color:red;">{{ errors.first('email') }}</span>
        <div class="bottom-text">メールアドレスをご入力ください</div>
      </div>

      <div class="form-group">
        <input @keydown.space.prevent v-validate="'required|min:8'" name="password" :type="showPassowrd ? 'text' : 'password'" v-model="password" placeholder="パスワード">
        <span style="color:red;display:block;">{{ errors.first('password') }}</span>
        <div class="password-text" style="padding-top:5px;width:150px;display:inline-block;">半角英数字8文字以上</div>
        <div style="padding-top: 5px;width:150px;float:right;}"> 
          <input class="password-checkbox" type="checkbox" @click="showPassowrd = !showPassowrd">
          <div class="show-password-text">パスワードを表示</div>
        </div>
      </div>

      <div class="form-group" style="margin-top:50px;">
        <div style="text-align:center;"> <input class="mail-magazine-input" name="terms" @click="acceptsMarketing = !acceptsMarketing" type="checkbox"> </div>
        <div style="text-align:center;">メールマガジンの購読を希望する</div>
      </div>

      <div class="form-group" style="margin-top:50px;text-align:center">
        <a @click="openLink('https://trailer.garden/privacy-policy')">
        <span>プライバシーポリシー</span>
        </a>
      </div>

      <div class="btn-group">
        <button
            style="background-color:#54917F;padding:20px;border-radius:5px;margin-bottom:20px;"
            @click="nextStep">
          <router-link to="/register" style="color:#FFF;font-size: 1.1rem;">
            登録
          </router-link>
        </button>
        <button
            style="background-color:rgba(239, 133, 125);padding:20px;border-radius:5px;margin-bottom:20px;">
          <router-link as="router-link" to="/login" style="color:#FFF;font-size: 1.1rem;">
            ログイン
          </router-link>
        </button>
      </div>

    </div>
    <div v-if="step === 1" class="check-register-information">
      <div class="alert">
        <div class="icon-container">
          <Info />
        </div>
        <div style="color:#DA536E;font-weight:bold;font-size:16px;">
          登録内容を確認してください
        </div>
      </div>
      <div class="register-information">
        <div>
          <div>
            <div w="140px" style="font-size:16px;" fontWeight="bold">氏名（漢字）: {{ this.lastName }} {{ this.firstName }} </div>
          </div>
          <div>
            <div w="140px" style="font-size:16px;" fontWeight="bold">氏名（カナ）: {{ this.lastNameKana }} {{ this.firstNameKana }}</div>
          </div>
          <div>
            <div w="140px" style="font-size:16px;" fontWeight="bold">メール: {{ this.email }}</div>
          </div>
          <div>
            <div w="140px" style="font-size:16px;" fontWeight="bold">電話番号: {{ this.phone }}</div>
          </div>
          <div>
            <div w="140px" style="font-size:16px;" fontWeight="bold">都道府県: {{ this.prefecture }}</div>
          </div>
          <div>
            <div w="140px" style="font-size:16px;" fontWeight="bold">市区町村: {{ this.municipality }}</div>
          </div>
          <div>
            <div w="140px" style="font-size:16px;" fontWeight="bold">住所: {{ this.address }}</div>
          </div>
          <div>
            <div w="140px" style="font-size:16px;" fontWeight="bold">パスワード:</div>
            <div style="font-size:16px;">***************</div>
          </div>
        </div>
      </div>
      <div class="btn-group">
        <button
            style="background-color:#54917F;padding:20px;border-radius:5px;margin-bottom:20px;color:#FFF;"
            @click="accountRegister">
          確認
        </button>
        <button
            style="background-color:rgba(239, 133, 125);padding:20px;border-radius:5px;margin-bottom:20px;color:#FFF;"
            @click="previousStep">
            戻る
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "../axios";
import notie from 'notie'
import Info from "../components/icons/Info";
import japanese from 'vee-validate/dist/locale/ja';
const regexGeneralText = /[^a-zA-Zぁ-んァ-ヶｱ-ﾝﾞﾟ一-龠]/gi;
const kanaRegex = /[^ァ-ヶｱ-ﾝﾞﾟー]/gi;
const passwordReg = /[^A-Z0-9]/gi;
const numberRegex = /[^0-9]/gi;

export default {
  name: "Register",
  components: {
    Info,
  },
  data() {
    return {
      address: '',
      building: '',
      municipality: '',
      prefecture: '',
      phone: '',
      acceptsMarketing: false,
      showPassowrd: false,
      terms: false,
      firstName: null,
      lastName: null,
      firstNameKana: null,
      lastNameKana: null,
      email: null,
      password: null,
      customerId: null,
      step: 0,
      isValid: this.firstName && this.lastName &&
          this.firstNameKana && this.lastNameKana &&
          this.email && this.password,
      prefectures:[
        "北海道","青森県","岩手県","宮城県","秋田県","山形県","福島県","茨城県","栃木県","群馬県","埼玉県",
        "千葉県","東京都","神奈川県","新潟県","富山県","石川県","福井県","山梨県","長野県","岐阜県","静岡県",
        "愛知県","三重県","滋賀県","京都府","大阪府","兵庫県","奈良県","和歌山県","鳥取県","島根県",
        "岡山県","広島県","山口県","徳島県","香川県","愛媛県","高知県","福岡県","佐賀県","長崎県","熊本県","大分県","宮崎県","鹿児島県",
        "沖縄県"]
    }
  },
  watch: {
    email(newVal) {
      this.$data.email = newVal.toLowerCase().trim()
    },
    password(newVal) {
      this.$data.password = newVal.replace(passwordReg, '').trim()
    },
    lastName(newVal){
      this.$data.lastName = newVal.replace(regexGeneralText, '').trim()
    },
    firstName(newVal){
      this.$data.firstName = newVal.replace(regexGeneralText, '').trim()
    },
    lastNameKana(newVal){
      this.$data.lastNameKana = newVal.replace(kanaRegex, '').trim()
    },
    firstNameKana(newVal){
      this.$data.firstNameKana = newVal.replace(kanaRegex, '').trim()
    },
    address(newVal){
      this.$data.address = newVal.trim()
    },
    building(newVal){
      this.$data.building = newVal.trim()
    },
    municipality(newVal){
      this.$data.municipality = newVal.trim()
    },
    prefecture(newVal){
      this.$data.prefecture = newVal.replace(regexGeneralText, '').trim()
    },
    phone(newVal){
      this.$data.phone = newVal.replace(numberRegex, '').trim()
    }
  },
  methods: {
    openLink(url){
      window.open(url, '_blank')
    },
    async nextStep() {
      const isValid = await this.$validator.validate()
      if (!isValid) {
          return
      }
      this.step = this.step + 1
      window.scrollTo(0, 0)
    },
    previousStep() {
      this.step = this.step - 1
      window.scrollTo(0, 0)
    },
    async accountRegister() {
      const {
        email, 
        password, 
        firstName, 
        lastName, 
        firstNameKana, 
        lastNameKana, 
        acceptsMarketing,
        address,
        building,
        municipality,
        prefecture,
        phone
      } = this
      const loader = this.$loading.show()
      const isValid = await this.$validator.validate()
      if (!isValid) {
          loader.hide()
          return
      }
      try {
        await axios.post('/api/register', {
          email, 
          password, 
          firstName, 
          lastName, 
          firstNameKana, 
          lastNameKana,
          acceptsMarketing,
          address,
          building,
          municipality,
          prefecture,
          phone
        })
        loader.hide()
        await this.$router.push('/login')
        notie.alert({
          type: 'success',
          text: '登録できました!'
        })
      }
      catch (err) {
        loader.hide()
        const message = typeof err.response !== "undefined" ? err.response.data.message : err.message;
        notie.alert({
          type: 'error',
          text: message
        })
      }
    },
  },
  created() {
    this.$validator.localize('ja', {
      messages: japanese.messages,
      attributes: {
        email: 'メール',
        password: 'パスワード',
        lastName: '姓（漢字）',
        firstName: '名（漢字）',
        lastNameKana: '姓（カナ）',
        firstNameKana: '名（カナ）',
        phone: '電話番号',
        prefecture:'都道府県',
        municipality:'市区町村',
        address: '住所',
        // building: '建物'
      }
    });
    this.$validator.localize('ja');
  }
}
</script>

<style scoped>
.bottom-text{
  padding: 5px;
}
.heading {
  text-align: center;
  font-weight: 400;
  font-size: 1.5em;
  margin-bottom: 20px;
}
.register-form {
  padding: 0 20px;
}
.form-wrapper {
  max-width: 900px;
  margin: 0 auto;
  margin-top: 140px;
}
.check-register-information {
  padding-top: 50px;
}
.register-information {
  padding:20px;
}
.form-group {
  margin-bottom: 10px;
}
.form-group input {
  box-sizing: border-box;
  border-radius: 4px 4px 0 0;
  width: 100%;
  height: 40px;
  outline: none;
  border-bottom: 1px solid #555;
  padding: 35px 20px;
  background: rgba(0, 0, 0, 0.06);
}

.form-group select {
  width: 100%;
  height: 60px;
  border-radius: 4px 4px 0 0;
  box-sizing: border-box;
  border-bottom: 1px solid #555;
  outline: none;
  background: rgba(0, 0, 0, 0.06);
  padding-left: 10px;
}


.password-checkbox {
  box-sizing: none;
  border-radius: 4px 4px 0 0;
  width: 20px !important;
  height: 20px !important;
  outline: 0px !important;
  border-bottom: 1px solid #555;
  padding: 0px !important;
  background: rgba(0, 0, 0, 0.06);
  margin-top: 0px !important;
  float: left;
}
.show-password-text {
  margin-left:25px;
}
.form-group p {
  color: rgba(0, 0, 0, 0.6);
  margin-top: 8px;
}

.btn-group {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 60px 0 40px;
}
.btn {
  padding: 30px 32px;
  font-size: 1.1rem;
  color: white;
  margin: 20px 0;
}
.register-information {
  background: #FFFFFF;
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}
.alert {
  padding: 16px;
  display: flex;
  align-items: center;
  border: 1px solid #DA536E;
  border-radius: 4px;
  margin: 10px;
}
.icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  background: rgba(218, 83, 110, 0.16);
  border-radius: 50%;
}
.mail-magazine-input {
    width: 20px !important;
    height: 20px !important;
    padding: 20px;
    margin: 20px;
}
@media screen and (max-width: 768px) {
  .form-wrapper {
      margin-top: 120px;
  }
}
</style>