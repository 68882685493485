<template>
  <CBox style="margin:10px auto 0px auto;max-width:1200px;">
    <CBox class="bookings-info">
       <CText>選択中のレッスン</CText>
       <CText style="font-weight:bold;">{{ typeName }} </CText>
    </CBox>
    <CHeading as="h4" size="16px" weight="400" mb="8" mt="8" p="0 16px">
      日程を選択してください
    </CHeading>
    <CBox
        d="flex"
        alignItems="center"
        justifyContent="space-between"
        p="0 16px"
        mb="34px">
      <CBox class="badge" :w="['62px', '120px', '120px']" @click="setPrevious">先月</CBox>
      <CText fontSize="24px">{{ getCurrentMonth }}</CText>
      <CBox class="badge" :w="['62px', '120px', '120px']" @click="setNext">翌月</CBox>
    </CBox>
    <CBox class="booking-time-info" p="20px 0" :m="['0', '0 16px']">
      <CBox d="flex" alignItems="center" justifyContent="space-between" p="0 16px" mb="20px"
      v-for="schedule in getCurrentMonthSchedule" :key="schedule.record_id"
      @click="goToNextStep(schedule)"
       :style="'opacity:' + getOpacity(schedule)">
        <CText> {{ getDateTime(schedule.startDateTime) }} - {{getEndTime(schedule.endDateTime)}} </CText>
        <CText style="padding:10px;" fontSize="18px" fontWeight="bold">{{ schedule.booked ? ' 予約済 ' : '' }}  </CText>
        <CText style="padding:10px;" bg="#E0E0E0" fontSize="18px" fontWeight="bold">{{getAvailable(schedule)}}</CText>
      </CBox>
    </CBox>
        <CBox mt="51px" mb="62px" d="flex" justifyContent="center">
          <CButton
          bg="#F2B5B1"
          h="52px"
          fontWeight="bold"
          color="#ffffff"
          @click="goBack">
        先生画面に戻る
      </CButton>
    </CBox>
  </CBox>
</template>

<script>
import {CBox, CButton, CHeading, CText} from "@chakra-ui/vue";
import moment from "moment";
moment.locale("ja");

export default {
  name: "StepBookingSecond",
  components: {
    CBox,
    CHeading,
    CText,
    CButton,
  },
  computed: {
    getCurrentMonth(){
      const date = '20' + (this.currentMonth.getYear() - 100) + '年' + (this.currentMonth.getMonth() + 1) + '月'
      return date
    },
    getCurrentMonthSchedule(){
      const currentYear = this.currentMonth.getYear()
      const currentMonth = this.currentMonth.getMonth()
      const bookings = this.$store.state.schedule

      const thisMonthsSchedule = this.teacherSchedule.filter(schedule => {
        const scheduleDate = new Date(schedule["日付"])
        const scheduleYear = scheduleDate.getYear()
        const scheduleMonth = scheduleDate.getMonth()

        const findBooking = bookings.find(booking => {
          const availableId = booking.available ? booking.available[0] : ''
          return schedule.record_id === availableId
        })
        if(findBooking){
          schedule.booked = true
        }
        let isSchedule = false
        if(schedule.sku && schedule.sku.length > 0){
          if(schedule.sku.find(sku => sku == this.$route.params.type)){
              isSchedule = true
          }
        }

        return currentYear == scheduleYear
        && currentMonth === scheduleMonth
        && isSchedule
      }).sort((a,b) => {
        return new Date(a["startDateTime"]) - new Date(b["startDateTime"]);
      })
      return thisMonthsSchedule
    }
  },
  methods: {
    setPrevious(){
      this.setPreviousMonth()
    },
    setNext(){
      this.setNextMonth()
    },
    getDateTime(time) {
      return moment(time).utc().format("LLLL");
    },
    getEndTime(time){
      return moment(time).utc(time*1000).format("HH:mm");
    },
    getAvailable(item){
      if(!item["予約"]){
        return '残り: ' + item['人数'] + '枠'  + ' (総数: ' + item['人数'] + ')'
      } else if (item["予約"].length && item["予約"].length >= item['人数']) {
        return '満席 : ' + item['人数'] + '枠' + ' (予約:' + item['人数'] + ')'  + ' (総数: ' + item['人数'] + ')'
      }
      return '残り: ' + (Number(item['人数']) - item["予約"].length)  + '枠' + ' (総数: ' + item['人数'] + ')'
    },
    getOpacity(item){
      if(item.booked){
        return '0.5'
      }
      if (item["予約"] && item["予約"].length && item["予約"].length >= item['人数']) {
        return '0.5'
      }
     return '1'
    },
    goToNextStep(item){
      if(item.booked){
        return
      }
      if(item["予約"] && item["予約"].length && item["予約"].length >= item['人数']){
        return
      }
      if(!this.$store.state.user){
        this.$router.push(`/login?redirect=step-booking/${this.$route.params.type}`)
        return
      }
      if(!item["予約"]){
        this.setBooking(item)
        this.nextStep()
        return
      }
      if (item["予約"] && item["予約"].length && item["予約"].length == item['人数']){
        return false
      }
      this.setBooking(item)
      this.nextStep()
    },
  },
  mounted(){
    window.scrollTo(0, 0)
  },
  props: {
    setBooking:{
      type: Function
    },
    goBack: {
      type: Function
    },
    setPreviousMonth:{
      type: Function
    },
    setNextMonth:{
      type: Function
    },
    currentMonth:{
      type: Date
    },
    teacherSchedule:{
      type: Array
    },
    nextStep: {
      type: Function
    },
    typeName: {
      type: String
    }
  }
}
</script>

<style scoped>
.badge {
  height: 28px;
  background: #54917F;
  border-radius: 4px;
  text-align: center;
  font-size: 20px;
  color: #fff;
  font-weight: normal;
  cursor: pointer;
}
.booking-time-info {
  background: #FFFFFF;
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  cursor: pointer;
}
.bookings-info {
  background: #F0F0F0;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 16px;
  margin-left:20px;
  margin-right:20px;
}
</style>