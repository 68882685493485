<template>
<div>
  <CBox class="access-area" id="access">
      <CHeading class="home-headers" color="#2A4750" textAlign="center" mb="10">
            ACCESS
      </CHeading>

      <div class="access-header-jp">
        アクセス
      </div>
      <div class="img-text">
        <div class="access-image" @click="openMap">
          <CImage
            src="/images/mapimg.png"
            h="auto"
            w="300px"
            objectFit="cover"
            class="access-map"
          />
        </div>
        <div class="access-info" v-if="$data.access">
          <div v-html="$data.access.text"></div>
        </div>
      </div>

  </CBox>
  <CBox class="contact" id="contact">
    <CFlex class="container" justify="center" align="center" direction="column">
      <CBox class="overlay" w="100%" h="100%" />
      <CBox class="contact-header overflow" m="120px 0 40px">
        <CText
            class="contact-footer-title"
            :fontSize="['32px', '48px']"
            color="#ffffff"
            m="0"
            align="center"
        >
          Contact
        </CText>
        <CText
            class="contact-footer-sub"
            :fontSize="['16px', '24px']"
            color="#ffffff"
            m="0"
            align="center"
        >
          お問い合わせ
        </CText>
      </CBox>
      <CFlex
        justify="center"
        class="contact-button overflow"
        flexWrap="wrap"
        mb="40px">
        <CFlex align="center" justify="center" class="contact-button-text" @click="openLink('https://forms.gle/umfnqWc894M6Ws1s8')">
          メールでのお問い合わせ</CFlex>
        <CFlex align="center" justify="center" class="contact-button-text" @click="openLink('https://liff.line.me/1645278921-kWRPP32q/?accountId=605uamre')">
          LINE公式アカウントから問い合わせ</CFlex>
      </CFlex>
      <CBox class="overflow" style="cursor: pointer;" @click="openLink('https://www.instagram.com/trailer_garden/')">
        <CImage src="/images/icons8-instagram-50.png"/>
      </CBox>
    </CFlex>
    <CBox bg="#A2CBBF" p=" 4em 0">
      <CFlex
        class="footer-wrapper"
        align="center"
        justify="center"
        flexWrap="wrap">
        <CBox class="footer" @click="openLink('https://knitido.co.jp/')">運営会社WEBサイト</CBox>
        <CBox class="footer" @click="$router.push({name: 'PrivacyPolicy'})">プライバシーポリシー</CBox>
        <CBox class="footer" @click="$router.push({name: 'CommercialTransactionsLaw'})">特定商取引法に基づく表示</CBox>
      </CFlex>
      <CBox class="copyright">Copyright ：© 2022 Knitido All rights reserved.</CBox>
    </CBox>
  </CBox>
</div>
</template>

<script>
import { CBox, CFlex, CImage, CText, CHeading} from "@chakra-ui/vue";
import axios from "axios";
import Papa from "papaparse"

export default {
  name: "Footer",
  components: {
    CBox,
    CFlex,
    CImage,
    CText,
    CHeading,
  },
  data(){
    return {
      display: true,
      access: {
        title: '',
        text: ''
      }
    }
  },
  methods:{
    openLink(url){
      window.open(url, '_blank')
    },
    openMap(){
      window.open('https://goo.gl/maps/ijjEz5MvmPUmD7Hs8', '_blank')
    }
  },
  async beforeMount(){
    const contents = await axios.get('https://firebasestorage.googleapis.com/v0/b/knitido-trailer-garden.appspot.com/o/%E3%82%B3%E3%83%B3%E3%83%86%E3%83%B3%E3%83%84.csv?alt=media')
    const parsedData = Papa.parse(contents.data, { header: true });
    parsedData.data.forEach((dd) => {
      if(dd['項目'] === 'ACCESS'){
        this.$data.access = {
          title: dd['タイトル'],
          text: dd['テクスト']
        }
      }
    })
  }
};
</script>

<style scoped>

.home-headers {
    font-family: 'Patrick Hand', cursive;
    font-weight: normal;
    font-size: 60px;
    margin-bottom: 0px;
    padding-left: 10px;
    padding-right: 10px; 
}
.access-header-jp {
  text-align:center;
  padding:10px;
  color:gray;
  font-weight:bold;
  font-family: 'Zen Maru Gothic', sans-serif;
}
.access-image {
  cursor:pointer;
  display:inline-flex;
}
.access-info {
  width: 300px;
  height: 300px;
  padding-left: 60px;
  float: right;
  padding-top: 65px;
  padding-bottom: 16px;
  background-color: #F4F4F4;
}

.access-area {
  background-color: #F4F4F4;
  width: 100%;
  margin-top: 64px;
  padding-bottom: 16px;
}
.access-area > .img-text {
  max-width: 600px;
  margin: 0px auto;
}

.container {
  position: relative;
  height: 578px;
  overflow: hidden;
  background: center no-repeat url("/images/contact.png");
  background-size: cover;
  width: 100%;
}
.overflow {
  z-index: 10;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  background: #2A4750;
}

.contact-button-text {
  border-radius: 8px;
  background-color: #fff;
  width: 330px;
  height: 80px;
  margin: 0 20px;
  opacity: 0.9;
  cursor: pointer;
}

.footer {
  margin: 10px 20px;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.0703846px;
  text-decoration-line: underline;
  color: #ffffff;
  cursor: pointer;
}

.copyright {
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.0703846px;
  color: #fff;
}

.contact-footer-title {
    font-family: 'Patrick Hand', cursive;
    font-weight: normal;
}

.contact-footer-sub {
    font-family: 'Zen Maru Gothic', sans-serif;
    font-weight: 400;
}

@media screen and (max-width: 868px) {
  .access-image {
    display:inline-block;
    width: 100%;
  }
  .access-map {
      margin: 0px auto;
  }
  .access-info {
    width: 100%;
    text-align: center;
    height: auto;
    padding: 0px;
    padding-top: 40px;
    padding-bottom: 16px;
  }
  .access-info {
    float: none;
  }
}
@media screen and (max-width: 815px) {
  .contact-header {
    margin-top: 40px;
  }
  .contact-button {
    width: 100%;
  }
  .contact-button-text {
    margin: 10px 0;
    width: 90%;
  }
  .footer-wrapper {
    justify-content: flex-start;
  }
  .footer {
    margin-top: 0;
  }
  .copyright {
    text-align: initial;
    margin: 20px;
  }
}

</style>
