<template>
  <div class="user-page">
    <CText fontSize="16px" fontWeight="bold" m="30">
      ご登録情報
    </CText>

    <CBox rounded="lg" class="user-info" v-if="getUser">
      <CList p="4">
        <CListItem d="flex" alignItems="center" p="2">
          <CText minW="140px" fontSize="16px" fontWeight="bold">氏名（漢字）:</CText>
          <CText fontSize="16px">
            {{ getUser.name }}
          </CText>
        </CListItem>
        <CListItem d="flex" alignItems="center" p="2">
          <CText minW="140px" fontSize="16px" fontWeight="bold">氏名（カナ）:</CText>
          <CText fontSize="16px">
            {{ getUser.nameKana }}
          </CText>
        </CListItem>
        <CListItem d="flex" alignItems="center" p="2">
          <CText minW="140px" fontSize="16px" fontWeight="bold">メール:</CText>
          <CText fontSize="16px">
            {{ getUser.email }}
          </CText>
        </CListItem>
        <CListItem d="flex" alignItems="center" p="2">
          <CText minW="140px" fontSize="16px" fontWeight="bold">電話番号:</CText>
          <CText fontSize="16px">
            {{ getUser.phone }}
          </CText>
        </CListItem>
        <CListItem d="flex" alignItems="center" p="2">
          <CText minW="140px" fontSize="16px" fontWeight="bold">都道府県:</CText>
          <CText fontSize="16px">
            {{ getUser.prefecture }}
          </CText>
        </CListItem>
        <CListItem d="flex" alignItems="center" p="2">
          <CText minW="140px" fontSize="16px" fontWeight="bold">市区町村:</CText>
          <CText fontSize="16px">
            {{ getUser.municipality }}
          </CText>
        </CListItem>
        <CListItem d="flex" alignItems="center" p="2">
          <CText minW="140px" fontSize="16px" fontWeight="bold">住所:</CText>
          <CText fontSize="16px">
            {{ getUser.address }}
          </CText>
        </CListItem>
        <CListItem d="flex" alignItems="center" p="2">
          <CText minW="140px" fontSize="16px" fontWeight="bold">建物:</CText>
          <CText fontSize="16px">
            {{ getUser.building }}
          </CText>
        </CListItem>
        <CListItem d="flex" alignItems="center" p="2">
          <CText minW="140px" fontSize="16px" fontWeight="bold">メールマガジン:</CText>
          <CText fontSize="16px">
            {{ getUser.acceptsMarketing ? '希望する' : '希望しない' }}
          </CText>
        </CListItem>
      </CList>
      <CBox mt="10px" mb="62px" d="flex" justifyContent="center">
        <CButton
            bg="#3CB37A"
            h="52px"
            fontWeight="bold"
            color="#ffffff"
            @click="$router.push('/update-user')">
          編集
        </CButton>
      </CBox>
    </CBox>
    
    <CBox rounded="lg" class="mypage-links" @click="$router.push('change-password')">
      パスワードの再発行
    </CBox>

    <CBox rounded="lg" class="mypage-links" @click="$router.push('orders')">
      ご注文履歴
    </CBox>

    <CBox rounded="lg" class="mypage-links" v-if="getUser && getUser.teacher" @click="$router.push('sensei')">
      【先生向け】レッスンの登録
    </CBox>

  <CBox fontSize="16px" color="#DA536E" ml="30" mt="10" class="delete-user-button" @click="$router.push('delete-user')">
    ニッティドトレーラーガーデンを退会する
  </CBox>
  
    <CBox mt="40px" mb="62px" d="flex" justifyContent="center">
      <CButton
          bg="#EC8882"
          h="52px"
          fontWeight="bold"
          color="#ffffff"
          @click="logout">
        ログアウト
      </CButton>
    </CBox>

  </div>
</template>
<script>
import {CBox, CText, CList, CListItem, CButton} from "@chakra-ui/vue";

export default {
  name: "User",
  components:{
    CBox, CText, CList, CListItem, CButton
  },
  methods:{
    logout(){
      localStorage.setItem('token', '');
      
      this.$store.state.user = null
      this.$router.push("/")
    }
  },
  computed:{
    getUser(){
      return this.$store.state.user
    }
  },
  beforeMount(){
    if(!this.$store.state.user){
      this.$router.push("/")
    }
  },
  mounted(){
    
  }
}
</script>
<style scoped>
.mypage-links {
  cursor: pointer;
  background: #fff;
  border-width: 1px;
  margin-bottom: 8px;
  padding: 24px;
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.14), 0 1px 8px rgba(0, 0, 0, 0.12);
  display: flow-root;
  margin: 20px;
}
.delete-user-button {
  cursor: pointer;
}
.user-info {
  background: #fff;
  border-width: 1px;
  margin-bottom: 8px;
  padding: 14px;
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.14), 0 1px 8px rgba(0, 0, 0, 0.12);
  display: flow-root;
}
.btn-group {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.logout-btn {
  padding: 20px 22px;
  font-size: 1.1rem;
  color: white;
  margin: 20px 0;
  cursor: pointer;
  border-radius: 5px;
}
.user-page{
  margin: 120px auto 0px auto;
  max-width: 1200px;
}
</style>
