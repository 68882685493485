<template>
  <div class="privacy-policy">
    <div>
    <h2>プライバシーポリシー</h2>
    <p>
    本ウェブサイト上で提供するサービス（以下「本サービス」）における個人情報の取扱いについて，プライバシーポリシー（個人情報保護方針）を定めます。
    個人情報保護の仕組みを構築し、個人情報保護の重要性の認識と取組みを徹底させることにより、個人情報の保護を行ってまいります。
    </p>
    
    <h3>第1条 個人情報とは</h3>
    <p>
      <ul>
        <li>
        1.「個人情報」とは，個人情報保護法にある「個人情報」を指すものとし、生存する個人に関する情報であり、当該情報に含まれる氏名、生年月日、住所、電話番号、連絡先、その他の記述等により特定の個人を識別できる情報を指します。
        </li>
        <li>
        2. 個人情報のうち「履歴情報および特性情報」とは、上記に定める「個人情報」以外のものをいい、利用頂いたサービスや購入頂いた商品、閲覧したページや広告の履歴、検索キーワード、利用日時、利用方法、利用環境、郵便番号や性別、職業、年齢、IPアドレス、クッキー情報、位置情報、端末の個体識別情報などを指します。
        </li>
      </ul>
    </p>

    <h3> 第2条 個人情報の取得方法</h3>
    <p>
      <ul>
        <li>
          1. 本サービスでは、ユーザーが利用登録をする際に氏名、生年月日、住所、電話番号、メールアドレス、クレジットカード番号などの個人情報をお尋ねすることがあります。また、ユーザーと提携先などとの間でなされた、ユーザーの個人情報を含む取引記録や、決済に関する情報を当社の提携先（情報提供元、広告主、広告配信先などを含む。以下｢提携先｣）などから取得することがあります。
        </li>
        <li>
          2. 本サービスでは、ユーザーについて、利用したサービス、購入した商品、閲覧したページや広告の履歴、検索キーワード、利用日時、利用方法、利用環境（携帯端末を通じてご利用の場合の当該端末の通信状態、利用に際しての各種設定情報なども含む）、IPアドレス、クッキー情報、位置情報、端末の個体識別情報などの履歴情報および特性情報を、当社や提携先のサービスを利用、またはページを閲覧する際に取得いたします。
        </li>
      </ul>
    </p>

    <h3>第3条 個人情報を取得・利用する目的</h3>
    <p>
    本サービスにおいて、個人情報を取得・利用する目的は以下のとおりです。
      <ul>

        <li>
        1. お客様ご自身の登録情報や利用状況の閲覧・修正を行っていただくために、氏
        名、住所、連絡先、支払方法などの登録情報、利用されたサービスや購入された商品、およびそれらの代金などに関する情報を表示する目的
        </li>

        <li>
        2. お客様へのお知らせや商品の送付、必要に応じてご連絡を行うため、氏名や住所、メールアドレスなどの連絡先情報を利用する目的
        </li>

        <li>
        3. 本人確認を行うために、氏名、生年月日、住所、電話番号、クレジットカード番号などの情報を利用する目的
        </li>

        <li>
        4. 商品代金のご請求のため、購入された商品名や数量、利用されたサービスの種類や期間、回数、ご請求金額、氏名、住所やクレジットカード番号などの支払いに関する情報などを利用する目的
        </li>

        <li>
        5. 簡便にデータを入力できるようにするため、登録して頂いた情報を入力画面に表示させたり、お客様のご指示に基づいて他のサービスなど（提携先が提供するものも含む）に転送する目的
        </li>

        <li>
        6. 代金の支払いを遅滞したり第三者に損害を発生させたりするなど、本サービスの利用規約に違反したお客様や、不正・不当な目的でサービスを利用しようとするお客様のご利用をお断りするために、利用態様、氏名や住所など個人を特定するための情報を利用する目的
        </li>

        <li>
        7. お客様からのお問い合わせに対応するために、お問い合わせ内容や代金の請求に関する情報など、お客様に対してサービスを提供するにあたって必要となる情報や、お客様のサービス利用状況、連絡先情報などを利用する目的
        </li>

        <li>
        8. 上記の利用目的に付随する目的
        </li>
      </ul>
    </p>

    <h3>第4条 個人情報の管理</h3>
    <p>
    お客様の個人情報を正確かつ最新の状態に保ち、個人情報への不正アクセス・紛失・破損・改ざん・漏洩などを防止するため、セキュリティシステムの維持・管理体制の整備・社員教育の徹底等の必要な措置を講じ、安全対策を実施し、個人情報の厳重な管理を行ないます。
    </p>

    <h3>第5条 個人情報の開示</h3>
    <p>
      <ul>
        <li>
          1. お客様ご自身から個人情報の開示を求められた場合、お客様に対してのみ、遅滞なくこれを開示します。ただし、開示することにより次のいずれかに該当する場合は、その全部または一部を開示しない場合もあり、開示しない決定をした場合には、その旨を遅滞なく通知します。
          <ul>
            <li>
              ① お客様ご自身または第三者の生命、身体、財産その他の権利利益を害するおそれがある場合
            </li>
            <li>
              ② 当社の業務の適正な実施に著しい支障を及ぼすおそれがある場合
            </li>
            <li>
              ③ その他法令に違反することとなる場合
            </li>
          </ul>
        </li>
        <li>
        2. 前項の定めにかかわらず、履歴情報および特性情報などの個人情報以外の情報については、原則として開示いたしません。
        </li>
      </ul>
    </p>

    <h3>第6条 個人情報の第三者への提供</h3>
    <p>
      <ul>
        <li>
          1.お客様よりお預かりした個人情報は適切に管理し、次のいずれかに該当する場合を除き、あらかじめお客様の同意を得ることなく、第三者に提供することはありません。ただし、個人情報保護法やその他の法令で認められる場合を除きます。
            <ul>
                  <li>
                    ① 法令に基づく場合
                  </li>
                  <li>
                    ② 人の生命、身体または財産の保護のために必要があり、本人の同意を得ることが困難である場合
                  </li>
                  <li>
                    ③ 公衆衛生の向上または児童の健全な育成の推進のために特に必要があり、本人の同意を得ることが困難である場合
                  </li>
                  <li>
                    ④ 国の機関もしくは地方公共団体またはその委託を受けた者が、法令の定める事務を遂行することに対して協力する必要があり、本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがある場合
                  </li>
                  <li>
                    ⑤ あらかじめ、次の事項を告知あるいは公表をしている場合
                      <ul>
                        <li>
                          ・利用目的に第三者への提供を含むこと
                        </li>
                        <li>
                          ・第三者に提供されるデータの項目
                        </li>
                        <li>
                          ・第三者への提供の手段または方法
                        </li>
                        <li>
                          ・本人の求めに応じて個人情報の第三者への提供を停止すること
                        </li>
                  </ul>
              </li>
            </ul>
        </li>
        <li>
          2.前項の定めにかかわらず，次に掲げる場合は第三者には該当しないものとします。
          <ul>
            <li>
            ① 本サービスが利用目的の達成に必要な範囲内において、個人情報の全部または一部を委託する場合
            </li>
            <li>
            ② 合併その他の事由による事業の承継に伴って、個人情報が提供される場合
            </li>
            <li>
            ③ 個人情報を特定の者との間で共同して利用する場合であって、その旨並びに共同して利用される個人情報の項目、共同して利用する者の範囲、利用する者の利用目的および当該個人情報の管理について責任を有する者の氏名または名称について、あらかじめ本人に通知し、または本人が容易に知り得る状態に置いている場合
            </li>
          </ul>
        </li>
        </ul>
    </p>
    <p>

    <h3>第7条 個人情報の安全対策</h3>
    <p>
      本サービスでは、個人情報の正確性及び安全性確保のために、セキュリティに万全の対策を講じております。
      個人情報の保護に関する情報収集を常に行い、最新のセキュリティ対策を行うよう努めます。
    </p>

    <h3>第8条 個人情報の訂正および削除</h3>
    <p>
      <ul>
          <li>
            1. お客様は本サービスが保有している自己の個人情報が誤った情報である場合、本サービスが定める手続きにより、個人情報の訂正または削除を請求することができます。
          </li>
          <li>
            2. 本サービスは、お客様から前項の請求を受けてその請求に応じる必要があると判断した場合、遅滞なく、当該個人情報の訂正または削除を行い、これをお客様に通知いたします。
          </li>
      </ul>
    </p>
    <p>

    <h3>第9条 個人情報の利用停止等</h3>
    <p>
      お客様より個人情報が利用目的の範囲を超えて取り扱われているという理由、または不正に取得されたものであるという理由により、利用の停止または消去（以下「利用停止等」）を求められた場合には、遅滞なく必要な調査を行い、その結果に基づき、個人情報の利用停止等を行い、その旨をお客様に通知します。ただし、個人情報の利用停止等に多額の費用を有する場合、または利用停止等を行うことが困難な場合は、これに代わるべき措置を講じます。
    </p>

    <h3>第10条 法令、規範の遵守とプライバシーポリシーの変更</h3>
    <p>
      <ul>
        <li>
        1.本サービスでは、保有する個人情報に関して適用される日本の法令、その他規範を遵守いたします。
        </li>
        <li>
        2.プライバシーポリシーの内容は、ユーザーに通知することなく、変更することができるものとします。
        </li>
        <li>
        3.本サービスが別途定める場合を除き、変更後のプライバシーポリシーは、本ウェブサイトに掲載した時点で効力が生じるものとします。
        </li>
      </ul>
    </p>

    <h3>第11条 お問い合わせ</h3>
    <p>
      本サービスにおけるプライバシーポリシーおよび個人情報の取り扱いに関するお問い合わせは、下記窓口までご連絡ください。
    </p>
    <p class="contact-info">
      ニッティド株式会社<br>
      住所：〒649-0120 和歌山県海南市下津町丸田68－1<br>
      TEL：073‐492‐0077<br>
      E-MAIL：info@knitido.co.jp<br>
    </p>
    <CBox mt="10px" mb="62px" d="flex" justifyContent="center">
      <CButton color="#fff" bg="#4abaa7" fontSize="16px" h="38px" fontWeight="normal" @click="$router.push('/')">ホームに戻る</CButton>
    </CBox>
  </div>
  </div>
</template>
<script>
import {
    CButton,
    CBox
} from "@chakra-ui/vue";
export default {
  name: "PrivacyPolicy",
  components:{
    CButton,
    CBox
  }
}
</script>
<style scoped>
p {
  padding: 10px 20px;
}
ul {
  list-style: none;
}
li {
  padding: 5px;
}
h2 {
  text-align: center;
  font-size: 20px;
}
h3 {
  padding-left: 20px;  
}
.privacy-policy {
  margin-top:150px;
}
.contact-info {
  width: 300px;
  margin: 20px auto;
  font-size: 18px;
}
</style>