<template>
  <CBox style="margin:10px auto 0px auto;max-width:1200px;">
    <CBox class="bookings-info">
       <CText>選択中のレッスン</CText>
       <CText style="font-weight:bold;">{{getLessonTypeText()}}</CText>
    </CBox>
    <CHeading as="h4" size="16px" weight="400" mb="8" mt="8">
      先生を選択してください
    </CHeading>
    <CBox rounded="lg" class="item-purchase" v-for="(item, index) in list" v-bind:key="index" @click="setSensei(item)">
      <CFlex paddingBottom="15px">
        <CAvatar size="xl" :src="item.image[0].thumbnails.full.url" v-if="item.image"/>
        <CBox paddingLeft="15px">
          <CBox fontSize="18px">
            {{ item.name }}
          </CBox>
          <CBox color="#828282">
            <span>
              {{getLessonTypeText()}}
            </span>
            <!--
            <span v-for="lesson in item.lessonTypes" :key="lesson">
              {{renderLesson(lesson)}}
            </span>
            -->
          </CBox>
        </CBox>
      </CFlex>
      <CBox fontSize="13px" />
    </CBox>
    <CBox mt="51px" mb="62px" d="flex" justifyContent="center">
          <CButton
          bg="#F2B5B1"
          h="52px"
          fontWeight="bold"
          color="#ffffff"
          @click="$router.push('/studio')">
        画面に戻る
      </CButton>
    </CBox>
  </CBox>
</template>

<script>
import { CText, CButton, CBox, CFlex, CAvatar, CHeading } from "@chakra-ui/vue";

export default {
  name: "StepBookingFirst",
  components: {
    CBox,
    CHeading,
    CAvatar,
    CFlex,
    CButton,
    CText
  },
  props: {
    nextStep:{
      type: Function
    },
    getSenseiSchedule: {
      type: Function
    },
    list: Array,
    listTypes: Array
  },
  methods: {
    getLessonTypeText(){
      const lessonType = this.$route.params.type
      const item = this.listTypes.find(item => item.sku === lessonType);
      return item?.name || ''
    },
    setSensei(sensei){
      this.getSenseiSchedule(sensei)
    }
  },
  mounted(){
    window.scrollTo(0, 0)
  },
};
</script>

<style scoped>
.event-section {
  padding: 32px 16px;
}
.item-purchase {
  cursor: pointer;
  background: #fff;
  border-width: 1px;
  margin-bottom: 8px;
  padding: 24px;
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.14), 0 1px 8px rgba(0, 0, 0, 0.12);
}
.bookings-info {
  background: #F0F0F0;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 16px;
  margin-left:20px;
  margin-right:20px;
}
</style>