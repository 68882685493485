<template>
  <CBox>
    <AlertBox
        v-bind:message="alertBox.message"
        v-bind:type="alertBox.type"/>
    <CBox class="confirm-bento" :m="[0, '0 16px']">
      <CList p="4">
        <CListItem d="flex" alignItems="center" p="2">
          <CText minW="140px" fontSize="16px" fontWeight="bold">メニュー</CText>
          <CText fontSize="16px">
            {{ bento.title[0] }}
          </CText>
        </CListItem>
        <CListItem d="flex" alignItems="center" p="2">
          <CText minW="140px" fontSize="16px" fontWeight="bold">金額</CText>
          <CText fontSize="16px">
            {{ getPrice() }}
          </CText>
        </CListItem>
        <CListItem d="flex" alignItems="center" p="2">
          <CText minW="140px" fontSize="16px" fontWeight="bold">日程</CText>
          <CText fontSize="16px">
            {{ bento.date }}
          </CText>
        </CListItem>
        
        <!--
        <CListItem d="flex" alignItems="center" p="2">
        <CText minW="140px" fontSize="16px" fontWeight="bold">詳細</CText>
        <CText fontSize="16px">
          {{ bento.details[0] }}
        </CText>
        </CListItem>
        -->

      </CList>
    </CBox>
    <CBox mt="51px" mb="62px" d="flex" justifyContent="center">
      <CButton
          bg="#F2B5B1"
          h="52px"
          fontWeight="bold"
          color="#ffffff"
          @click="nextStep">
        予約を確定する
      </CButton>
    </CBox>
    <CBox mt="10px" mb="62px" d="flex" justifyContent="center">
      <CButton
          bg="#54917F"
          h="52px"
          fontWeight="bold"
          color="#ffffff"
           @click="backStep">
        弁当に戻る
      </CButton>
    </CBox>
  </CBox>
</template>

<script>
import {CBox, CText, CList, CListItem, CButton} from "@chakra-ui/vue";
import AlertBox from "../AlertBox";

export default {
  name: "ConfirmBento",
  components: {
    CBox,
    CText,
    CList,
    CListItem,
    CButton,
    AlertBox,
  },
  data() {
    return {
      alertBox: {
        type: 'alert',
        message: 'まだ予約は完了していません内容のご確認をお願いいたします',
      }
    }
  },
  methods: {
    getPrice(){
      return new Intl.NumberFormat('ja-JP', { style: 'currency', currency: 'JPY' }).format(this.bento.price[0])
    }
  },
  mounted(){
    window.scrollTo(0, 0)
  },
  props: {
    nextStep: {
      type: Function
    },
    backStep: {
      type: Function
    },
    bento: {
      type: Object
    }
  }
}
</script>

<style scoped>
.confirm-bento {
  background: #FFFFFF;
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}
</style>