<template>
  <CBox mt="110">
    <AlertBox
        v-bind:type="alertBox.type"
        v-bind:message="alertBox.message"
    />
    <CBox class="ticket-information" :m="[0, '0 16px']">
      <CList p="4">
        <CListItem d="flex" alignItems="center" p="2">
          <CText minW="140px" fontSize="16px" fontWeight="bold">メニュー</CText>
          <CText fontSize="16px">
            {{ booking['レッスンタイプ名'][0] }}
          </CText>
        </CListItem>
        <CListItem d="flex" alignItems="center" p="2">
          <CText minW="140px" fontSize="16px" fontWeight="bold">日程</CText>
          <CText fontSize="16px">
            {{ getDateTime(booking.startDateTime) }}
          </CText>
        </CListItem>
      </CList>
    </CBox>
    <CBox class="btn-group">
      <CLink as="router-link" to="/" :_hover="{transition: 'none', outline: 'none'}">
        <CButton
            class="btn"
            backgroundColor="rgba(239, 133, 125)"
        >
          ホームに戻る
        </CButton>
      </CLink>
      <CLink as="router-link" to="/orders" :_hover="{transition: 'none', outline: 'none'}">
        <CButton
            class="btn"
            backgroundColor="#54917F"
        >
          ご注文・履歴
        </CButton>
      </CLink>
      <!--
      <CLink as="router-link" to="/my-bookings" :_hover="{transition: 'none', outline: 'none'}">
        <CButton
            disabled
            class="btn"
            backgroundColor="#54917F"
            mt="5px"
        >
          カレンダーに登録する
        </CButton>
      </CLink>
      -->
    </CBox>
  </CBox>
</template>

<script>
import {CBox, CText, CList, CListItem, CButton, CLink} from "@chakra-ui/vue";
import AlertBox from "../AlertBox";
import moment from "moment";
moment.locale("ja");

export default {
  name: "ConfirmTicket",
  components: {
    CBox,
    CText,
    CList,
    CListItem,
    CButton,
    CLink,
    AlertBox,
  },
  data() {
    return {
      alertBox: {
        type: 'success',
        message: 'ご予約ありがとうございました',
      }
    }
  },
  methods:{
    getDateTime(time) {
      return moment(time).utc().format("LLLL");
    },
  },
  mounted(){
    window.scrollTo(0, 0)
  },
  props: {
    currentTicket:{
      type: Object
    },
    booking:{
      type: Object
    }
  }
}
</script>

<style scoped>
.ticket-information {
  background: #FFFFFF;
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}
.btn-group {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 60px 0 40px;
}
.btn {
  padding: 30px 32px;
  font-size: 1.1rem;
  color: white;
  margin: 20px 0;
}
</style>