<template>
  <CBox>
    <CBox :w="['100%', '80%']" m="0 auto" v-if="step === 1">
      <AlertBox
          v-bind:message="alertBox.message"
          v-bind:type="alertBox.type"/>
      <CImage :src="event.image" w="100%"/>
      <CBox p="24px 16px">
        <CText class="title"> {{ event.title }} </CText>
        <CText class="time-available">
          {{ getDate(event.date) }} <br/>
          {{ getTime(event.startTime) }} - {{  getTime(event.endTime) }}
        </CText>
        <CText class="booking-type">
          参加費 {{ getPrice() }} （税込み）
        </CText>
        <CText class="booking-type" v-if="event.quantity">
            {{ event.quantity - getBooked(event) }} / {{ event.quantity }}
        </CText>
        <CBox pt="4" v-html="event.body_html">>
        </CBox>
      </CBox>
      <CBox align="center" mt="8" mb="16">
        <CButton
            variant="solid"
            fontWeight="bold"
            fontSize="16px"
            color="#ffffff"
            bg="#F2B5B1"
            h="52px"
            @click="nextStep">
          イベントに申し込む
        </CButton>
      </CBox>

      <CBox align="center" style="padding-bottom:50px;">
        <CButton
            variant="solid"
            fontWeight="bold"
            fontSize="16px"
            color="#ffffff"
            bg="#F2B5B1"
            h="52px"
            @click="goBack">
          戻る
        </CButton>
      </CBox>

    </CBox>

    <ConfirmBookedEvent :goBack="goBack" :event="event" v-if="step === 2" v-bind:next-step="nextStep"/>
    <EventBookingComplete :goBack="goBack" :event="event" v-if="step === 3"/>
  </CBox>
</template>

<script>
import {CBox, CImage, CText, CButton} from "@chakra-ui/vue";
import AlertBox from "../AlertBox";
import ConfirmBookedEvent from "./ConfirmEventBooked";
import EventBookingComplete from "./EventBookingComplete";
import client from "../../client";
import axios from "../../axios";
import moment from "moment"

function utf8_to_b64( str ) {
  return window.btoa(unescape(encodeURIComponent( str )));
}

export default {
  name: "FreeBookedEvent",
  components: {
    CBox,
    CImage,
    CText,
    CButton,
    AlertBox,
    ConfirmBookedEvent,
    EventBookingComplete,
  },
  props: {
    goBack: {
      type: Function
    },
    event: {
      type: Object
    }
  },
  data() {
    return {
      alertBox: {
        type: 'alert',
        message: 'このイベントは参加に申込みが必要です',
      },
      step: 1
    }
  },
  methods: {
    getDate(date){
      return moment(date).format('LL')
    },
    getTime(time){
      return moment.utc(time*1000).format('HH:mm');
    },
    getBooked(event){
      let quantity = event.quantity
      let booked = event.booked
      if(!quantity || quantity == 0){
        return 0
      }
      return quantity - booked.length
    },
    nextStep() {
      if(this.step == 2){
        this.handlePurchase()
        return
      }
      this.step = this.step + 1
    },
    getPrice(){
      return new Intl.NumberFormat('ja-JP', { style: 'currency', currency: 'JPY' }).format(this.event.price)
    },
    async handlePurchase() {
      // Check type of event first
      const loader = this.$loading.show()
      const products = await axios.get('/api/products',{
        params: {
          productType: 'event-ticket' 
        }
      })
      const eventProduct = products.data.find(d => {
        return d.variant.sku == this.event.id
      })
      if(eventProduct){
        const guid = utf8_to_b64(`gid://shopify/ProductVariant/${eventProduct.variant.id}`);
        const lineItemsToAdd = [{variantId: guid, quantity: 1}]
        const checkoutRes = await client.checkout.addLineItems(this.checkout.id, lineItemsToAdd);
        this.checkout = checkoutRes;
        window.open(checkoutRes.webUrl, "_self")
      } else {
        // Don't work
      }
      loader.hide()
    },
  },
  async mounted() {
    window.scrollTo(0, 0)
    const loader = this.$loading.show()
    this.checkout = await client.checkout.create()
    loader.hide()
  },
}
</script>

<style scoped>
.title {
  font-size: 18px;
  font-weight: bold;
  padding-bottom: 10px;
}
.time-available, .booking-type {
  font-size: 16px;
}
.description {
  padding-top: 10px;
  font-size: 13px;
}
</style>