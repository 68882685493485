<template>
  <CBox>
    <AlertBox
        v-bind:message="alertBox.message"
        v-bind:type="alertBox.type"
    />
    <CBox class="ticket-information" :m="[0, '0 16px']">
      <CList p="4">
        <CListItem d="flex" alignItems="center" p="2">
          <CText minW="140px" fontSize="16px" fontWeight="bold">イベント</CText>
          <CText fontSize="16px">
            {{ event.title }}
          </CText>
        </CListItem>
        <CListItem d="flex" alignItems="center" p="2">
          <CText minW="140px" fontSize="16px" fontWeight="bold">日程</CText>
          <CText fontSize="16px">
            {{ getDate(event.date) }} {{ getTime(event.startTime) }} - {{  getTime(event.endTime) }}
          </CText>
        </CListItem>
        <CListItem d="flex" alignItems="center" p="2">
          <CText minW="140px" fontSize="16px" fontWeight="bold">参加費</CText>
          <CText fontSize="16px" v-if="event.price">
            {{ toYen(event.price)}} (税込み)
          </CText>
          <CText fontSize="16px" v-else>
            {{ event.eventType }}
          </CText>

        </CListItem>
      </CList>
    </CBox>
    <CBox mt="51px" mb="62px" d="flex" justifyContent="center">
      <CButton
          bg="#F2B5B1"
          h="52px"
          fontWeight="bold"
          color="#ffffff"
          @click="nextStep">
        イベントに申し込む
      </CButton>
    </CBox>
    <CBox mt="51px" mb="62px" d="flex" justifyContent="center">
          <CButton
          bg="#F2B5B1"
          h="52px"
          fontWeight="bold"
          color="#ffffff"
          @click="goBack">
        戻る
      </CButton>
    </CBox>
  </CBox>
</template>

<script>
import {CBox, CText, CList, CListItem, CButton} from "@chakra-ui/vue";
import AlertBox from "../AlertBox";
import moment from "moment"

export default {
  name: "ConfirmBookedEvent",
  components: {
    CBox,
    CText,
    CList,
    CListItem,
    CButton,
    AlertBox,
  },
  props: {
    goBack: {
      type: Function
    },
    event: {
      type: Object
    },
    nextStep: {
      type: Function
    }
  },
  methods:{
    toYen(){
      return new Intl.NumberFormat('ja-JP', { style: 'currency', currency: 'JPY' }).format(this.event.price)
    },
    getTime(time){
      return moment.utc(time*1000).format('HH:mm');
    },
    getDate(date){
      return moment(date).format('LL')
    }
  },
  data() {
    return {
      alertBox: {
        type: 'alert',
        message: 'まだ予約は完了していません\n' +
            '内容のご確認をお願いいたします',
      }
    }
  },
  mounted(){
    window.scrollTo(0, 0)
  }
}
</script>

<style scoped>
.ticket-information {
  background: #FFFFFF;
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}
</style>