<template>
  <div class="login-form">
      <div class="form-wrapper">
      
      <div class="heading">ログイン</div>
      
      <div class="form-group">
        <input @keydown.space.prevent v-validate="'required|email'" type="email" name="email" v-model="email" placeholder="メールアドレス">
        <span style="color:red;">{{ errors.first('email') }}</span>
        <div style="padding:20px;">
          会員登録時に記入いただいたメールアドレス
        </div>
      </div>

      <div class="form-group">
        <input @keydown.space.prevent v-validate="'required'" type="password" name="password" v-model="password" placeholder="パスワード">
        <span style="color:red;">{{ errors.first('password') }}</span>
        <div style="padding:20px;">パスワードをご記入ください</div>
      </div>

      <div class="btn-group">
          <button
            class="login-btn"
            style="background-color:rgba(239, 133, 125);"
            @click="login">
            ログイン
          </button>
      </div>

      <div class="btn-group">
        <button class="btn" style="border-radius:5px;background-color:#54917F;">
          <router-link style="color:#FFF;" to="/register">
            新規会員登録はこちら
          </router-link>
        </button>
          <router-link style="margin-top:20px;" to="/request-forgot-password">
            パスワードを忘れた方はこちら
          </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import notie from "notie";
import axios from "../axios";
import japanese from 'vee-validate/dist/locale/ja';
import "../assets/time-picker.css"
import "../assets/custom-notie.css"

export default {
  name: "Login",
  data() {
    return {
      locale: 'ja',
      email: null,
      password: null,
      accessToken: null,
      lastName: "",
      firstName: "",
    };
  },
  watch:{
    email(newVal) {
      this.$data.email = newVal.toLowerCase().trim()
    },
  },
  methods: {
    async login() {
      const email = this.email
      const password = this.password
      
      const loader = this.$loading.show()
      const isValid = await this.$validator.validate()
      if (!isValid) {
          loader.hide()
          return
      }
      
      try {
        const {data} = await axios.post('/api/login', {
          email, password
        })
        notie.alert({
          type: "success",
          text: "ログインしました!",
        });

        this.$store.state.user = data.user
        this.$store.state.token = data.token
        localStorage.setItem('token', data.token);

        if(data.senseiData){
          this.$store.state.sensei = data.senseiData.user
        }

        if(data.tags && data.tags.includes('sensei')){
           this.$store.state.userTags = data.tags
           this.$router.push("/sensei")
        } else {
        const redirectRoute = this.$route.query.redirect
        this.$router.push(`/${redirectRoute || ''}`);
        }
        this.$store.dispatch("getUser")
        this.$store.dispatch("getTickets")
        loader.hide()
      } catch {
        loader.hide()

        notie.alert({
          type: "error",
          text: "メールアドレスまたはパスワードが無効です。もう一度お試しください。"
        });

      }
    },
  },
  created() {
    this.$validator.localize('ja', {
      messages: japanese.messages,
      attributes: {
        email: 'メール',
        password: 'パスワード'
      }
    });
    this.$validator.localize('ja');
  },
  mounted(){
    
  }
};
</script>

<style scoped>
.login-form {
  text-align: left;
  padding:20px;
  margin-top: 120px;
}

.heading {
  text-align: center;
  font-weight: 400;
  font-size: 1.5em;
  padding: 10px;
}

.form-wrapper {
  max-width: 900px;
  margin: 0 auto;
}

.form-group input {
  box-sizing: border-box;
  border-radius: 4px 4px 0 0;
  width: 100%;
  height: 40px;
  outline: none;
  border-bottom: 1px solid #555;
  padding: 35px 20px;
  background: rgba(0, 0, 0, 0.06);
}

.form-group p {
  color: rgba(0, 0, 0, 0.6);
  margin-top: 8px;
}

.btn-group {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.btn {
  padding: 30px 32px;
  font-size: 1.1rem;
  color: white;
}

.login-btn {
  padding: 20px 22px;
  font-size: 1.1rem;
  color: white;
  margin: 20px 0;
  cursor: pointer;
  border-radius: 5px;
}

@media screen and (max-width: 768px) {
  .heading {
    font-size: 1.4em;
  }
  .login-form {
    font-size: 1.2em;
  }
  .form-wrapper {
    max-width: 400px;
  }
  .login-form {
    margin-top: 80px;
  }
}
</style>