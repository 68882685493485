<template>
  <div>
    <h1> Takeout </h1>
    <div class="takeout-menus">
      <div class="bento-card" v-for="menu in menus" :key="menu.id">
      <img style="width:100%;" :src="menu.image.src"/>
      <div style="padding:10px;">
        <div class="title">{{ menu.title }}  </div>
        <br>
        <!--
        <p class="description" v-html="menu.body_html">
        </p>
        -->
      </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "../axios";
export default {
  name: "Takeout",
  data() {
    return {
      step: 1,
      menus:[],
      menu: {},
      cart: []
    }
  },
  async beforeMount(){
    // Get cafe menus
    // Cafes
    const menus = await axios.get('/api/products', {
      params: {
        'productType' : 'bento'
      }
    })
    this.menus = menus.data.filter(menu => {
      return menu.tags.includes('cafe') && !menu.tags.includes('bento')
    })
    // same as landing page
  }
}
</script>

<style scoped>
.bento-card {
  width: calc(33%);
  padding:5px;
  display: inline-grid;
}
.takeout-menus {
  display: inline-block;
  margin-top: 64px;
}

@media screen and (max-width: 868px) {
  .takeout-menus {
    margin-top: 90px;
  }
}
</style>