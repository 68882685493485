<template>
  <CBox mt="110">
    <AlertBox
        v-bind:message="alertBox.message"
        v-bind:type="alertBox.type"
    />
    <CBox class="ticket-information" :m="[0, '0 16px']">
      <CList p="4">
        <CListItem d="flex" alignItems="center" p="2">
          <CText minW="140px" fontSize="16px" fontWeight="bold">メニュー</CText>
          <CText fontSize="16px">
            {{ event.title }}
          </CText>
        </CListItem>
        <CListItem d="flex" alignItems="center" p="2">
          <CText minW="140px" fontSize="16px" fontWeight="bold">日程</CText>
          <CText fontSize="16px">
            <!--
            2021年11月23日（月）10:00 - 12:00
            -->
          </CText>
        </CListItem>
        <CListItem d="flex" alignItems="center" p="2">
          <CText minW="140px" fontSize="16px" fontWeight="bold">参加費</CText>
          <CText fontSize="16px">
           <!-- {{ getPrice() }} -->（税込み）
          </CText>
        </CListItem>
      </CList>
    </CBox>
    <CBox mt="51px" mb="8" d="flex" justifyContent="center">
      <CButton
          bg="#F2B5B1"
          h="52px"
          fontWeight="bold"
          color="#ffffff"
          @click="goToHistory">
        ご注文・履歴へ
      </CButton>
    </CBox>
    <CBox mb="70px" d="flex" justifyContent="center">
      <CButton
          disabled
          bg="#A2CBBF"
          h="52px"
          fontWeight="bold"
          color="#ffffff"
          @click="setCalendar">
        カレンダーに登録する
      </CButton>
    </CBox>
  </CBox>
</template>

<script>
import {CBox, CText, CList, CListItem, CButton} from "@chakra-ui/vue";
import AlertBox from "../AlertBox";

export default {
  name: "EventBookingComplete",
  components: {
    CBox,
    CText,
    CList,
    CListItem,
    CButton,
    AlertBox,
  },
  props: {
    event: {
      type: Object
    }
  },
  methods:{
    setCalendar(){
      // 
    },
    goToHistory(){
      this.$router.push('/orders')
    },
    getPrice(){
      return new Intl.NumberFormat('ja-JP', { style: 'currency', currency: 'JPY' }).format(this.event.variant.price)
    }
  },
  data() {
    return {
      alertBox: {
        type: 'success',
        message: 'ご予約ありがとうございました',
      }
    }
  },
  mounted(){
    window.scrollTo(0, 0)
  }
}
</script>

<style scoped>
.ticket-information {
  background: #FFFFFF;
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}
</style>