<template>
  <div class="delete-user-page">
    <CText fontSize="16px" fontWeight="bold" m="30" textAlign="center">
      本当に解約しますか？
    </CText>
    <AlertBox
        v-bind:message="alertBox.message"
        v-bind:type="alertBox.type"/>
    
    <CBox mt="50px" display="flex" justifyContent="center" alignItems="center">
      <CButton color="#FFF" backgroundColor="rgba(239, 133, 125)" @click="deleteUser">
        解約
      </CButton>
    </CBox>

    <CBox mt="50px" display="flex" justifyContent="center" alignItems="center">
      <CButton color="#FFF" backgroundColor="rgba(122, 181, 163)" @click="$router.push('/user')">
        キャンセルして戻る
      </CButton>
    </CBox>
  </div>
</template>
<script>
import { CText, CButton, CBox} from "@chakra-ui/vue";
import AlertBox from "./AlertBox";
import axios from "../axios";
import notie from 'notie'

export default {
  name: "DeleteUser",
  components:{
    CText,
    AlertBox,
    CButton,
    CBox
  },
  data() {
    return {
      passwordConfirm: '',
      alertBox: {
        type: 'alert',
        message: '解約をするとすべての履歴や情報が削除されます。',
      }
    }
  },
  methods:{
    async deleteUser(){
      const loader = this.$loading.show()
      try {
        const user = this.$store.state.user
        await axios.post('/api/delete-user', {
          ...user
        })
        loader.hide()
        localStorage.clear();
        notie.alert({
          type: 'success',
          text: 'ユーザーを解約しました。'
        })
        setTimeout(() => {
          this.$router.push('/')
          location.reload();
        }, 2000)
      } catch (err){
        loader.hide()
        return
      }
    }
  }
}
</script>
<style scoped>
.delete-user-page{
  margin: 120px auto 0px auto;
  max-width: 1200px;
}
</style>
