import Vue from 'vue'
import Vuex from 'vuex'
import axios from "../axios";

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    testMode: false,
    user: null,
    token: null,
    sensei: null,
    userTags: [],
    schedule: [],
    scrollY: 0,
    openScroll: false,
    tickets: [],
    lessonTypes: [],
    ticketTypes: []
  },
  mutations: {
    setUser (state, user) {
      state.user = user
    },
    setUserTags(state, tags){
      state.tags = tags
    },
    setTickets(state, tickets){
      state.tickets = tickets
    },
    setLessonTypes(state, lessonTypes){
      state.lessonTypes = lessonTypes
    },
    setSchedule(state, schedule){
      state.schedule = schedule
    },
    setTicketTypes(state, ticketTypes){
      state.ticketTypes = ticketTypes
    }
  },
  actions: {
    async getUser({commit}) {
      const { data } = await axios.get('/api/get-user-info')
      commit('setUser', data.user)
      commit('setUserTags', data.tags)
      return data
    },
    async getTickets ({ commit, state}) {
      const user = state.user
      console.log(user)
      const schedule = await axios.get('/api/schedule', {
        params: { email: user.email }
      })
      const bookings = schedule.data.map(d => {
        d.type = "booking"
        return d
      })
      const ticketTypesRes = await axios.get('/api/ticket-types')

      const myTickets = ticketTypesRes.data.map(lt => {
        let totalused = 0
        if(user[lt.sku]){
          bookings.forEach(b => {
            if(b.ticketSku[0] == lt.sku){
              totalused += (b.totalBookedTickets || 0)
            }
          })
          lt.currentUnused = user[lt.sku]
          lt.totalPurchased = (user[lt.sku+'-total'] || 0)
          lt.available = lt.currentUnused
        }
        return {
          id: lt.id,
          currentUnused: lt.currentUnused,
          totalPurchased: lt.totalPurchased,
          used: totalused,
          sku: lt.sku,
          name: lt.name,
          typeName: lt.typeName,
          available: lt.available,
          multiTicketRule: lt.multiTicketRule
        }
      })
      commit('setTickets', myTickets)
      commit('setSchedule', bookings)
      commit('setTicketTypes', ticketTypesRes.data)
      return myTickets
    },
    async getLessonTypes ({ commit }){
      const lessonTypesRes = await axios.get('/api/lesson-types')
      const lessonTypes = lessonTypesRes.data.reduce((lessons, listItem) => {
        if(!lessons[listItem.group] && listItem.sku){
          lessons[listItem.group] = []
        }
        if(listItem.sku){
          lessons[listItem.group].push(listItem)
        }
        return lessons
      }, {})
      
      commit('setLessonTypes', Object.values(lessonTypes))
      return lessonTypes
    }
  }
})

export default store