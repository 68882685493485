<template>
  <CBox mt="110">
    <AlertBox
        v-bind:message="alertBox.message"
        v-bind:type="alertBox.type"/>
    <CBox class="bento-complete" :m="[0, '0 16px']">
      <CList p="4">
        <CListItem d="flex" alignItems="center" p="2">
          <CText minW="140px" fontSize="16px" fontWeight="bold">メニュー</CText>
          <CText fontSize="16px">
            【従業員限定】{{ bento.title[0] }}
          </CText>
        </CListItem>
        <CListItem d="flex" alignItems="center" p="2">
          <CText minW="140px" fontSize="16px" fontWeight="bold">金額</CText>
          <CText fontSize="16px">
            {{ getPrice() }}
          </CText>
        </CListItem>
        <CListItem d="flex" alignItems="center" p="2">
          <CText minW="140px" fontSize="16px" fontWeight="bold">日程</CText>
          <CText fontSize="16px">
            {{ bento.date }}
          </CText>
        </CListItem>
      </CList>
    </CBox>
    <CBox mt="51px" mb="8" d="flex" justifyContent="center">
      <CButton
          bg="#F2B5B1"
          h="52px"
          fontWeight="bold"
          color="#ffffff"
           @click="myPage">
        ホームに戻る
      </CButton>
    </CBox>
    <CBox mt="51px" mb="8" d="flex" justifyContent="center">
      <CButton
          bg="#54917F"
          h="52px"
          fontWeight="bold"
          color="#ffffff"
           @click="goToOrders">
        ご注文・履歴へ
      </CButton>
    </CBox>
  </CBox>
</template>
<script>

import {CBox, CText, CList, CListItem, CButton} from "@chakra-ui/vue";
import AlertBox from "../AlertBox";

export default {
  name: "BentoComplete",
  components: {
    CBox,
    CText,
    CList,
    CListItem,
    CButton,
    AlertBox,
  },
  props: {
    myPage: {
      type: Function
    },
    bento: {
      type: Object
    }
  },
  methods:{
    goToOrders(){
      localStorage.setItem('orderList', 'company-bento')
      this.$router.push('/orders')
      return 
    },
    getPrice(){
      return new Intl.NumberFormat('ja-JP', { style: 'currency', currency: 'JPY' }).format(this.bento.price[0])
    }
  },
  data() {
    return {
      alertBox: {
        type: 'success',
        message: 'ご予約ありがとうございました',
      }
    }
  },
  mounted(){
    window.scrollTo(0, 0)
  }
}
</script>

<style scoped>
.bento-complete {
  background: #FFFFFF;
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}
</style>