<template>
  <div style="margin:130px auto 0px auto;max-width:1200px;">

        <div class="bento-card" @click="$router.push('bento')" v-if="getUser && getUser.companyId">
          <img style="width:100%;" src="/images/company-bentos.jpg"/>
          <div style="padding:10px;">
            <div class="title">お弁当のご注文</div>
            <p class="description">
              従業員限定の体に優しいお弁当ご注文はこちら
            </p>
          </div>
        </div>
        
        <!--
        <div class="bento-card" @click="$router.push('takeout')">
          <img style="width:100%;" src="/images/cup-of-coffee.png"/>
          <div style="padding:10px;">
            <div class="title">カフェ</div>
            <p class="description">
              テイクアウトテキスト
            </p>
          </div>
        </div>
        -->
  </div>
</template>

<script>
export default {
  name: "CafeBentoSection",
  computed:{
    getUser(){
      return this.$store.state.user
    }
  },
  mounted(){
    
  }
}
</script>

<style scoped>
.bento-card {
  width: 33%;
  cursor: pointer;
  overflow: hidden;
  background: #fff;
  border-width: 1px;
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.14), 0 1px 8px rgba(0, 0, 0, 0.12);
  margin: 10px;
}
.title {
  font-weight: 500;
  font-size: 18px;
}
.description {
  font-size: 12px;
  color: #828282;
}

</style>