<template>
  <div style="margin-top:120px;padding:20px;">
      <div v-if="step === 1 && ready">
        <!-- <div style="font-weight:bold;text-align:center;"> 弁当 </div> -->
        <!--
          <div v-for="sechudle in bentoSechudleGroup" :key="sechudle.date">
          </div>
        -->
        <!-- <div style="font-weight:bold;text-align:center;"> {{ sechudle.date }} </div> -->
          <div v-for="bento in bentoSechudle" :key="bento.id" class="bento-card" @click="confirmStep(bento)">
           <!-- <img w="100%" :src="bento.image[0].url"/> -->
            <div p="6">
              <div class="title">{{ bento.title[0] }} </div>
              <p class="date"> 受け取り日時: {{ bento.date }} </p>
              <p class="inventory"> 在庫: {{ getInventory(bento) }} / {{ bento.quantity }} </p>
              <p class="description">
                {{ bento.details[0] }}
              </p>
            </div>
          </div>
      </div>
      <ConfirmBento :bento="bento" v-if="step === 2"  v-bind:back-step="backStep" :nextStep="goToComplete"/>
      <BentoComplete :bento="bento" v-if="step === 3" v-bind:my-page="myPage"/>
  </div>
</template>
<script>
import ConfirmBento from "../components/CafeBentoSection/ConfirmBento";
import BentoComplete from "../components/CafeBentoSection/BentoComplete";
import axios from "../axios";

export default {
  name: "Bento",
  data() {
    return {
      ready: false,
      step: 1,
      bentoSechudle: [],
      bentoTypes: [],
      bentos:[],
      bento: {},
      bentoSechudleGroup: []
    }
  },
  components: {
    ConfirmBento,
    BentoComplete,
  },
  methods:{
    getInventory(bento){
      let inventory = bento.quantity
      if(bento.sales && bento.sales.length){
        return inventory - bento.sales.length
      }
      return  inventory
    },
    backStep(){
      this.step = 1
    },
    async goToComplete(){
      const { sales, quantity, id } = this.bento
      const loader = this.$loading.show()
      if(quantity > sales.length){
        await axios.post('/api/company-bentos', {
          customer_id: this.user.record_id,
          bento_id: id
        })
        this.bento = {}
        this.step = 1

        /*
        localStorage.setItem('orderList', 'company-bento')
        this.$router.push('/orders')
        */
      } else {
        // alert something
      }
      await this.getbentos()
      loader.hide()
    },
    confirmStep(bento) {
      if(!this.user){
        this.$router.push("/login?redirect=cafe")
        return
      }
      
      if(bento){
        this.bento = bento
      }
      this.step = 2
    },
    myPage(){
      this.$router.push("/")
    },
    async getbentos(){
      const bentoSechudle = await axios.get('/api/company-bentos')
      console.log('bentoSechudle', bentoSechudle.data)
      this.bentoSechudle = bentoSechudle.data.map(bento => {
        return bento
      })

      const bentoTypes = await axios.get('/api/bento-types')
      this.bentoTypes = bentoTypes.data.map(bento => {
        bento.sechudle = this.bentoSechudle.filter(sechudle => {
          return sechudle.bento[0] == bento.id
        })
        return bento
      })
      return
    }
  },
  mounted(){
    this.getbentos()
  },
  beforeMount(){
      const user = this.$store.state.user
      console.log(user)
      if(user){
        this.user = user
      }

      setTimeout(() => {
          if(!user.companyId){
            this.$router.push("/")
          }
      }, 3000);
      this.ready = true
  }
}
</script>

<style scoped>
.bento-card {
  width: calc(33.3% - 20px);
  padding: 5px;
  margin: 10px;
  display: inline-grid;
  cursor: pointer;
  border-width: 1px;
  border-radius: 5px;
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.14), 0 1px 8px rgba(0, 0, 0, 0.12);
}
.takeout-menus {
  display: inline-block;
  margin-top: 90px;
}
.title {
  padding: 5px;
  font-weight: 500, Medium;
  font-size:18px;
}
.description {
  color:#828282;
}
@media screen and (max-width: 777px) {
  .bento-card {
    width: calc(100% - 20px);
    display: inline-block;
  }
}
</style>