<template>
  <CBox :mb="[60, 160]">
    <CBox
        rounded="lg"
        background="#f0f0f0"
        p="15px"
        textAlign="center"
        m="16px"
        v-if="step !==4 && step !==5"
    >
      <CBox fontSize="14px" mb="2">
        選択中のレッスン
      </CBox>
      <CBox fontSize="16px" fontWeight="bold">
        {{ typeName }}
      </CBox>
    </CBox>
    <StepBookingFirst v-if="step === 1" :goBack="goBack" :getSenseiSchedule="getSenseiSchedule" :list="teachers" :listTypes="listTypes" v-bind:next-step="nextStep" />
    <StepBookingSecond v-if="step === 2" :typeName="typeName" :goBack="goBack" :setBooking="setBooking" :setPreviousMonth="setPreviousMonth" :setNextMonth="setNextMonth" :currentMonth="getCurrentMonth" :teacherSchedule="teacherSchedule" v-bind:next-step="nextStep"/>
    <StepBookingThird v-if="step === 3" :goBack="goBack" :user="getUser" :setcurrentTicket="setcurrentTicket" :listCount="listCount" :booking="booking" :setPreviousMonth="setPreviousMonth" :setNextMonth="setNextMonth" v-bind:next-step="nextStep"/>
    <TicketCheck v-if="step === 4" :goBack="goBack" :booking="booking" :currentTicket="currentTicket" v-bind:next-step="nextStep"/>
    <ConfirmTicket :goBack="goBack" :booking="booking" v-if="step === 5"/>
  </CBox>
</template>

<script>
import { CBox } from "@chakra-ui/vue";

import StepBookingFirst from '../components/StepBooking/StepBookingFirst'
import StepBookingSecond from "../components/StepBooking/StepBookingSecond";
import StepBookingThird from "../components/StepBooking/StepBookingThird";
import TicketCheck from "../components/StepBooking/TicketCheck";
import ConfirmTicket from "../components/StepBooking/ConfirmTicket";
import axios from "../axios";
import moment from "moment";
moment.locale("ja");

export default {
  name: "StepBooking",
  components: {
    CBox,
    StepBookingFirst,
    StepBookingSecond,
    StepBookingThird,
    TicketCheck,
    ConfirmTicket,
  },
  data() {
    return {
      step: 1,
      typeName: '',
      teachers: [],
      listTypes: [],
      teacherSchedule:[],
      currentMonth: new Date(),
      booking: {},
      bookings:[],
      listCount:[],
      listOrders:[],
      currentTicket:{}
    }
  },
  computed:{
    getCurrentMonth(){
      return this.currentMonth
    },
    getUser(){
      return this.$store.state.user
    }
  },
  async mounted() {
    const user = this.$store.state.user

    const testmode = this.$store.state.testmode
    const res = await axios.get('/api/lesson-types')
    this.listTypes = res.data;
    this.listTypes.forEach(item => {
      if(this.$route.params.type === item.sku) {
        this.typeName = item.name
        this.lessonType = item
      }
    })
    const loader = this.$loading.show()
    const teachers = await axios.get('/api/teachers').then(res => res.data)

    this.teachers = teachers.filter(teacher => {
      const isTeacher = teacher.lessonTypes.includes(this.lessonType.id)
      if(testmode){
          return isTeacher
      }
      return isTeacher && !teacher.isTest
    })

    const resOrders = await axios.get('/api/orders')
    this.listOrders = Object.values(resOrders.data.listTypes)
    this.listCount = Object.values(resOrders.data.listTotalTicket).filter(item => item.count > 0)

    if(user){
      const schedule = await axios.get('/api/schedule', {
        params: { email: user.email }
      })
      this.bookings = schedule.data.map(d => {
        d.type = "booking"
        return d
      })
      this.listCount = this.listCount.map(lc => {
          const bookBySku = this.bookings.filter(book => {
            let isSku = false
            if(book.sku && book.sku.length && (book.sku[0] == lc.sku)){
              isSku = true
            }
            return isSku
          })
          lc.booking = bookBySku
        return lc
      })

    }
    
    loader.hide()
    
    // Get teacher & Booking
    if(localStorage.getItem('booking-id') && localStorage.getItem('teacher-id')){
      this.jumpToBookLesson()
    }
  },
  methods: {
    async jumpToBookLesson(){
        const teacherId = localStorage.getItem('teacher-id')
        const selectedTeacher = this.teachers.find(t => t.id === teacherId)
        const teacherSchedule = await this.getSenseiSchedule(selectedTeacher)
        const selectedLesson = teacherSchedule.find(t => t.record_id == localStorage.getItem('booking-id'))
        this.setBooking(selectedLesson)
        this.nextStep()
        
        if(localStorage.getItem('booking-id')){
           localStorage.removeItem('booking-id')
        }
        
        if(localStorage.getItem('teacher-id')){
           localStorage.removeItem('teacher-id')
        }
    },
    goBack(){
      this.step = this.step -1
    },
    setBooking(booking){
      this.booking = booking
    },
    setPreviousMonth(){
      let month = this.currentMonth.getMonth()
      let year = this.currentMonth.getFullYear()
      if(month == 0){
        month = 11
        year = year - 1
      } else {
        month = month - 1
      }
      let newDate = new Date(this.currentMonth).setMonth(month)
      newDate = new Date(newDate).setFullYear(year)
      this.currentMonth = new Date(newDate)
    },
    setNextMonth(){
      let month = this.currentMonth.getMonth()
      let year = this.currentMonth.getFullYear()
      if(month == 11){
        month = 0
        year = year + 1
      } else {
        month = month + 1
      }
      let newDate = new Date(this.currentMonth).setMonth(month)
      newDate = new Date(newDate).setFullYear(year)
      this.currentMonth = new Date(newDate)
    },
    nextStep() {
      this.step = this.step + 1
    },
    async getSenseiSchedule(sensei) {
      this.nextStep()
      this.teacherSchedule = await axios.get('/api/teacher-schedule',{
        params: { email: sensei.mail, month: this.month , type: this.$route.params.type}
      }).then(res => res.data.map(available => {
        return {
          ...available.fields,
          record_id: available.id
        }
      }))
      return this.teacherSchedule
    },
    setcurrentTicket(currentTicket) {
      this.currentTicket = currentTicket
    }
  }
};
</script>

<style scoped>
</style>