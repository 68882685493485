<template>
    <CBox h="100px" class="header">
      <CFlex
        justifyContent="space-between"
        h="100%"
        class="menu-container">
        <CBox>
        <CFlex align="left">
            <img style="padding-right:16px;cursor:pointer;"
                 src="/images/standard_logo.svg"
                 @click="$route.path !== '/' ? $router.push('/') : false"/>
            <CLink
              v-if="$store.state.userTags.includes('sensei')"
              margin="0"
              as="router-link"
              to="/export"
              class="link">
              Export
            </CLink>
            <CLink
              v-if="$store.state.userTags.includes('sensei')"
              margin="0"
              as="router-link"
              to="/sensei"
              class="link"
              >Sensei</CLink
            >
            <!--
            <CLink
              margin="0"
              as="router-link"
              to="/event"
              class="link"
              >Event</CLink
            >
            -->
            <CLink
              margin="0"
              class="link"
              @click="scrollToElement('aboutUs')"
              >About us</CLink
            >
            <CLink
              v-if="getUser"
              margin="0"
              as="router-link"
              to="/cafe"
              class="link"
              >Cafe</CLink
            >
            <CLink
              margin="0"
              as="router-link"
              to="/studio"
              class="link"
              >Studio</CLink
            >
            <CLink
              v-if="getUser"
              margin="0"
              as="router-link"
              to="/orders"
              class="link"
              >Order</CLink>
            <!--
            <CLink
              margin="0"
              class="link"
              target="_blank"
              href="https://trailer-app-dev.myshopify.com/"
              >Shop</CLink
            >
            -->
            <CLink
              margin="0"
              class="link"
              @click="scrollToElement('access')"
              >Access</CLink
            >
            <CLink
              margin="0"
              class="link"
              @click="scrollToElement('contact')"
              >Contact</CLink
            >
        </CFlex>
        </CBox>

        <CBox align="left">

          <CBox class="social-btns" @click="openLink('https://liff.line.me/1645278921-kWRPP32q/?accountId=605uamre')">
            <CImage
                class="social-btns"
                src="/images/line.png"
                h="50px"
                w="50px"
              />
          </CBox>

          <CBox class="social-btns" @click="openLink('https://www.instagram.com/trailer_garden/')">
            <CImage
                class="social-btns"
                src="/images/icons8-instagram-50.png"
                h="50px"
                w="50px"
              />
          </CBox>

          <CButton variant-color="white" class="button" @click="$router.push('/login')" v-if="!getUser">
            ログイン
          </CButton>
          <CLink as="router-link" to="/user" v-else>
            <CImage
                class="social-btns"
                src="/images/user-white.png"
                alt=""
                w="48px"
            />
          </CLink>

        </CBox>

      </CFlex>
    </CBox>
</template>

<script>
import { CBox, CFlex, CLink, CImage, CButton } from "@chakra-ui/vue";
export default {
  name: "HeaderLarge",
  components:{
    CFlex,
    CBox,
    CLink,
    CImage,
    CButton
  },
  methods:{
    openLink(url){
      window.open(url, '_blank')
    },
    scrollToElement(id){
      const path = this.$route.path
      if(path !== '/' && id !== 'access' && id !== 'contact'){
        this.$router.push('/#'+id)
        return
      }
      let el = document.getElementById(id);
      if (el) {
        var headerOffset = 100;
        var elementPosition = el.getBoundingClientRect().top;
        var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth"
        });
      }
    }
  },
  computed:{
    getScrollY(){
      return this.$store.state.scrollY
    },
    getUser(){
      return this.$store.state.user
    }
  }
}
</script>
<style scoped>
.header {
  padding-left: 25px;
  padding-right: 25px;
  background: #2A4750;
  backdrop-filter: blur(20px);
  position: absolute;
  padding-top: 20px;
  width: 100%;
  font-size: 22px;
  z-index: 9999;
}
.button {
  border: none;
  outline: none;
  cursor: pointer;
  background: #fff;
  color:#333;
  border-radius: 5px;
  padding:5px 24px;
  opacity: 0.9;
  font-size: 14px;
}
.menu-container-mobile {
  position: relative;
  padding-top: 30px;
}
.menu-container .link:not(:last-child) {
  margin-right:20px;
}
.link {
  transition: none;
  font-family: 'Patrick Hand', cursive;
  line-height: 70px;
}
.link:hover {
  color: #ffffff;
  text-decoration: none;
}
.header-mobile {
  display: none;
}
a:link {
  text-decoration: none;
  color: #FFF;
}
a:visited {
  text-decoration: none;
  color: #FFF;
}
.social-btns{
  display: inline-block;
  margin-right: 10px;
  cursor: pointer;
}
@media screen and (max-width: 868px) {
  .header {
    display: none;
  }
  .header-mobile {
    display: block;
    /*height: 60px;*/
    background: #f5f5f5;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 999;
  }
  .link {
    margin: 0;
    color: #000000;
    display: block;
    padding: 20px 40px;
    transition: background 0.2s;
  }
  .link:hover {
    color: #000000;
    background: #dedede;
  }
  .header {
    top: 0px;
  }
}
</style>