<template>
  <CBox style="margin:154px auto 0px auto;max-width:1200px;">
    <CBox class="bookings-info" m="16" v-if="user">
      <div style="font-weight:bold;"> 購入済みのチケット </div>
      <CBox mb="3" v-if="tickets && tickets.length > 0" class="ticket-totals">
        <CText v-for="(item, index) in tickets" v-bind:key="index" class="tickets">
          <div class="studio-name"> {{ item.name }} </div> <div class="studio-leftovers">残り {{item.available || 0}}枚 </div>
        </CText>
      </CBox>
      
      <CLink as="router-link" to="/purchase-ticket" :_hover="{ transition: 'none', outline: 'none' }">
        <CButton style="position: inherit;" color="#ffffff" bg="#EF857D" h="52px">
          チケットを購入する
        </CButton>
      </CLink>
    </CBox>

    <CBox class="bookings-info" mb="10" v-else>
      <CBox mb="3">
        <CText>
        チケットを購入するためログインしてください
        </CText>
      </CBox>
      <CButton style="position: inherit;" fontWeight="bold" color="#ffffff" bg="#EF857D" h="52px" @click="goToLogin">
        ログイン
      </CButton>
    </CBox>

      <div class="tab-wrapper">
        <div class="tab-item" v-for="(item, index) in lessonTypes" :class="index === currentTab ? 'active': ''" style="min-width: 150px;" v-bind:key="index" @click="handleChangeTab(index)">
          {{item[0].typeName}}
        </div>
      </div>

      <CBox v-if="currentItem" p="0 20px" style="display:flex;flex-wrap: wrap;">
        <CBox rounded="lg" v-for="(item, index) in currentItem" v-bind:key="index" :mb="[3, 6]" class="event-type"
        @click="goToLesson(item)">

              <div class="image-line-up" :style="`
                background-image:url(${item.image[0].thumbnails.full.url}); 
                height:${getCardWidth}px;
                width:${getCardWidth}px;
                `">
              </div>

              <CBox p="24px">
                <CText class="title">{{item.name}}</CText>
                <CText class="description">
                  {{item.name}}
                </CText>
              </CBox>
        </CBox>
      </CBox>
  </CBox>
</template>

<script>
import {CBox, CText, CButton, CLink} from "@chakra-ui/vue";

export default {
  name: 'SalonAndFitnessSection',
  components: {
    CBox,
    CText,
    CButton,
    CLink
  },
  data() {
    return {
      currentTab: 0,
      listCount: [],
      listOrders: [],
      innerWidth: 0
    };
  },
  computed: {
    lessonTypes(){
      return this.$store.state.lessonTypes
    },
    user(){
      return this.$store.state.user
    },
    tickets(){
      return this.$store.state.tickets
    },
    ticketTypes(){
      return this.$store.state.ticketTypes
    },
    getCardWidth(){
      let innerWidth = this.innerWidth
      if(innerWidth > 1200){
        return 1200 / 3 - 20
      }
      if(innerWidth > 777){
        return innerWidth / 3 - 20
      } else {
        return innerWidth
      }
    },
    currentItem() {
      return this.lessonTypes.find((item, index) => {
        return index === this.currentTab
      })
    }
  },
  async mounted() {
    const loader = this.$loading.show()
    if(localStorage.getItem('redirect')){
      localStorage.removeItem('redirect')
       localStorage.removeItem('redirect-sku')
    }
    this.$nextTick(() => {
        window.addEventListener('resize', this.onResize);
    })
    this.onResize()
    loader.hide();
  },
  async beforeMount(){
      await this.$store.dispatch('getLessonTypes')
      await this.$store.dispatch('getUser')
      await this.$store.dispatch('getTickets')
  },
  methods: {
    goToLesson(item){
      this.$router.push('/step-booking/' + item.sku)
    },
    onResize() {
      this.innerWidth = window.innerWidth
    },
    goToLogin(){
      this.$router.push('/login?redirect=studio')
    },
    handleChangeTab(index) {
      this.currentTab = index;
    }
  },
  beforeDestroy() {
      window.removeEventListener('resize', this.onResize); 
  }
}
</script>

<style scoped>
.image-line-up {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.tab-wrapper {
  display: flex;
  overflow-x: auto;
  padding-bottom: 10px;
}
.tab-item {
  cursor: pointer;
  text-align: center;
  padding-bottom: 15px;
}
.tab-item.active {
  color: #A2CBBF;
  border-bottom: 2px solid #A2CBBF;
}
.bookings-info {
  background: #F0F0F0;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 16px;
}
.booking-type {
  cursor: pointer;
  /*overflow: hidden;*/
  background: #fff;
  border-width: 1px;
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.14), 0 1px 8px rgba(0, 0, 0, 0.12);
}
.title {
  font-size: 18px;
  font-weight: 500;
  color: #000000;
}
.description {
  font-size: 13px;
  color: #828282;
}
.studio-name {
  width: calc(100% - 120px);
  display: inline-block;
}
.studio-leftovers {
    width: 120px;
    padding: 5px;
    color: #005C42;
    display: inline-block;
    background: #FFF;
    text-align: center;
    border-radius: 10px;
}
.ticket-totals {
  width:100%;
}
.tickets {
  padding:10px;
  width:100%;
  display:inline-flex;
  border-bottom: solid 2px #FFF;
  /*font-size: 18px;*/
}
.event-type {
  width: calc(33% - 20px);
  margin: 10px;
  display: inline-block;
  cursor: pointer;
  overflow: hidden;
  background: #fff;
  border-width: 1px;
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.14), 0 1px 8px rgba(0, 0, 0, 0.12);
}
@media screen and (max-width: 777px) {
  .event-type {
    width: calc(100% - 20px);
    display: inline-block;
  }
}
</style>
