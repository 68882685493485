<template>
  <div class="login-form">
    <div>
      <AlertBox
          v-if="sent"
          v-bind:message="alertBox.message"
          v-bind:type="alertBox.type"
      />
      <div class="form-wrapper" v-if="!sent">
        <div style="text-align:center;font-size:18px;;margin:20px;">パスワードを再設定</div>
        <div class="form-group">
          <label>  メールアドレス: </label>
          <input v-validate="'required|email'" type="email" name="email" v-model="email" placeholder="メールアドレス">
          <span style="color:red;">{{ errors.first('email') }}</span>
            <div style="padding:20px;">
            </div>
        </div>
        <div class="btn-group">
            <button
              class="login-btn"
              style="background-color:rgba(239, 133, 125);"
              @click="sendReset">
              リセットパスワード
            </button>
        </div>
      </div>
      <div v-else>

      </div>
    </div>

  </div>
</template>
<script>
import japanese from 'vee-validate/dist/locale/ja';
import AlertBox from "../components/AlertBox";
import axios from "../axios";

export default {
  name: "RequestForgotPassword",
  components:{
    AlertBox
  },
  data(){
    return {
      email: "",
      sent: false,
      alertBox: {
        type: 'success',
        message: 'メールを送りました',
      },
      resetToken: null
    }
  },
  methods:{
    async sendReset(){
      const email = this.email
      const loader = this.$loading.show()
      const isValid = await this.$validator.validate()
      if (!isValid) {
          loader.hide()
          return
      }
      await axios.post('api/forgot-password', {
        email
      })
      loader.hide()
      this.sent = true
    }
  },
  created() {
    this.$validator.localize('ja', {
      messages: japanese.messages,
      attributes: {
        email: 'メール'
      }
    });
    this.$validator.localize('ja');
  }
}
</script>
<style scoped>
.login-form {
  text-align: left;
  padding:20px;
  margin-top: 120px;
}
.form-wrapper {
  max-width: 900px;
  margin: 0 auto;
}
.login-btn {
  padding: 20px 22px;
  font-size: 1.1rem;
  color: white;
  margin: 20px 0;
  cursor: pointer;
  border-radius: 5px;
}
.forgot-password-section {
  margin-top:100px;
}
.form-group input {
  box-sizing: border-box;
  border-radius: 4px 4px 0 0;
  width: 100%;
  height: 40px;
  outline: none;
  border-bottom: 1px solid #555;
  padding: 35px 20px;
  background: rgba(0, 0, 0, 0.06);
}

.form-group p {
  color: rgba(0, 0, 0, 0.6);
  margin-top: 8px;
}

.btn-group {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

</style>