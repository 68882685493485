<template>
  <CBox>
    <CBox
        class="alert"
        mb="8"
        ml="4"
        mr="4"
        v-if="type === 'alert'"
        border="1px solid #DA536E"
    >
      <CBox class="icon-container" bg="rgba(218, 83, 110, 0.16)">
        <Info />
      </CBox>
      <CText
          mb="0"
          ml="2"
          fontSize="16px"
          fontWeight="bold"
          color="#DA536E"
      >
        {{ message }}
      </CText>
    </CBox>

    <CBox
        class="alert"
        mb="8"
        ml="4"
        mr="4"
        v-if="type === 'success'"
        border="1px solid #54917F"
    >
      <CBox class="icon-container" bg="rgba(76, 175, 80, 0.16)">
        <CheckIcon />
      </CBox>
      <CText
          mb="0"
          ml="2"
          fontSize="16px"
          fontWeight="bold"
          color="#54917F"
      >
        {{ message }}
      </CText>
    </CBox>
  </CBox>
</template>

<script>
import {CBox, CText} from "@chakra-ui/vue";
import CheckIcon from "./icons/CheckIcon";
import Info from "./icons/Info";
export default {
  name: "AlertBox",
  components: {
    CBox,
    CText,
    CheckIcon,
    Info,
  },
  props: {
    type: String,
    message: String,
  }
}
</script>

<style scoped>
.alert {
  padding: 16px;
  display: flex;
  align-items: center;
  border-radius: 4px;
}
.icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 48px;
  min-height: 48px;
  border-radius: 50%;
}
</style>