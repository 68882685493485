<template>
  <div class="transaction-law">
    <h2>特定商取引法表示</h2>
    <table>
      <tr>
        <td>販売業者</td>
        <td>ニッティド株式会社</td>
      </tr>
      <tr>
        <td>運営統括責任者</td>
        <td>井戸端 康宏</td>
      </tr>
      <tr>
        <td>所在地</td>
        <td>〒649-0121 和歌山県海南市下津町丸田68-1</td>
      </tr>
      <tr>
        <td>電話番号</td>
        <td>073‐492‐0077</td>
      </tr>
      <tr>
        <td>メールアドレス</td>
        <td>contact@trailer.garden</td>
      </tr>
      <tr>
        <td>商品代金以外の必要料金</td>
        <td>・銀行振込でご購入の際の振込手数料。<br>
            ・代金引換でご購入の際の代引手数料。</td>
      </tr>
      <tr>
        <td>引渡し時期</td>
        <td>通常、ご注文日より平日2日前後での発送となります。<br>
            ただし、連休明け（正月･GWなど）は、平日3日前後での発送となります。</td>
      </tr>
      <tr>
        <td>お支払い方法</td>
        <td>代金引換・クレジットカード･後払い（コンビニ･銀行･郵便局）となります。</td>
      </tr>
      <tr>
        <td>お支払い期限</td>
        <td>後払いの場合：請求書は、商品とは別に郵送されますので、発行から14日以内にお支払い下さい。</td>
      </tr>
      <tr>
        <td>返品・交換(送料)</td>
        <td>返品・交換は商品到着後20日以内。<br>
            商品の品質は万全を期しておりますが万一の不良品の場合は、期間にかかわらず返品をお受けいたします。<br>
            お客様のご注文ミス・お客様の都合による返品・交換は送料はお客様負担（元払い）とさせていただきます。</td>
      </tr>
      <tr>
        <td>画面表示と実際の商品との相違</td>
        <td>撮影光源及び、画像加工による若干の相違がある。</td>
      </tr>
      <tr>
        <td>返品住所・電話番号等</td>
        <td>販売所在地と電話・ファックス・電子メ－ルと同じ。</td>
      </tr>
    </table>
    <CBox mt="30px" mb="62px" d="flex" justifyContent="center">
      <CButton color="#fff" bg="#4abaa7" fontSize="16px" h="38px" fontWeight="normal" @click="$router.push('/')">ホームに戻る</CButton>
    </CBox>
  </div>
</template>
<script>
import {
    CButton,
    CBox
} from "@chakra-ui/vue";
export default {
  name: "CommercialTransactionsLaw",
  components:{
    CButton,
    CBox
  }
}
</script>

<style scoped>
  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }
  td, th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }
  .transaction-law {
    max-width: 900px;
    margin: 0px auto;
    padding: 120px 20px 0px 20px;
  }
  h2 {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
  }
</style>