<template>
  <CBox style="padding-top:120px;">
    <AlertBox
        v-bind:message="alertBox.message"
        v-bind:type="alertBox.type"
    />
    <CBox class="ticket-information" :m="[0, '0 16px']">
      <CList p="4">
        <CListItem d="flex" alignItems="center" p="2">
          <CText minW="140px" fontSize="16px" fontWeight="bold">レッスン</CText>
          <CText fontSize="16px">
            {{ booking["レッスンタイプ名"][0] }}
          </CText>
        </CListItem>
        <CListItem d="flex" alignItems="center" p="2">
          <CText minW="140px" fontSize="16px" fontWeight="bold">日程</CText>
          <CText fontSize="16px">
            {{ getDateTime(booking.startDateTime) }} - {{ getEndTime(booking.endDateTime) }}
          </CText>
        </CListItem>

        <CListItem d="flex" alignItems="center" p="2">
          <CText minW="140px" fontSize="16px" fontWeight="bold">先生</CText>
          <CText fontSize="16px">
           {{ booking["先生名"][0] }}
          </CText>
        </CListItem>

        <CListItem d="flex" alignItems="center" p="2">
          <CText minW="140px" fontSize="16px" fontWeight="bold">チケット</CText>
          <CText fontSize="16px">
           {{ booking.ticketType }}
          </CText>
        </CListItem>

      </CList>
    </CBox>
    <CBox mt="44px" d="flex" justifyContent="center">
      <CButton backgroundColor="#3CB37A" color="#ffffff" @click="confirmBooking">
        予約を確定する
      </CButton>
    </CBox>
    <CBox mt="51px" mb="62px" d="flex" justifyContent="center">
          <CButton
          bg="#F2B5B1"
          h="52px"
          fontWeight="bold"
          color="#ffffff"
          @click="goBack">
        戻る
      </CButton>
    </CBox>
  </CBox>
</template>

<script>
import {CBox, CText, CList, CListItem, CButton} from "@chakra-ui/vue";
import AlertBox from "../AlertBox";
import axios from "../../axios";
import moment from "moment";
moment.locale("ja");

export default {
  name: "TicketCheck",
  components: {
    CBox,
    CText,
    CList,
    CListItem,
    CButton,
    AlertBox,
  },
  data() {
    return {
      alertBox: {
        type: 'alert',
        message: 'まだ予約は完了していません. 内容のご確認をお願いいたします',
      }
    }
  },
  methods: {
    getEndTime(time){
      return moment(time).utc().format("hh:mm")
    },
    getDateTime(time,) {
      return moment(time).utc().format("LLLL")
    },
    async confirmBooking(){
    const loader = this.$loading.show()
      const user = this.$store.state.user
      await axios.post('/api/booking', {
        currentTicket: this.currentTicket,
        booking: this.booking,
        user: user,
      })
      await this.$store.dispatch('getUser')
      await this.$store.dispatch("getTickets")
      loader.hide()
      this.nextStep()
    }
  },
  mounted(){
    window.scrollTo(0, 0)
  },
  props: {
    nextStep: {
      type: Function
    },
    goBack: {
      type: Function
    },
    currentTicket:{
      type: Object
    },
    booking:{
      type: Object
    }
  }
}
</script>

<style scoped>
.ticket-information {
  background: #FFFFFF;
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}
</style>