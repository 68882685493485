<template>
  <CBox class="card"
    @mouseover="hover = true"
    @mouseleave="hover = false"
    :style="`height:${width}px;
    width:${width}px;`"
    >
    <div class="image-line-up" :style="`
    background-image:url(${image}); 
    height:100%;
    width:100%;
    `" v-if="type === 'normal'"></div>
    <div v-else>
      <img :src="image" class="image" />
    </div>
    
    <CBox :class="hover ? 'card-bottom-show card-bottom' : 'card-bottom'" v-if="type !== 'normal'">
      <CText class="title">
        {{ title }}
      </CText>
      <CText class="date">
        {{ date }}
      </CText>
      <CText class="description" v-html="description" v-if="hover">
      </CText>
      <div class="card-button" v-if="hover" @click="$router.push('/event?id='+id)">
        イベントを詳しく見る
      </div>
    </CBox>
  
    <CBox :class="hover ? 'card-bottom-show card-bottom card-bottom-normal' : 'card-bottom card-bottom-normal'" v-if="type == 'normal' && hover">
      <CText class="title">
        {{ title }} 
      </CText>
      <CText class="description" v-html="description">
      </CText>
    </CBox>

  </CBox>
</template>

<script>
import { CBox, CText } from "@chakra-ui/vue";

export default {
  name: "Card",
  components: {
    CBox,
    CText,
  },
  props: {
    width: Number,
    image: String,
    title: String,
    date: String,
    description: String,
    tagName: String,
    type: String,
    id: String
  },
  data(){
    return {
      hover: false
    }
  }
};
</script>

<style scoped>
.card {
  min-width:33%;
  max-width:100%;
  position:relative;
  word-wrap:break-word;
  overflow:hidden;
}

.image {
  width: 100%;
}

.container {
  background: #ffffff;
  box-shadow: 4px 4px 2px 1px #c3ddd6;
}

.tag {
  background: #f5f5f5;
  border-radius: 4px;
  padding: 7px 12px;
  width: fit-content;
  font-weight: 700;
  font-size: 12px;
}

.title {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: #FFF;
  text-align: center;
}

.date {
  text-align: center;
}

.description {
  text-align: center;
  word-wrap: break-word;
  overflow: hidden;
}

.card-bottom {
  color: #FFF;
  background: rgba(122, 181, 163, 90%);
  padding: 20px;
  z-index: 99;
  bottom: 0;
  top: auto;
  position: absolute;
  width: 100%;
  transition: top 0.5s ease 0s;
  cursor: pointer;
}

.card-bottom-show {
  top: 0px;
  transition: top 0.5s ease 0s;
}

.card-button {
  background-color:#FFF;
  color:#005C42;
  font-weight: bold;
  border-radius: 5px;
  padding:5px;
  display: inline-block;
  display: block;
  width: 180px;
  text-align: center;
  margin: 20px;
  cursor: pointer;
  position: absolute;
  bottom: 0px;
  margin-left: calc(50% - 100px);
}

.card-bottom-normal {
  width: calc(100%);
  padding-top: 40%;
}

.image-line-up {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

@media screen and (max-width: 868px) {
  .title {
    font-size: 14px;
  }
  .card-bottom-normal {
    width: calc(100%);
    padding-top: 40%;
  }
  .card {
    padding: 0px;
  }
}

@media screen and (max-width: 868px) {
  .description {
    display: none;
  }
}

</style>