<!--suppress ALL -->
<template>
  <div id="app">
    <Header/>
    <div>
      <router-view/>
    </div>
    <Footer v-if="displayFooter"/>
  </div>
</template>

<script>

import "notie/dist/notie.css"
import "nprogress/nprogress.css"
import "./assets/time-picker.css"
import "./assets/date-picker.css"
import Header from "./components/Header";
import Footer from "./components/Footer";
// import axios from "./axios";

export default {
  name: "App",
  components: {
    Header,
    Footer
  },
  data(){
    return {
      loaded: false,
      displayFooter: true
    }
  },
  computed: {
    user(){
      return this.$store.state.user
    }
  },
  async beforeMount(){
    if(this.$route.path.includes('sensei')){
      this.displayFooter = false
    }
    const token = localStorage.getItem('token');
    if(!token){
      return
    }
    await this.$store.dispatch('getUser')
    await this.$store.dispatch('getTickets')

    const isRedirect = localStorage.getItem('redirect')
    if(isRedirect && isRedirect  === 'studio'){
      if(this.$route.path === '/'){
        const sku = localStorage.getItem('redirect-sku')
        if(sku){
           this.$router.push("/step-booking/" + sku)
        } else {
          this.$router.push("/studio")
        }
        
      }
    }
  },
  mounted() {
    if(this.$route.query.testmode && this.$route.query.testmode == 'true'){
        this.$store.state.testmode = true
    }
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Patrick+Hand&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Zen+Maru+Gothic&display=swap');
html{
    -webkit-overflow-scrolling: touch;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  color: #2c3e50;
  height: 100vh;
}

.notie-container {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  border-radius: 20px;
  box-shadow: 0 4px 20px rgba(#3f4066, 0.2);
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  left: 20px;
  margin-top: 30px;
  padding: 14px 20px;
  text-align: center;
  width: calc(100% - 40px);
  z-index: 3;
}

.notie-background-success {
  background-color: #00b9ac;
}

.notie-background-error {
  background-color: #ff4d0e;
}
.bm-menu {
  background-color: #f5f5f5 !important;
}
.bm-item-list {
  margin-left: 0;
}
.bm-burger-button {
  position: absolute !important;
  width: 20px !important;
  height: 12px !important;
  left: 20px !important;
  top: 26px !important;
  cursor: pointer !important;
}
.bm-burger-bars {
  background: rgba(0, 0, 0, 1);
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  select,
  textarea,
  input {
    font-size: 16px;
  }
}

</style>