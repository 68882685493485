<template>
  <div class="form-wrapper">
    <form v-if="step === 0">
      <div class="heading">パスワード変更</div>

      <!--
      <div class="form-group">
        <input @keydown.space.prevent v-validate="'required|min:8'" name="currentPassword" :type="showCurrentPassword ? 'text' : 'password'" v-model="currentPassword" placeholder="現在のパスワード">
        <span class="error-text">{{ errors.first('currentPassword') }}</span>
        <div class="password-text" style="">半角英数字8文字以上</div>
        <div class="check-box-area">
          <input class="password-checkbox" type="checkbox" @click="showCurrentPassword = !showCurrentPassword">
          <div class="show-password-text">パスワードを表示</div>
        </div>
      </div>
      -->

      <div style="margin:40px;">
        <p><span style="color:#3CB37A;cursor:pointer;" @click="$router.push('request-forgot-password')">パスワードを忘れた方はこちら</span></p>
      </div>

      <div class="form-group">
        <input @keydown.space.prevent v-validate="'required|min:8'" name="newPassword" :type="showNewPassword ? 'text' : 'password'" v-model="newPassword" placeholder="新しいパスワード">
        <div>
          <span class="error-text">{{ errors.first('newPassword') }}</span>
        </div>
        <div class="password-text">半角英数字8文字以上</div>
      </div>

      <div class="form-group">
        <input @keydown.space.prevent v-validate="'required|min:8'" name="newPasswordConfirm" :type="showNewPassword ? 'text' : 'password'" v-model="newPasswordConfirm" placeholder="新しいパスワードの再入力">
        <div>
          <span class="error-text">{{ errors.first('newPasswordConfirm') }}</span>
          <span class="error-text" v-if="!passwordsMatch">パスワードが一致しません</span>
        </div>

        <div class="password-text">半角英数字8文字以上</div>
        <div class="check-box-area">
          <input class="password-checkbox" type="checkbox" @click="showNewPassword = !showNewPassword">
          <div class="show-password-text">パスワードを表示</div>
        </div>
      </div>

      <div class="btn-group">
        <button
            style="font-weight:bold;background-color:#54917F;padding:20px;border-radius:5px;margin-bottom:20px;color:#FFF;"
            @click="nextStep">
            パスワード変更を確認
        </button>
        <button
            style="background-color:rgba(239, 133, 125);padding:20px;border-radius:5px;margin-bottom:20px;font-weight:bold;color:#FFF;"
            @click="$router.push('user')">
            キャンセルして戻る
        </button>
      </div>
    </form>
    <div v-if="step === 1">
      <div class="alert">
        <div class="icon-container">
          <Info />
        </div>
        <div style="color:#DA536E;font-weight:bold;font-size:16px;">
          パスワードを確認してください
        </div>
      </div>

      <div class="register-information">
          <div style="font-size:20px;">
            新しいパスワード: <input :type="showNewPasswordConfirm ? 'text' : 'password'" disabled style="font-size:20px;font-weight:bold;" v-model="newPassword" fontWeight="bold">
          </div>
      </div>
      <div class="check-box-area" style="margin-top:10px;">
          <input class="password-checkbox" type="checkbox" @click="showNewPasswordConfirm = !showNewPasswordConfirm">
          <div class="show-password-text">パスワードを表示</div>
      </div>

      <div class="btn-group">
        <button
            style="background-color:#54917F;padding:20px;border-radius:5px;margin-bottom:20px;color:#FFF;"
            @click="savePassword">
          パスワード変更をします
        </button>
        <button
            style="background-color:rgba(239, 133, 125);padding:20px;border-radius:5px;margin-bottom:20px;color:#FFF;"
            @click="previousStep">
            戻る
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import japanese from 'vee-validate/dist/locale/ja';
import notie from 'notie'
import Info from "../components/icons/Info";
import axios from "../axios";

export default {
  name: "ChangePassword",
  components:{
    Info
  },
  data(){
    return {
      step: 0,
      showNewPassword: false,
      showCurrentPassword: false,
      showNewPasswordConfirm: false,
      showPassowrd: false,
      currentPassword: '',
      newPassword: '',
      newPasswordConfirm: '',
      record_id: ''
    }
  },
  computed:{
    passwordsMatch(){
      if(this.newPassword === '' && this.newPasswordConfirm == ''){
        return true
      }
      if(this.newPassword.length > 0 && this.newPasswordConfirm.length > 0 && this.newPassword === this.newPasswordConfirm ){
        return true
      } else {
        return false
      }
    }
  },
  methods:{
    previousStep(){
      this.step = 0
    },
    async nextStep(){
      const isValid = await this.$validator.validate()
      if (!isValid) {
          return
      }
      if(this.newPassword === '' && this.newPasswordConfirm == ''){
        return false
      }
      if(this.newPassword.length > 0 && this.newPasswordConfirm.length > 0 && this.newPassword !== this.newPasswordConfirm ){
        return true
      }
      this.step = 1
    },
    async savePassword(){
      const loader = this.$loading.show()
      try {
        const sendNewpassword = await axios.post('/api/update-user-password', {
          password: this.newPassword,
          record_id: this.record_id
        })
        if(sendNewpassword.data.token){
          localStorage.setItem('token', sendNewpassword.data.token);   
        }
        loader.hide()
        notie.alert({
          type: 'success',
          text: 'パスワード変更をしました!'
        })
        this.showNewPassword = 0
        this.step = 0
        this.showNewPassword = false
        this.showCurrentPassword = false
        this.showNewPasswordConfirm = false
        this.showPassowrd = false
        this.currentPassword = ''
        this.newPassword = ''
        this.newPasswordConfirm = ''
        this.$router.push('user')
      } catch (err){
        loader.hide()
        const message = typeof err.response !== "undefined" ? err.response.data.message : err.message;
        notie.alert({
          type: 'error',
          text: message
        })
      }
    }
  },
  mounted(){
    this.$validator.localize('ja', {
      messages: japanese.messages,
      attributes: {
        currentPassword: '現在のパスワード',
        newPassword: '新しいパスワード',
        newPasswordConfirm: '新しいパスワードの再入力',
      }
    });
    this.$validator.localize('ja');
    const loader = this.$loading.show()

    setTimeout(() => {
      loader.hide()
      if(this.$store.state.user){
        const {record_id} = this.$store.state.user
        this.record_id = record_id
      }
    }, 2000)
  }
}
</script>

<style scoped>
.bottom-text{
  padding: 5px;
}
.heading {
  text-align: center;
  font-weight: 400;
  font-size: 1.5em;
  margin-bottom: 20px;
}
.register-form {
  padding: 0 20px;
}
.form-wrapper {
  max-width: 900px;
  margin: 0 auto;
  margin-top: 140px;
}
.check-register-information {
  padding-top: 50px;
}
.register-information {
  padding:20px;
}
.form-group {
  margin-bottom: 10px;
}
.form-group input {
  box-sizing: border-box;
  border-radius: 4px 4px 0 0;
  width: 100%;
  height: 40px;
  outline: none;
  border-bottom: 1px solid #555;
  padding: 35px 20px;
  background: rgba(0, 0, 0, 0.06);
}
.password-checkbox {
  box-sizing: none;
  border-radius: 4px 4px 0 0;
  width: 20px !important;
  height: 20px !important;
  outline: 0px !important;
  border-bottom: 1px solid #555;
  padding: 0px !important;
  background: rgba(0, 0, 0, 0.06);
  margin-top: 0px !important;
  float: left;
}
.show-password-text {
  margin-left:25px;
}
.password-text {
  padding-top:5px;
  width:150px;
  display:inline-block;
}
.form-group p {
  color: rgba(0, 0, 0, 0.6);
  margin-top: 8px;
}

.btn-group {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 60px 0 40px;
}
.btn {
  padding: 30px 32px;
  font-size: 1.1rem;
  color: white;
  margin: 20px 0;
}
.register-information {
  background: #FFFFFF;
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}
.alert {
  padding: 16px;
  display: flex;
  align-items: center;
  border: 1px solid #DA536E;
  border-radius: 4px;
  margin: 10px;
}
.icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  background: rgba(218, 83, 110, 0.16);
  border-radius: 50%;
}
.mail-magazine-input {
    width: 20px !important;
    height: 20px !important;
    padding: 20px;
    margin: 20px;
}
@media screen and (max-width: 768px) {
  .form-wrapper {
      margin-top: 120px;
  }
}

.error-text {
  color:red;
  display:block;
}

.check-box-area {
  padding-top: 5px;
  width:150px;
  float:right;
}

</style>