export default {
    colors: {
        green: {
            1: '#54917F',
            2: '#3CB37A',
            3: '#005C42',
        },
        yellow: '#FCC800',
        orange: '#E5A323',
        red: '#ED6D46',
        brown: '#7B5544',
        blue: {
            1: '#68A9CF',
            2: '#0073A8',
            3: '#26499D',
        },
        pink: {
          1: '#EF857D',
          2: '#DA536E',
        }
    }
}